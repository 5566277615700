import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../";
import { SelectInput, SelectInputProps } from "../../inputs/SelectInput";

type FormSelectFieldProps = {
  onChange?: (name: string, val: any) => void; // optional change handler
  onValueChange?: (val: any) => void; // optional callback for value change - in addition to the default handler
} & FormFieldProps &
  Omit<SelectInputProps, "onChange" | "value">;

export const FormSelectField = React.memo(
  /**
   *
   */
  function FormSelectField({
    name,
    onValueChange,
    ...passProps
  }: FormSelectFieldProps) {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          return (
            <SelectInput
              {...fieldProps}
              id={name}
              onChange={(name, value) => {
                onChange({ target: { name, value } });
                onValueChange && onValueChange(value);
              }}
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);
