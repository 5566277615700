import React from "react";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import { Tooltip } from "@material-ui/core";
import {
  ChabadHouseAddressInfo,
  ChabadHouseCampusesInfo,
  ChabadHouseContactInfo,
  ChabadHouseFinancialInfo,
  ChabadHouseGeneralInfo,
  ChabadHousePersonnelInfo,
  ChabadHouseSocialInfo,
} from ".";
import {
  ProfileFormInfoIcon,
  ProfileFormTypographyFlexStyled,
} from "../../ProfileForm.styles";
import { getProfileSectionId, ProfileSectionWrapper } from "../../profileMenu";
import { useMobile } from "../../../../themes";

export interface ChabadHouseInfoProps extends ProfileFormSectionProps {
  chabadHouseIndex: number;
}

export const ChabadHouseInfo = React.memo(
  /**
   *
   */
  function ChabadHouseInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, initialFormValues, intersectionOptions } = props;

    const initialChabadHouseProfile =
      initialFormValues.chabadHouses[chabadHouseIndex];
    const isMobile = useMobile();

    return (
      <ProfileSectionWrapper
        id={getProfileSectionId("chabadHouse", chabadHouseIndex)}
        intersectionOptions={intersectionOptions}
        view="mobile"
      >
        <ProfileSectionWrapper
          id={getProfileSectionId("chabadHouseGeneral", chabadHouseIndex)}
          intersectionOptions={intersectionOptions}
          view="desktop"
        >
          <ProfileFormTypographyFlexStyled
            variant="h6"
            style={{ display: isMobile ? "block" : "flex" }}
          >
            Chabad house info - {initialChabadHouseProfile.name}
            <Tooltip
              title={
                <>
                  To edit Chabad House name, reach out to{" "}
                  <a
                    href="mailto:help@chabadoncampus.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    help@chabadoncampus.org
                  </a>
                </>
              }
            >
              <ProfileFormInfoIcon />
            </Tooltip>
          </ProfileFormTypographyFlexStyled>
          <ChabadHouseGeneralInfo {...props} />
          <ChabadHouseAddressInfo {...props} />
          <ChabadHouseCampusesInfo {...props} />
          <ChabadHouseContactInfo {...props} />
          <ChabadHouseSocialInfo {...props} />
        </ProfileSectionWrapper>
        <ChabadHousePersonnelInfo {...props} />
        <ChabadHouseFinancialInfo {...props} />
      </ProfileSectionWrapper>
    );
  },
);
