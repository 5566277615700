import React from "react";
import Select from "../../../components/form/deprecated-inputs/SelectDeprecated";

export default class BillingAddress extends React.Component {
  toggleUseAddress = (event, address) => {
    const { onChange } = this.props;

    const billingAddress = event.target.checked
      ? {
          id: address.id,
          address1: address.address1 || "",
          address2: address.address2 || "",
          city: address.city || "",
          state: address.state || "",
          zip: address.zip || "",
          country: address.country || "",
          discriminator: address.discriminator,
        }
      : {
          id: 0,
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          discriminator: "None",
        };

    onChange("billing.address", billingAddress);
  };

  render() {
    const {
      billingAddress,
      countries,
      chabadHouseAddress,
      personalAddress,
      onChange,
      onChangeEvent,
      validation,
    } = this.props;

    return (
      <div className="kinus-form-section billing-address-section">
        <p className="medium-text fw-700 mb-16">Billing Address</p>
        <span
          className={validation.billingAddress ? "error-message" : ""}
          style={{
            top: "18px",
            fontFamily: "AvenirBook",
            fontSize: "12px",
            left: "0",
          }}
        >
          {!!validation.billingAddress && `*${validation.billingAddress}`}
        </span>

        {[
          {
            address: chabadHouseAddress,
            label: "Billing address same as Chabad house address",
          },
          {
            address: personalAddress,
            label: "Billing address same as personal address",
          },
        ].map((adrs, index) => (
          <div
            key={index}
            className={`flex custom-checkbox-container mb-8 ${
              !adrs.address ? "disabled" : ""
            }`}
          >
            <input
              type="checkbox"
              className="custom-checkbox"
              id={`use-address=${index}`}
              disabled={!adrs.address}
              checked={adrs.address && billingAddress.id === adrs.address.id}
              onChange={(event) => this.toggleUseAddress(event, adrs.address)}
            />
            <label htmlFor={`use-address=${index}`}>{adrs.label}</label>
          </div>
        ))}

        <form className="billing-address-form mt-24">
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">Street Address</label>
            <input
              type="text"
              className={`custom-input ${
                validation.billingAddress && !billingAddress.address1
                  ? "error"
                  : ""
              }`}
              placeholder="Street Address"
              disabled={billingAddress.id}
              name="billing.address.address1"
              onChange={onChangeEvent}
              value={billingAddress.address1}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">Street Address 2</label>
            <input
              type="text"
              className="custom-input"
              placeholder="Street Address 2 (optional)"
              disabled={billingAddress.id}
              name="billing.address.address2"
              onChange={onChangeEvent}
              value={billingAddress.address2}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">Country</label>
            <Select
              className={`custom-select ${
                validation.billingAddress && !billingAddress.country
                  ? "error"
                  : ""
              }`}
              disabled={billingAddress.id}
              name="billing.address.country"
              onChange={onChange}
              options={
                countries &&
                countries.map((country) => ({
                  display: country.name,
                  value: country.name,
                }))
              }
              value={billingAddress.country}
              clearable={true}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">City</label>
            <div className="flex">
              <input
                type="text"
                className={`custom-input ${
                  validation.billingAddress && !billingAddress.city
                    ? "error"
                    : ""
                }`}
                placeholder="City"
                disabled={billingAddress.id}
                name="billing.address.city"
                onChange={onChangeEvent}
                value={billingAddress.city}
              />
            </div>
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="kinus-form-label">State</label>
            <Select
              disabled={billingAddress.id}
              name="billing.address.state"
              onChange={onChange}
              options={
                billingAddress.country &&
                countries &&
                countries
                  .find((country) => country.name === billingAddress.country)
                  .states.map((state) => ({
                    display: state.name,
                    value: state.name,
                  }))
              }
              value={billingAddress.state}
              clearable={true}
            />
          </div>
          <div className="flex flex-align-center mobile-block">
            <label className="kinus-form-label">Zip</label>
            <input
              type="text"
              className="custom-input"
              placeholder="Zip"
              disabled={billingAddress.id}
              name="billing.address.zip"
              onChange={onChangeEvent}
              value={billingAddress.zip}
            />
          </div>
        </form>
      </div>
    );
  }
}
