import React from "react";
import Select from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";

export default class GeneralDemographics extends React.PureComponent {
  render() {
    const {
      demographics: {
        cityOfOrigin = "",
        cityOfOriginJewishCommunityType = "",
        cityOfOriginRegionID = "",
        familyJewishAffiliation = "",
        halachicJewishStatus = "",
        shliachOverrideJewishnessStatus = "",
        shliachOverrideJewishnessNote = "",
        // jewishRelationshipStatus = "", // removed temporarily
      },
      halachicJewishStatuses,
      jewishCommunityTypes,
      jewishFamilyAffiliationTypes,
      jewishnessStatuses,
      // jewishRelationshipStatuses, // removed temporarily
      onChange,
      onChangeEvt,
      regions,
    } = this.props;

    return (
      <>
        <div className="profile-form-section">
          <p className="profile-section-title">Overall Demographics</p>
          <div className="profile-form-grid">
            <div className="input-container">
              <label>City of Origin</label>
              <input
                name="cityOfOrigin"
                onChange={onChangeEvt}
                type="text"
                value={cityOfOrigin}
              />
            </div>
            <div className="input-container">
              <label>City of Origin Jewish Stats</label>
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                name="cityOfOriginJewishCommunityType"
                onChange={onChange}
                options={
                  jewishCommunityTypes &&
                  jewishCommunityTypes.map((t) => ({
                    label: t.displayValue,
                    value: t.enumValue,
                  }))
                }
                placeholder="Select Stats"
                searchable={true}
                value={cityOfOriginJewishCommunityType}
              />
            </div>
            <div className="input-container">
              <label>Geography</label>
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                name="cityOfOriginRegionID"
                onChange={onChange}
                options={
                  regions &&
                  regions.map((r) => ({
                    label: r.name,
                    value: r.id,
                  }))
                }
                placeholder="Select Region"
                searchable={true}
                value={cityOfOriginRegionID}
              />
            </div>
            <div className="input-container">
              <label>Family Affiliation</label>
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                name="familyJewishAffiliation"
                onChange={onChange}
                options={
                  jewishFamilyAffiliationTypes &&
                  jewishFamilyAffiliationTypes.map((t) => ({
                    label: t.displayValue,
                    value: t.enumValue,
                  }))
                }
                placeholder="Select Affiliation"
                searchable={true}
                value={familyJewishAffiliation}
              />
            </div>
            {/* take out for now put back later
           <div className="input-container">
            <label>Relationship Status</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="jewishRelationshipStatus"
              onChange={onChange}
              options={
                jewishRelationshipStatuses &&
                jewishRelationshipStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={jewishRelationshipStatus}
            />
          </div> */}
            <div className="input-container">
              <label>Halachically Jewish Student</label>
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                name="halachicJewishStatus"
                onChange={onChange}
                options={
                  halachicJewishStatuses &&
                  halachicJewishStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={halachicJewishStatus}
              />
              {halachicJewishStatus === "Complicated" && (
                <p className="xs-text accent-text">
                  Explain in the Notes section below
                </p>
              )}
            </div>
            <div className="input-container">
              <label>Jewishness - Shliach Override</label>
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                name="shliachOverrideJewishnessStatus"
                onChange={onChange}
                options={
                  jewishnessStatuses &&
                  jewishnessStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={shliachOverrideJewishnessStatus}
              />
            </div>
          </div>
        </div>
        <div className="profile-form-section">
          <p className="mb-12 accent-text xs-text uppercase-text">
            Shliach Override Note
          </p>
          <textarea
            className="custom-input"
            type="text"
            name="shliachOverrideJewishnessNote"
            onChange={onChangeEvt}
            value={shliachOverrideJewishnessNote}
          />
        </div>
      </>
    );
  }
}
