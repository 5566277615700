import React, { memo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@material-ui/core";
import BillingModal, { BillingReasons } from "./BillingModal";
import {
  BillingHeaderCardStyled,
  BillingStatusChipStyled,
} from "./Billing.styles";
import {
  getJewishUMembershipEffectiveThroughYear,
  jewishUMembershipExpirationDate,
} from "../../eduUtils";
import { EduEnrollment } from "./BillingTypes";
import { Navigation, useQuery } from "../../../../lib";

interface MembershipStatusProps {
  enrollment: EduEnrollment;
  renewalDisabled: boolean | undefined;
}

function MembershipStatus({
  enrollment,
  renewalDisabled,
}: MembershipStatusProps) {
  const { eduProgramSubscriptionFee, isExpired, isExpiringSoon } = enrollment;

  const location = useLocation();
  const { renew } = useQuery();

  const [showRenewalModal, setShowRenewalModal] = useState(false);

  useEffect(() => {
    if (renew) {
      // show renewal modal when renew query param is set (if renewal is not disabled)
      !renewalDisabled && setShowRenewalModal(true);
      // redirect to clear the renew query param after opening the modal
      Navigation.redirect(location.pathname);
    }
  }, [renewalDisabled, location.pathname, renew]);

  useEffect(() => {
    // hide renewal modal if renewal became disabled after billing details loaded and modal is currently shown
    if (renewalDisabled && showRenewalModal) {
      setShowRenewalModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewalDisabled]);

  const membershipEffectiveThroughYear =
    getJewishUMembershipEffectiveThroughYear();

  return (
    <>
      <BillingHeaderCardStyled className={isExpired ? "error" : ""}>
        <Box>
          <Box className="flex flex-align-center mobile-block">
            <Typography variant="h6">Membership</Typography>
            <BillingStatusChipStyled
              className={isExpired ? "error" : isExpiringSoon ? "warning" : ""}
            >
              {isExpired
                ? "Expired"
                : isExpiringSoon
                ? "Expiring soon"
                : "Active"}
            </BillingStatusChipStyled>
          </Box>
          {(isExpired || isExpiringSoon) && (
            <Button
              color={isExpired ? "default" : "primary"}
              disabled={renewalDisabled}
              onClick={() => setShowRenewalModal(true)}
            >
              Renew
            </Button>
          )}
        </Box>
        <Typography variant="body1">
          {isExpired || isExpiringSoon
            ? `Your annual membership ${
                isExpired
                  ? "has expired"
                  : `will expire on ${jewishUMembershipExpirationDate}`
              }. Please renew your membership for $${eduProgramSubscriptionFee} to continue offering courses`
            : `You're all set! You can now enjoy all the benefits of JewishU`}{" "}
          for {membershipEffectiveThroughYear - 1}-
          {membershipEffectiveThroughYear}.
        </Typography>
      </BillingHeaderCardStyled>
      {showRenewalModal && (
        <BillingModal
          billingAmount={enrollment.eduProgramSubscriptionFee}
          billingReason={BillingReasons.SubscriptionRenewal}
          enrollmentId={enrollment.id}
          onClose={() => setShowRenewalModal(false)}
        />
      )}
    </>
  );
}

export default memo(MembershipStatus);
