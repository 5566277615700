import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../";
import { DateInput, DateInputProps } from "../../inputs/DateInput";

type FormDateFieldProps = {
  onChange?: (name: string, val: any, other?: object | undefined) => void; // optional change handler
  onValueChange?: (val: any) => void;
} & FormFieldProps &
  Omit<DateInputProps, "onChange" | "value">;

export const FormDateField = React.memo(
  /**
   *
   */
  function FormDateField({
    onValueChange,
    name,
    ...passProps
  }: FormDateFieldProps) {
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ...fieldProps } = field;
          const { error } = fieldState;

          return (
            <DateInput
              {...fieldProps}
              id={name}
              onChange={(name, value) => {
                onChange({ target: { name, value } });
                onValueChange && onValueChange(value);
              }}
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);
