import ApiCallErrorMessageHandler from "../ApiCallErrorMessageHandler";
import { AuthRequest } from "../AuthRequest";
import { BaseResponse, SmsPayload, SmsStatement, SmsThread } from "./ApiTypes";

export async function getMessageThread(
  chabadHouseId: number,
  threadId: number,
) {
  const threadResponse: BaseResponse<SmsThread[]> = {};
  try {
    const response = await AuthRequest.get(`sms/thread/${threadId}`, {
      params: { chabadHouseId },
    });
    threadResponse.data = response.data;
  } catch (err) {
    threadResponse.error = ApiCallErrorMessageHandler(err);
  }
  return threadResponse;
}

export async function getSmsStatement(
  chabadHouseId: number,
  startDate: Date,
  endDate: Date,
) {
  const params = {
    startDate: startDate.toLocaleDateString("en-US"),
    endDate: endDate.toLocaleDateString("en-US"),
  };
  const statementResponse: BaseResponse<SmsStatement> = {};
  try {
    const response = await AuthRequest.get(
      `ChabadHouses/${chabadHouseId}/smsStatement`,
      {
        params,
      },
    );
    statementResponse.data = response.data;
  } catch (err) {
    statementResponse.error = ApiCallErrorMessageHandler(err);
  }
  return statementResponse;
}

export async function submitSms(payload: SmsPayload) {
  const sendSmsResponse: BaseResponse<string> = {};
  try {
    await AuthRequest.post("sms/sendBlast", payload);
  } catch (err) {
    sendSmsResponse.error = ApiCallErrorMessageHandler(err);
  }
  return sendSmsResponse;
}
