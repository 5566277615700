import React from "react";
import Toggle from "../../../components/form/deprecated-inputs/ToggleDeprecated";

export default class HotelStay extends React.PureComponent {
  toggleHotelNights = (event, checked, night) => {
    event.stopPropagation();
    event.preventDefault();

    const {
      formProps: {
        onChange,
        values: {
          hotelStay: { nights },
        },
      },
    } = this.props;

    onChange(
      "hotelStay.nights",
      checked
        ? [...nights, night.date]
        : nights.filter((date) => date !== night.date),
    );
  };

  toggleNumberOfRooms = async (name, value) => {
    const {
      formProps: {
        onChange,
        values: {
          hotelStay: { connectingRooms },
        },
      },
    } = this.props;

    await onChange(name, value);

    if (value === 1 && connectingRooms) {
      onChange("hotelStay.connectingRooms", false);
    }
  };

  render() {
    const {
      formProps: { onChange, onChangeEvent, values, validation },
      childrenAttending,
      hotelNights,
      multipleRoomsThreshold,
      pricing,
    } = this.props;

    return (
      <React.Fragment>
        <div className="kinus-form-section relative">
          <p className="medium-text fw-700 mb-16 relative">
            Select the nights you'll be staying at the hotel
            <span
              className={validation.hotelStayNights ? "error-message" : ""}
              style={{
                top: "18px",
                fontFamily: "AvenirBook",
                fontSize: "12px",
                left: "0",
              }}
            >
              {!!validation.hotelStayNights && `*${validation.hotelStayNights}`}
            </span>
          </p>
          <p className="accent-text-dark" style={{ lineHeight: "1.5" }}>
            The Kinus runs from Monday midday to Thursday afternoon. By default,
            rooms are reserved for Monday, Tuesday & Wednesday night and
            check-out is on Thursday. Please uncheck any applicable nights if
            you plan on arriving late or leaving early.
          </p>
          <p className="accent-text-dark" style={{ lineHeight: "1.5" }}>
            In order to accommodate families with long-distance travel,
            additional reservations can be made for the nights prior and/or
            following the Kinus at a secured rate of $
            {
              pricing.find(
                (item) => item.priceCode === "SingleExtraNightInHotel",
              ).subsidizedCost
            }{" "}
            per room/night. The location and amount of rooms will be the same as
            your Kinus reservation. (We are not responsible for meals or
            childcare for those nights).
          </p>
          <div className="hotel-dates flex">
            {hotelNights.map((night, index) => {
              const isSelected =
                values.hotelStay.nights.findIndex(
                  (date) => date === night.date,
                ) >= 0;
              return (
                <div
                  key={index}
                  className={`hotel-date ${isSelected ? "selected" : ""}`}
                  onClick={(event) =>
                    this.toggleHotelNights(event, !isSelected, night)
                  }
                >
                  <div className="flex">
                    <p className="small-text">{night.formattedDate}</p>
                    <div className="radio-button-container">
                      <div className="flex">
                        <input
                          type="checkbox"
                          id={`date-${index}`}
                          checked={isSelected}
                          onChange={(event) => {
                            this.toggleHotelNights(event, !isSelected, night);
                          }}
                        />
                        <label htmlFor={`date-${index}`} />
                      </div>
                    </div>
                  </div>
                  {night.isKinusNight && (
                    <p className="kinus-banner-mobile">Kinus</p>
                  )}
                </div>
              );
            })}
            <p className="kinus-banner">Kinus</p>
          </div>
        </div>
        <div className="kinus-form-section hotel-rooms">
          <p className="medium-text fw-700 mb-16 relative">
            How many hotel rooms do you need?{" "}
            <span
              className={
                validation.hotelStayNumberOfRooms ? "error-message" : ""
              }
              style={{
                top: "18px",
                fontFamily: "AvenirBook",
                fontSize: "12px",
                left: "0",
              }}
            >
              {!!validation.hotelStayNumberOfRooms &&
                `*${validation.hotelStayNumberOfRooms}`}
            </span>
          </p>
          {childrenAttending < multipleRoomsThreshold && (
            <p
              className="small-text mb-8 accent-text-dark"
              style={{ lineHeight: "1.5" }}
            >
              * Due to room constraints second rooms are only available for
              families with four or more children. If this is an issue, please
              complete your registration and contact us at{" "}
              <a
                href="mailto:help@chabadoncampus.org"
                className="link-text"
                target="_blank"
                rel="noopener noreferrer"
              >
                help@chabadoncampus.org
              </a>{" "}
              or{" "}
              <a href="tel:+1-718-510-8181,0" className="link-text">
                718 510 8181 x 0
              </a>
              .
            </p>
          )}

          <div className="flex flex-align-center mb-24">
            <Toggle
              error={validation.hotelStayNumberOfRooms}
              name="hotelStay.numberOfRooms"
              onChange={this.toggleNumberOfRooms}
              options={[
                { value: 1, display: "1" },
                {
                  value: 2,
                  display: "2",
                  disabled: childrenAttending < multipleRoomsThreshold,
                },
              ]}
              value={values.hotelStay.numberOfRooms}
            />
            <div className="flex custom-checkbox-container connecting-rooms-checkbox ml-40">
              <input
                type="checkbox"
                className="custom-checkbox"
                id="connecting-rooms"
                name="hotelStay.connectingRooms"
                checked={values.hotelStay.connectingRooms}
                onChange={onChangeEvent}
                disabled={values.hotelStay.numberOfRooms < 2}
              />
              <label
                htmlFor="connecting-rooms"
                className={values.hotelStay.numberOfRooms < 2 ? "disabled" : ""}
              >
                Click here to request a connecting room
              </label>
            </div>
          </div>
          <p
            className="small-text accent-text-dark mb-8"
            style={{ lineHeight: "1.5" }}
          >
            * Rooms will be next to each other, but NOT necessarily with a
            connecting door. The limited number of connecting rooms will be
            prioritized based on children's needs.
          </p>
          <p
            className="small-text mb-8 accent-text-dark"
            style={{ lineHeight: "1.5" }}
          >
            * There are a limited number of rooms with 2 double beds. Priority
            will be given to families with younger children. You may be assigned
            a room with 2 double beds OR a king room which includes a sofa bed.
          </p>
        </div>
        <div className="kinus-form-section">
          <p className="medium-text fw-700 mb-16">
            Select any extras you need for your room
          </p>
          {values.hotelStay.extras.map((extra, index) => (
            <div
              key={extra.hotelExtras}
              className="flex flex-align-center extras-row"
            >
              <p className="small-text accent-text">{extra.displayValue}</p>
              <select
                disabled={!values.hotelStay.numberOfRooms}
                className="custom-select"
                name={`hotelStay.extras[${index}].numberOfExtras`}
                value={extra.numberOfExtras}
                onChange={(event) =>
                  onChange(event.target.name, parseInt(event.target.value, 10))
                }
              >
                <option defaultChecked={true}>0</option>
                {Array(parseInt(values.hotelStay.numberOfRooms, 10))
                  .fill()
                  .map((_, i) => i + 1)
                  .map((amount) => (
                    <option key={amount} value={amount}>
                      {amount}
                    </option>
                  ))}
              </select>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
