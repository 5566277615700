import React from "react";
import { injectStripe } from "react-stripe-elements";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import moment from "moment";
import { formatCurrency, validateEmail, currencyCodes } from "../../../../lib";
import { RafflePaymentTypes } from "../../constants";

import Modal from "../../../../components/Modal";
import CustomSelectDeprecated from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";
import PhoneInputDeprecated from "../../../../components/form/deprecated-inputs/PhoneInputDeprecated";
import NewOrderAddressInfo from "./NewOrderAddressInfo";
import NewOrderPaymentInfo from "./NewOrderPaymentInfo";

class NewOrderModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      loading: false,
      order: {
        address: {
          address1: "",
          address2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        association: "None",
        cardHolderFullName: "",
        cashTypeID: "",
        ccInfoComplete: false,
        doSendEmail: true,
        email: "",
        firstName: "",
        isPrivate: false,
        isUkGiftAid: false,
        lastName: "",
        paymentNotes: "",
        paymentType: RafflePaymentTypes.Cash,
        phone: "",
        orderDate:
          new Date() <= new Date(props.raffle.details.endDate)
            ? moment().format("YYYY-MM-DD")
            : "",
        referrerID: "",
        sellerEnrollmentID: props.enrollmentId,
        source: "Shliach",
        ticketQuantity: "",
        tribute: "",
      },
      orderValidationErrors: [],
      submitAttempted: false,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  onChangeOrder = (name, value) => {
    let order = _cloneDeep(this.state.order);
    _set(order, name, value);

    this.setState({ order });

    if (this.state.submitAttempted) {
      if (!this.orderIsIncomplete()) {
        this.setState({ errorMessage: "" });
        return;
      }
    }
  };

  onChangeEventOrder = (event) => {
    this.onChangeOrder(
      event.target.name,
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value,
    );
  };

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
    this.props.toggleNewOrderModal();
  };

  onSave = () => {
    this.setState(
      {
        errorMessage: "",
        submitAttempted: true,
      },
      () => {
        if (this.orderIsIncomplete()) {
          this.setState({ errorMessage: "Please review required fields" });
          return;
        }

        const {
          raffle,
          sys: { countries },
        } = this.props;
        const { id } = raffle && raffle.enrollmentDetails;

        const {
          order: { ccInfoComplete, cardHolderFullName, ...orderForSubmission },
          order: { address, paymentType },
        } = this.state;

        this.setState(
          {
            loading: true,
          },
          async () => {
            if (paymentType === RafflePaymentTypes.CreditCard) {
              const billingDetails = {
                name: cardHolderFullName,
              };
              const hasAddress =
                address.address1 ||
                address.address2 ||
                address.city ||
                address.state ||
                address.country ||
                address.zip;
              if (hasAddress) {
                billingDetails.address = {
                  line1: address.address1,
                  line2: address.address2,
                  city: address.city,
                  state: address.state,
                  postal_code: address.zip,
                  country:
                    countries &&
                    (
                      countries.find(
                        (country) => country.name === address.country,
                      ) || {}
                    ).code,
                };
              }

              let { error: paymentMethodError, paymentMethod } =
                await this.props.stripe.createPaymentMethod("card", {
                  billing_details: billingDetails,
                });

              if (paymentMethodError) {
                this.setState({
                  errorMessage:
                    paymentMethodError.message ||
                    "Unable to process cc payment",
                  loading: false,
                });
                return;
              }
              orderForSubmission.paymentMethodId = paymentMethod.id;
            }

            if (orderForSubmission.phone === "") {
              orderForSubmission.phone = null;
            }
            orderForSubmission.sellerEnrollmentID = id;
            orderForSubmission.timeZoneOffset = new Date().getTimezoneOffset();

            await this.props.createOrder(orderForSubmission);
            let {
              success: createOrderSuccess,
              data: createOrderData = {},
              errorMessage: createOrderErrorMessage,
            } = this.props.raffle.createOrder;
            if (createOrderSuccess) {
              if (createOrderData.requiresAction) {
                //SCA Action Required
                const { error: paymentIntentError, paymentIntent } =
                  await this.props.stripe.handleCardAction(
                    createOrderData.paymentIntentClientSecret,
                  );

                if (paymentIntentError) {
                  this.setState({
                    errorMessage:
                      paymentIntentError.message ||
                      "Unable to process cc payment",
                    loading: false,
                  });
                  return;
                } else {
                  // The card action has been handled. The PaymentIntent can be confirmed again on the server
                  delete orderForSubmission.paymentMethodId;
                  orderForSubmission.paymentIntentId = paymentIntent.id;

                  await this.props.createOrder(orderForSubmission);
                  createOrderSuccess = this.props.raffle.createOrder.success;
                  createOrderData = this.props.raffle.createOrder.data;
                  // Checking again if still requiresAction for some reason and if so returning error message
                  if (createOrderSuccess && createOrderData.requiresAction) {
                    this.setState({
                      errorMessage: "Unable to process cc payment",
                      loading: false,
                    });
                    return;
                  }
                }
              }

              //if success at the end of the entire process, can toggle modal to complete order
              if (createOrderSuccess) {
                this.setState({ submitAttempted: "" });
                this.props.toggleNewOrderModal();
                this.props.refreshOrders();
                this.setState(this.state.initialState);
                return;
              }
            }

            this.setState({
              errorMessage: createOrderErrorMessage,
              loading: false,
            });
          },
        );
      },
    );
  };

  orderIsIncomplete = () => {
    const {
      cardHolderFullName,
      ccInfoComplete,
      email,
      firstName,
      lastName,
      ticketQuantity,
      paymentType,
      orderDate,
      cashTypeID,
    } = this.state.order;

    return (
      !firstName ||
      !lastName ||
      !ticketQuantity ||
      !email ||
      (paymentType === RafflePaymentTypes.CreditCard &&
        (!cardHolderFullName || !ccInfoComplete)) ||
      (paymentType === RafflePaymentTypes.Cash && !cashTypeID) ||
      !orderDate
    );
  };

  updateOrderValidation = (name, isValid) => {
    const { orderValidationErrors } = this.state;
    this.setState({
      orderValidationErrors: isValid
        ? orderValidationErrors.filter((err) => err !== name)
        : [...orderValidationErrors, name],
    });
  };

  ukGiftAidCheckbox = () => (
    <div className="flex custom-checkbox-container new-order-checkbox mb-24">
      <input
        className="custom-checkbox"
        id="uk-gift-aid-checkbox"
        name="isUkGiftAid"
        onChange={this.onChangeEventOrder}
        type="checkbox"
        value={this.props.isUkGiftAid}
      />
      <label htmlFor="uk-gift-aid-checkbox" className="flex flex-align-center">
        <span style={{ marginTop: "2px" }} className="small-text">
          Donor is a UK citizen and would like to Gift Aid this donation. Only
          check this if confirmed with donor.
        </span>
      </label>
    </div>
  );

  render() {
    const {
      allowDonorToPayProcessingFee,
      cociCcProcessingFeePercentage,
      currencyCode,
      show,
      ticketPrice,
      raffle: { referrers, details: { startDate, endDate } } = {},
      sys: { cashTypes, countries, raffleDonorAssociations } = {},
    } = this.props;

    const {
      initialState,
      errorMessage,
      loading,
      order,
      order: {
        address,
        association,
        cardHolderFullName,
        cashTypeID,
        ccInfoComplete,
        didDonorPayProcessingFee,
        doSendEmail,
        email,
        firstName,
        isPrivate,
        lastName,
        paymentNotes,
        paymentType,
        phone,
        referrerID,
        ticketQuantity,
        tribute,
        orderDate,
        isDonorThanked,
      },
      orderValidationErrors,
      submitAttempted,
    } = this.state;

    const ticketPriceAmount = ticketQuantity * ticketPrice;

    return (
      <Modal show={show}>
        <div
          className="modal-container order-modal-container"
          key="newOrderModal"
        >
          <div className="modal card new-order-modal">
            <div className="new-order-modal-overflow">
              <p className="xl-text fw-700 mb-24">New Order</p>
              <div className="new-order-modal-grid mb-24">
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">First Name</label>
                  <input
                    type="text"
                    className={`custom-input ${
                      submitAttempted && !firstName ? "error" : ""
                    }`}
                    name="firstName"
                    onChange={this.onChangeEventOrder}
                    value={firstName}
                  />
                </div>
                <div className="desktop-display-contents new-order-modal-grid-reverse-order">
                  <div className="flex flex-justify-space flex-align-center relative mobile-block">
                    <label className="accent-text small-text">
                      Qty Tickets
                    </label>
                    <div className="flex flex-align-center full-width">
                      <input
                        type="number"
                        className={`custom-input ${
                          submitAttempted && !ticketQuantity ? "error" : ""
                        }`}
                        min={1}
                        name="ticketQuantity"
                        onChange={this.onChangeEventOrder}
                        value={ticketQuantity}
                      />
                      {ticketQuantity && (
                        <span className="ml-8">
                          {formatCurrency(
                            ticketQuantity * ticketPrice,
                            currencyCode,
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-justify-space flex-align-center relative mobile-block">
                    <label className="accent-text small-text">Last Name</label>
                    <input
                      type="text"
                      className={`custom-input ${
                        submitAttempted && !lastName ? "error" : ""
                      }`}
                      name="lastName"
                      onChange={this.onChangeEventOrder}
                      value={lastName}
                    />
                  </div>
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Team</label>
                  <CustomSelectDeprecated
                    isClearable={true}
                    placeholder="Select a Team..."
                    onChange={this.onChangeOrder}
                    name="referrerID"
                    options={
                      referrers &&
                      referrers.map((r) => ({
                        key: r.id,
                        value: r.id,
                        label: r.name,
                      }))
                    }
                    value={referrerID}
                  />
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Email</label>
                  <input
                    type="text"
                    className={`custom-input ${
                      (submitAttempted && !email) ||
                      orderValidationErrors.indexOf("email") >= 0
                        ? "error"
                        : ""
                    }`}
                    name="email"
                    onBlur={(event) => {
                      const isValidEmail =
                        !event.target.value ||
                        validateEmail(event.target.value);
                      this.updateOrderValidation(
                        event.target.name,
                        isValidEmail,
                      );
                    }}
                    onChange={this.onChangeEventOrder}
                    value={email}
                  />
                  {orderValidationErrors.indexOf("email") >= 0 && (
                    <span className="error-message field-error-message">
                      Invalid Email Address
                    </span>
                  )}
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Phone</label>
                  {/* Note: we do not save phoneCountry for raffle orders.  We are not defaulting a country bec we don't have an address yet when mounting this phone input. */}
                  <PhoneInputDeprecated
                    name="phone"
                    onChange={(cell) => {
                      this.onChangeOrder("phone", cell);
                    }}
                    error={orderValidationErrors.indexOf("phone") >= 0}
                    validate={(isValid) =>
                      this.updateOrderValidation("phone", isValid)
                    }
                    validateCountry={false}
                    value={phone}
                  />
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Order Date</label>
                  <input
                    type="date"
                    className={`custom-input ${
                      (submitAttempted && !orderDate) ||
                      orderValidationErrors.indexOf("orderDate") >= 0
                        ? "error"
                        : ""
                    }`}
                    name="orderDate"
                    value={orderDate}
                    min={moment(startDate).format("YYYY-MM-DD")}
                    max={moment(endDate).format("YYYY-MM-DD")}
                    onChange={(event) => {
                      this.onChangeEventOrder(event);
                      const date = moment(event.target.value);
                      const isValidDate =
                        !!date &&
                        date >= moment(startDate) &&
                        date <= moment(endDate);
                      this.updateOrderValidation("orderDate", isValidDate);
                    }}
                  />
                  {orderValidationErrors.indexOf("orderDate") >= 0 && (
                    <span className="error-message field-error-message">
                      Invalid Order Date
                    </span>
                  )}
                </div>
                <div className="flex flex-justify-space flex-align-center relative mobile-block">
                  <label className="accent-text small-text">Association</label>
                  <CustomSelectDeprecated
                    isClearable={true}
                    placeholder="Select association..."
                    onChange={(name, val) =>
                      this.onChangeOrder(name, val || "None")
                    }
                    name="association"
                    options={raffleDonorAssociations?.map((a) => ({
                      key: a.enumValue,
                      value: a.enumValue,
                      label: a.displayValue,
                    }))}
                    value={association}
                  />
                </div>
                <div className="flex flex-justify-space flex-align-center relative span-two mobile-block">
                  <label className="accent-text small-text">Tribute</label>
                  <input
                    className="custom-input"
                    name="tribute"
                    onChange={this.onChangeEventOrder}
                    placeholder="In memory of, etc"
                    type="text"
                    value={tribute}
                  />
                </div>
              </div>
              <NewOrderAddressInfo
                countries={countries}
                address={address}
                onChange={this.onChangeOrder}
                onChangeEvent={this.onChangeEventOrder}
              />
              <NewOrderPaymentInfo
                allowDonorToPayProcessingFee={allowDonorToPayProcessingFee}
                paymentType={paymentType}
                cashTypes={cashTypes}
                cashTypeID={cashTypeID}
                paymentNotes={paymentNotes}
                cardHolderFullName={cardHolderFullName}
                ccInfoComplete={ccInfoComplete}
                cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
                currencyCode={currencyCode}
                didDonorPayProcessingFee={didDonorPayProcessingFee}
                submitAttempted={submitAttempted}
                onChange={this.onChangeOrder}
                onChangeEvent={this.onChangeEventOrder}
                ticketPriceAmount={ticketPriceAmount}
              />

              {currencyCode === currencyCodes.GBP && this.ukGiftAidCheckbox()}
            </div>
            <div className="raffle-modal-btns flex flex-justify-space flex-align-center mt-24 relative">
              <div>
                <div className="flex custom-checkbox-container new-order-checkbox">
                  <input
                    className="custom-checkbox"
                    id="private-donation-checkbox"
                    onChange={(event) =>
                      this.onChangeOrder("isPrivate", event.target.checked)
                    }
                    type="checkbox"
                    value={isPrivate}
                    checked={isPrivate}
                  />
                  <label
                    htmlFor="private-donation-checkbox"
                    className="flex flex-align-center"
                  >
                    <span className="small-text mt-2">
                      Do not acknowledge this donation publicly
                    </span>
                  </label>
                </div>
                <div className="flex custom-checkbox-container new-order-checkbox mt-8">
                  <input
                    className="custom-checkbox"
                    id="donor-thanked-checkbox"
                    onChange={(event) =>
                      this.onChangeOrder("isDonorThanked", event.target.checked)
                    }
                    type="checkbox"
                    value={isDonorThanked}
                    checked={isDonorThanked}
                  />
                  <label
                    htmlFor="donor-thanked-checkbox"
                    className="flex flex-align-center"
                  >
                    <span className="small-text mt-2">Donor was thanked</span>
                  </label>
                </div>
                <div className="flex custom-checkbox-container new-order-checkbox mt-8">
                  <input
                    className="custom-checkbox"
                    id="email-receipt-checkbox"
                    onChange={(event) =>
                      this.onChangeOrder("doSendEmail", event.target.checked)
                    }
                    type="checkbox"
                    value={doSendEmail}
                    checked={doSendEmail}
                  />
                  <label
                    htmlFor="email-receipt-checkbox"
                    className="flex flex-align-center"
                  >
                    <span className="small-text mt-2">Send email receipt</span>
                  </label>
                </div>
              </div>

              <div className="flex flex-align-center">
                <button
                  className="btn btn-light btn-medium"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-accent btn-medium ml-16"
                  disabled={
                    orderValidationErrors.length ||
                    _isEqual(initialState.order, order)
                  }
                  onClick={this.onSave}
                  tabIndex={190}
                >
                  {loading ? "Processing..." : "Process Payment"}
                </button>
              </div>
              {submitAttempted && !loading && errorMessage && (
                <p className="error-message new-order-error-message">
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default injectStripe(NewOrderModal);
