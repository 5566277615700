import React, { useCallback, useState } from "react";
import Modal from "../../../components/Modal";
import MaterialRadioGroupDeprecated from "../../../components/form/deprecated-inputs/MaterialRadioGroupDeprecated";
import TextField from "../../../components/form/deprecated-inputs/MuiTextFieldDeprecated";
import { useSelector } from "react-redux";
import { SystemSelectors } from "../../../state";

const radioCheckboxColorProps = {
  color: "#747579",
  checkedColor: "#FFC602",
  labelColor: "#1E1C1C",
};

function StudentRecommendationModal(props) {
  const {
    onRecommendationSubmitted,
    show,
    studentData: { id, studentFirstName, studentLastName } = {},
    submitRecommendation,
    toggleStudentRecommendationModal,
  } = props;

  const studentAcquaintanceLevels = useSelector(
    SystemSelectors.tripStudentAcquaintanceLevels,
  );
  const studentRecommendationStatuses = useSelector(
    SystemSelectors.tripStudentRecommendationStatuses,
  );

  const [recommendation, setRecommendation] = useState({
    recommendationStatus: "",
    acquaintanceLevel: "",
    hadPriorKnowledgeOfStudentRegistration: "",
    relationshipWithStudent: "",
    expectedStudentGrowth: "",
    comments: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onChange = useCallback(
    (name, value) => {
      setRecommendation({ ...recommendation, [name]: value });
      if (errorMessage) setErrorMessage("");
    },
    [errorMessage, recommendation],
  );

  const onSubmit = useCallback(async () => {
    if (
      !recommendation.recommendationStatus ||
      !recommendation.acquaintanceLevel ||
      (recommendation.hadPriorKnowledgeOfStudentRegistration !== true &&
        recommendation.hadPriorKnowledgeOfStudentRegistration !== false) ||
      !recommendation.relationshipWithStudent ||
      !recommendation.expectedStudentGrowth
    ) {
      setSubmitAttempted(true);
      setErrorMessage("Please complete required fields");
      return;
    }

    setErrorMessage("");
    setSubmitting(true);

    const submitted = await submitRecommendation(id, recommendation);

    if (submitted) {
      onRecommendationSubmitted();
      setSubmitted(true);
    } else {
      setErrorMessage(
        "Recommendation could not be submitted. Please try again.",
      );
    }

    setSubmitting(false);
  }, [id, onRecommendationSubmitted, recommendation, submitRecommendation]);

  return (
    <Modal show={show}>
      <div className="modal-container full-page-mobile-modal-container">
        <div
          className={`modal modal-card card student-recommendation-modal relative${
            submitted ? " submitted" : ""
          }`}
        >
          {!submitted ? (
            <>
              <div className="desktop-hidden tablet-hidden modal-mobile-header">
                <i
                  className="material-icons pointer link-text-secondary"
                  onClick={toggleStudentRecommendationModal}
                >
                  close
                </i>
              </div>
              <div className="student-recommendation-header">
                <div>
                  <p className="xxl-text fw-700 mb-8">
                    Shliach/Shlucha Recommendation
                  </p>
                  <p className="large-text fw-700">
                    <span className="accent-text-dark">Student:</span>{" "}
                    {studentFirstName} {studentLastName}
                  </p>
                </div>
                <i
                  className="material-icons link-text-secondary accent-text-dark"
                  onClick={toggleStudentRecommendationModal}
                >
                  close
                </i>
              </div>
              <div className="mb-24">
                <div
                  className={`accent-text-medium line-height-double mb-8${
                    submitAttempted && !recommendation.acquaintanceLevel
                      ? " error-text"
                      : ""
                  }`}
                >
                  Do you know this student?
                </div>
                <MaterialRadioGroupDeprecated
                  {...radioCheckboxColorProps}
                  name="acquaintanceLevel"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  fieldValue={recommendation.acquaintanceLevel}
                  options={studentAcquaintanceLevels.map((l) => ({
                    label: l.displayValue,
                    value: l.enumValue,
                  }))}
                />
              </div>
              <div className="mb-24">
                <div
                  className={`accent-text-medium line-height-double mb-8${
                    submitAttempted &&
                    recommendation.hadPriorKnowledgeOfStudentRegistration === ""
                      ? " error-text"
                      : ""
                  }`}
                >
                  Were you aware that this student signed up for this trip?
                </div>
                <MaterialRadioGroupDeprecated
                  color="#747579"
                  checkedColor="#FFC602"
                  labelColor="#1E1C1C"
                  name="hadPriorKnowledgeOfStudentRegistration"
                  onChange={(e) =>
                    onChange(e.target.name, e.target.value === "true")
                  }
                  fieldValue={
                    recommendation.hadPriorKnowledgeOfStudentRegistration
                  }
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </div>
              <div className="mb-24">
                <div
                  className={`accent-text-medium line-height-double mb-8${
                    submitAttempted && !recommendation.relationshipWithStudent
                      ? " error-text"
                      : ""
                  }`}
                >
                  Please describe your relationship with this student
                </div>
                <TextField
                  className="full-width"
                  minRows={4}
                  multiline={true}
                  name="relationshipWithStudent"
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                  value={recommendation.relationshipWithStudent}
                />
              </div>
              <div className="mb-24">
                <div
                  className={`accent-text-medium line-height-double mb-8${
                    submitAttempted && !recommendation.expectedStudentGrowth
                      ? " error-text"
                      : ""
                  }`}
                >
                  What growth do you hope to see in this student as a result of
                  this trip?
                </div>
                <TextField
                  className="full-width"
                  minRows={4}
                  multiline={true}
                  name="expectedStudentGrowth"
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                  value={recommendation.expectedStudentGrowth}
                />
              </div>
              <div className="mb-24">
                <div
                  className={`accent-text-medium line-height-double mb-8${
                    submitAttempted && !recommendation.recommendationStatus
                      ? " error-text"
                      : ""
                  }`}
                >
                  Do you recommend this student for this trip?
                </div>
                <MaterialRadioGroupDeprecated
                  {...radioCheckboxColorProps}
                  name="recommendationStatus"
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  fieldValue={recommendation.recommendationStatus}
                  options={studentRecommendationStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))}
                />
              </div>
              <div className="mb-24">
                <div className="accent-text-medium line-height-double mb-8">
                  Please share any comments or other information that you feel
                  would be helpful in determining if this is the right trip for
                  your student. (optional)
                </div>
                <TextField
                  className="full-width"
                  minRows={4}
                  multiline={true}
                  name="comments"
                  onChange={(e) => {
                    onChange(e.target.name, e.target.value);
                  }}
                  variant="outlined"
                  value={recommendation.comments}
                />
              </div>
              <div className="student-recommendation-footer">
                <button
                  className="btn btn-accent"
                  disabled={submitting}
                  onClick={onSubmit}
                >
                  {submitting ? "Submitting..." : "Submit"}
                </button>
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="modal-mobile-header text-right mobile-text-left">
                <i
                  className="material-icons pointer link-text-secondary"
                  onClick={toggleStudentRecommendationModal}
                >
                  close
                </i>
              </div>
              <div className="mt-24 mb-96">
                <img src="/images/enrollmentComplete.svg" alt="" height="180" />
                <div className="xxl-text line-height-double fw-700 mt-24">
                  Your recommendation was successfully submitted.
                </div>
              </div>
              <div className="student-recommendation-footer">
                <button
                  className="btn btn-light"
                  onClick={toggleStudentRecommendationModal}
                >
                  Close
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
export default React.memo(StudentRecommendationModal);
