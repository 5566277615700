import React from "react";
import Select from "react-select";
import { Button, Typography, Grid } from "@material-ui/core";
import CheckboxDeprecated from "../../../components/form/deprecated-inputs/CheckboxDeprecated";
import Loader from "../../../components/Loader";
import PaginatedList from "../../../components/PaginatedList";
import ActivityListRow from "../activity/list/ActivityListRow";
import StudentInteractionModal from "../activity/StudentInteractionModal.vm";
import { formatFullName, Navigation, PageURL } from "../../../lib";
import moment from "moment";
import { Add } from "@material-ui/icons";

export default class StudentActivity extends React.PureComponent {
  state = {
    isCloningInteraction: false,
    page: 1,
    resultsView: 10,
    selectedStudentInteraction: null,
    showStudentInteractionModal: false,
  };

  componentDidMount() {
    const {
      isShliach,
      pageRoute: { page: routePage, params, query },
    } = this.props;
    const { li: showLamplightersActivity } = query;

    if (showLamplightersActivity && !isShliach) {
      // if the user is not a shliach, clear out the showLamplightersActivity query filter
      const url = PageURL.to(routePage, params, {
        ...query,
        li: undefined,
      });
      Navigation.redirect(url);
    }
  }

  getActivity = (page) => {
    this.setState({ page });

    const {
      getActivity,
      lamplightersSchedules,
      pageRoute: { page: routePage, params, query },
      student,
    } = this.props;
    const { li: showLamplightersActivity, pt: programType } = query;

    const activityFilters = {
      programType,
      studentId: student.id,
    };
    if (showLamplightersActivity) {
      const currentLamplightersSchedule = lamplightersSchedules.find(
        (s) => s.isCurrent,
      );

      if (currentLamplightersSchedule) {
        activityFilters.grantScheduleId = currentLamplightersSchedule.id;
      } else {
        // if there is no current lamplighters schedule,
        // clear out the showLamplightersActivity query filter
        const url = PageURL.to(routePage, params, {
          ...query,
          li: undefined,
        });
        Navigation.redirect(url);
      }
    }

    getActivity(page, this.state.resultsView, activityFilters, true);
  };

  getCategorizedActivity = (activity) => {
    const categorizedActivity = {};

    activity.forEach((activity) => {
      const category = moment(activity.dateTime).format("MMM YYYY");
      if (!categorizedActivity.hasOwnProperty(category)) {
        categorizedActivity[category] = [];
      }
      categorizedActivity[category].push(activity);
    });

    return categorizedActivity;
  };

  renderCategorizedActivity = (activity, currentLamplightersScheduleId) => {
    const categorizedActivity = this.getCategorizedActivity(activity);

    const {
      isShliach,
      student: { person: { firstName, lastName } = {} },
    } = this.props;

    return Object.keys(categorizedActivity).map(
      (categoryKey, categoryIndex) => (
        <React.Fragment key={categoryIndex}>
          <p className="medium-text fw-700 mt-16 mb-16">{categoryKey}</p>
          {categorizedActivity[categoryKey].map((activity, index) => (
            <ActivityListRow
              activity={activity}
              key={index}
              isShliach={isShliach}
              isStudentActivityView={true}
              isLamplightersEligibleInteraction={({ grantScheduleID }) =>
                grantScheduleID === currentLamplightersScheduleId
              }
              onCloneInteraction={() => {
                this.onChooseInteraction(activity, true);
              }}
              onEditInteraction={() => {
                this.onChooseInteraction(activity);
              }}
              studentName={formatFullName(firstName, lastName)}
            />
          ))}
        </React.Fragment>
      ),
    );
  };

  onChooseInteraction(activity, isCloningInteraction = false) {
    this.setState({
      isCloningInteraction,
      selectedStudentInteraction: activity,
      showStudentInteractionModal: true,
    });
  }

  onFilterActivity = (name, val) => {
    const {
      pageRoute: { query, page, params },
    } = this.props;

    const url = PageURL.to(page, params, {
      ...query,
      [name]: val ? encodeURIComponent(val) : undefined,
    });
    Navigation.redirect(url);

    setTimeout(() => this.getActivity(1), 0);
  };

  render() {
    const {
      activity: {
        data: { results = [], numberOfRows = 0 } = {},
        loading,
        success,
      },
      lamplightersSchedules,
      mobileView,
      pageRoute: {
        query: { li: showLamplightersActivity = false, pt: programType = "" },
      },
      refreshStudentDetails,
      student: { id: studentId, firstEncounterDate },
      sys: { studentActivityProgramTypes = [] },
    } = this.props;
    const {
      isCloningInteraction,
      page,
      resultsView,
      selectedStudentInteraction,
      showStudentInteractionModal,
    } = this.state;

    const programTypeOptions = [
      { label: "All Programs", value: "" },
      ...(studentActivityProgramTypes
        ? studentActivityProgramTypes.map((p) => ({
            value: p.enumValue,
            label: p.displayValue,
          }))
        : []),
    ];

    const currentLamplightersSchedule =
      lamplightersSchedules && lamplightersSchedules.find((s) => s.isCurrent);

    return (
      <div>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} container direction="row">
            <Grid item container xs={12} justifyContent="space-between" md={3}>
              <Typography variant="h5">Activity</Typography>
              {mobileView && (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() =>
                    this.setState({ showStudentInteractionModal: true })
                  }
                >
                  <Add />
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              direction="row"
              container
              alignItems="center"
              className="lamplighters-students-checkbox-container"
              justifyContent="flex-end"
            >
              {!!currentLamplightersSchedule && (
                <CheckboxDeprecated
                  checked={!!showLamplightersActivity}
                  className="lamplighters-students-checkbox accent-text-dark mr-16"
                  label="Only show Lamplighters interactions"
                  name="showLamplightersActivity"
                  onChange={(_, value) => this.onFilterActivity("li", value)}
                />
              )}
              {!mobileView && (
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() =>
                    this.setState({ showStudentInteractionModal: true })
                  }
                >
                  <Add /> Interaction
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid item container>
            <div className="flex flex-justify-space">
              <div
                className="flex flex-align-center mobile-ml-0"
                style={{ width: "240px" }}
              >
                <p className="mr-8 nowrap-text">Filter by</p>
                <Select
                  className="filter-select"
                  classNamePrefix="filter-select"
                  defaultValue={programTypeOptions[0]}
                  isSearchable={false}
                  onChange={({ value }) => this.onFilterActivity("pt", value)}
                  options={programTypeOptions}
                  value={
                    programTypeOptions.find((p) => p.value === programType) ||
                    programTypeOptions[0]
                  }
                />
              </div>
            </div>
          </Grid>
        </Grid>
        {showLamplightersActivity && !lamplightersSchedules ? (
          <Loader /> // if showLamplightersActivity filter is set but lamplighters schedules have not been loaded yet, show a loader to avoid rendering paginated table which will attempt to load activity for the current schedule
        ) : (
          <div>
            <PaginatedList
              className="students-activity-table"
              loadData={this.getActivity}
              loading={loading}
              name="activity"
              page={page}
              records={results}
              renderRecords={(activity) =>
                this.renderCategorizedActivity(
                  activity,
                  currentLamplightersSchedule && currentLamplightersSchedule.id,
                )
              }
              resultsView={resultsView}
              success={success}
              totalCount={numberOfRows}
            />
          </div>
        )}
        {showStudentInteractionModal && (
          <StudentInteractionModal
            close={() =>
              this.setState({
                isCloningInteraction: false,
                selectedStudentInteraction: null,
                showStudentInteractionModal: false,
              })
            }
            interactionId={
              selectedStudentInteraction &&
              selectedStudentInteraction.interactionID
            }
            isCloningInteraction={isCloningInteraction}
            onSubmitInteraction={async (interaction) => {
              if (isCloningInteraction) {
                this.setState({
                  isCloningInteraction: false,
                  selectedStudentInteraction: null,
                });
              }
              this.getActivity(page);
              //if logged interaction date is prior to current first encounter date refresh student details to get new first encounter date
              if (
                interaction &&
                moment(interaction.dateTime).isBefore(
                  moment(firstEncounterDate),
                )
              ) {
                refreshStudentDetails();
              }
            }}
            show={showStudentInteractionModal}
            studentId={studentId}
          />
        )}
      </div>
    );
  }
}
