import { ProfileForm } from "./ProfileForm";

export const ProfilePages = {
  profile: {
    path: "/account",
    title: "My Account",
    type: "PAGE_ACCOUNT",
    view: ProfileForm,
  },
};

export default ProfilePages;

export const ProfileArea = {
  pages: ProfilePages,
};
