import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { StudentsActions } from "../../../state";
import StudentProfileForm from "./StudentProfileForm";

export default connect(
  function mapState(state) {
    return {
      campuses: state.students.studentCampuses,
      loggedInAs: state.auth.loggedInAs,
      profile: state.students.student,
      profileSettings: state.students.studentProfileSettings,
      submitProfile: state.students.submitStudent,
      deleteStudent: state.students.deleteStudent,
      sys: state.sys,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          ...StudentsActions,
        },
        dispatch,
      ),
    };
  },
)(StudentProfileForm);
