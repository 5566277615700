import React, { useState } from "react";

import Modal from "../../../components/Modal";
import {
  Checkbox,
  FormControlLabel,
  Button,
  Collapse,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Visibility, Close } from "@material-ui/icons";
import { getTripEmailPreviewText } from "../../../lib";

function StudentConfirmationModal(props) {
  const {
    show,
    toggleModal,
    onUpdate,
    programScheduleName,
    studentData: {
      id = "",
      status = "",
      studentFirstName = "",
      campusName = "",
    } = {},
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [showPreview, setShowPreview] = useState(false);
  const [sendRejectionEmail, setSendRejectionEmail] = useState(true);

  const onConfirm = async () => {
    setSubmitting(true);

    const { success, errorMessage } = await onUpdate(
      id,
      status,
      !sendRejectionEmail,
    );

    setSubmitting(false);

    if (success) {
      toggleModal();
    } else {
      setErrorMessage(
        errorMessage?.response?.data?.messages?.join(", ") ||
          "Something went wrong. Please try again.",
      );
    }
  };

  const confirmationType = status === "Accepted" ? "Accept" : "Reject";

  return (
    <Modal show={show}>
      <div className="modal-container ">
        <div className="modal card student-confirmation-modal relative flex flex-column flex-justify-space">
          <div>
            <div className="flex flex-justify-space flex-align-center">
              <p className="medium-text fw-700 xxl-text">
                {confirmationType} student
              </p>
              <IconButton disabled={submitting} onClick={() => toggleModal()}>
                <Close />
              </IconButton>
            </div>
            <p className="accent-text mt-16">
              Are you sure you want to {confirmationType.toLowerCase()} this
              student?
            </p>
            {confirmationType === "Reject" && (
              <>
                <FormControlLabel
                  className="mt-24 send-email-checkbox"
                  label="Send rejection email to student"
                  control={
                    <Checkbox
                      checked={sendRejectionEmail}
                      name="sendStudentEmail"
                      onChange={() =>
                        setSendRejectionEmail(!sendRejectionEmail)
                      }
                    />
                  }
                />
                <Button
                  onClick={() => setShowPreview(!showPreview)}
                  color="primary"
                  disableRipple
                  className={"preview-btn"}
                >
                  <Visibility fontSize="small" className="visibility-icon" />{" "}
                  Preview Email
                  {showPreview ? (
                    <ExpandLess fontSize="small" />
                  ) : (
                    <ExpandMore fontSize="small" />
                  )}
                </Button>

                <Collapse in={showPreview}>
                  <Box className="email-preview-wrapper">
                    <Typography variant="body2">
                      {getTripEmailPreviewText({
                        studentFirstName,
                        campusName,
                        programScheduleName,
                      })}
                    </Typography>
                  </Box>
                </Collapse>
              </>
            )}
          </div>
          <div className="button-wrapper">
            <Button
              variant="contained"
              className="cancel-btn"
              disabled={submitting}
              onClick={() => toggleModal()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={
                confirmationType === "Accept" ? "accept-btn" : "reject-btn"
              }
              disabled={submitting}
              onClick={onConfirm}
            >
              {confirmationType}
              {submitting ? "ing..." : ""}
            </Button>
          </div>
          {errorMessage && (
            <div className="error-text small-text text-right">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(StudentConfirmationModal);
