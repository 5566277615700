import React, { useState, useEffect } from "react";
import { Collapse } from "@material-ui/core";
import Toggle from "../../../components/form/deprecated-inputs/ToggleDeprecated";

export default function TeamsSection(props) {
  const { allowPublicCreateReferrer, onChange, showAllExpanded } = props;

  const [showExpanded, setShowExpanded] = useState(showAllExpanded);

  const handleToggleExpand = () => {
    setShowExpanded(!showExpanded);
  };

  useEffect(() => {
    setShowExpanded(showAllExpanded);
  }, [showAllExpanded]);

  return (
    <div className="raffles-form-section">
      <div className="raffles-section-title" onClick={handleToggleExpand}>
        <div className="flex">
          <p className="large-text fw-900 flex flex-align-center pointer mb-8">
            Teams
          </p>
        </div>
        {showExpanded ? (
          <i className="material-icons accent-text-secondary xl-text">remove</i>
        ) : (
          <i className="material-icons accent-text-secondary xl-text">add</i>
        )}
      </div>

      <Collapse in={showExpanded}>
        <div className="expanded-section">
          <p className="accent-text-dark mb-24 line-height-double">
            Select if you'd like to allow public ability to create teams.
          </p>
          <Toggle
            name="allowPublicCreateReferrer"
            onChange={onChange}
            options={[
              {
                value: true,
                display: "Allow",
              },
              {
                value: false,
                display: "Don't allow",
              },
            ]}
            value={allowPublicCreateReferrer}
          />
        </div>
      </Collapse>
    </div>
  );
}
