import React from "react";
import NewOrderModal from "./newOrder/NewOrderModal";
import BulkImportModal from "./BulkImportModal";
import ExportCSVButton from "../../../components/ExportCSVButton";
import StripeProvider from "../../../components/StripeProvider";
import { formatExcelNumberString } from "../../../lib/utils";
import moment from "moment";

export default class OrderActions extends React.PureComponent {
  state = {
    showNewOrderModal: false,
    showBulkOrderUploadModal: false,
  };

  exportHeaders = [
    { label: "Order Number", value: "orderNumber" },
    { label: "Ticket Number", value: "ticketNumber" },
    { label: "Quantity", value: "quantity" },
    { label: "Amount", value: "amount" },
    { label: "Ticket Price Amount", value: "ticketPriceAmount" },
    { label: "Processing Fee Donor Amount", value: "processingFeeDonorAmount" },
    { label: "Payment Method", value: "paymentMethod" },
    { label: "Cash Type", value: "cashType" },
    { label: "Source", value: "source" },
    {
      label: "Date",
      value: (order) => moment(order.dateOrderedOn).format("MM/DD/YYYY"),
    },
    {
      label: "Date Order Created",
      value: (order) => moment(order.createdOn).format("MM/DD/YYYY"),
    },
    { label: "First Name", value: "donorFirstName" },
    { label: "Last Name", value: "donorLastName" },
    { label: "Email", value: "donorEmail" },
    { label: "Phone", value: "donorPhone" },
    { label: "Association", value: "associationDisplay" },
    { label: "Billing Address Line 1", value: "billingAddress1" },
    { label: "Billing Address Line 2", value: "billingAddress2" },
    { label: "Billing Address City", value: "billingCity" },
    { label: "Billing Address State", value: "billingState" },
    { label: "Billing Address Country", value: "billingCountry" },
    {
      label: "Billing Zip",
      value: (order) => formatExcelNumberString(order.billingZip),
    },
    { label: "Team", value: "referrerName" },
    { label: "Tribute", value: "tribute" },
    {
      label: "CC - Last 4 Digits",
      value: (order) => (order.lastFour ? `*${order.lastFour}` : null),
    },
    { label: "Private", value: (order) => (order.isPrivate ? "*" : null) },
    {
      label: "UK Gift Aid",
      value: (order) => (order.isUkGiftAid ? "*" : null),
    },
    {
      label: "Refunded",
      value: (order) =>
        !!order.refundAmount
          ? !order.amount
            ? "Fully Refunded"
            : "Partially Refunded"
          : "",
    },
  ];

  getExportHeaders = (exportType) => {
    return exportType === "tickets"
      ? this.exportHeaders.filter((field) => field.label !== "Refunded")
      : this.exportHeaders.filter((field) => field.value !== "ticketID");
  };

  getRaffleOrdersForExport = async (exportType) => {
    await this.props.getRaffleOrdersForExport(
      this.props.enrollmentID,
      this.props.pageRoute.query.q,
      exportType === "tickets",
      this.props.raffleID,
    );
    return this.props.raffle.ordersForExport.results;
  };

  toggleNewOrderModal = () => {
    this.setState({ showNewOrderModal: !this.state.showNewOrderModal });
  };

  toggleBulkOrderUploadModal = () => {
    this.setState({
      showBulkOrderUploadModal: !this.state.showBulkOrderUploadModal,
    });
  };

  render() {
    const {
      allowDonorToPayProcessingFee,
      cociCcProcessingFeePercentage,
      createOrder,
      currencyCode,
      enrollmentID,
      isOpenForManualTicketEntry,
      bulkOrderTemplateURL,
      getRaffleOrders,
      raffle,
      refreshOrders,
      stripePublicKey,
      sys,
      ticketPrice,
    } = this.props;
    let { showNewOrderModal, showBulkOrderUploadModal } = this.state;
    return (
      <div className="flex shliach-orders-buttons ml-32">
        {enrollmentID && isOpenForManualTicketEntry && (
          <React.Fragment>
            <button
              className="btn btn-accent flex flex-align-center mr-8"
              onClick={() => this.toggleNewOrderModal()}
            >
              Add Order
            </button>

            <StripeProvider apiKey={stripePublicKey}>
              <NewOrderModal
                allowDonorToPayProcessingFee={allowDonorToPayProcessingFee}
                cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
                createOrder={createOrder}
                currencyCode={currencyCode}
                raffle={raffle}
                refreshOrders={refreshOrders}
                show={showNewOrderModal}
                sys={sys}
                toggleNewOrderModal={this.toggleNewOrderModal}
                ticketPrice={ticketPrice}
              />
            </StripeProvider>
          </React.Fragment>
        )}

        <div className="dropdown-container mr-8">
          <button className="btn btn-accent flex flex-align-center">
            CSV Export
            <i className="material-icons ml-8">arrow_drop_down</i>
          </button>
          <div className="dropdown btn-dropdown csv-export-dropdown">
            <ExportCSVButton
              className="small-text link-text-secondary"
              fileName="Raffle-Order-Tickets"
              getExportData={() => this.getRaffleOrdersForExport("tickets")}
              getHeaders={() => this.getExportHeaders("tickets")}
              title="Export as Tickets"
            />
            <ExportCSVButton
              className="small-text mb-0 mt-16 link-text-secondary"
              fileName="Raffle-Orders"
              getExportData={() =>
                this.getRaffleOrdersForExport("transactions")
              }
              getHeaders={() => this.getExportHeaders("transactions")}
              title="Export as Orders"
            />
          </div>
        </div>

        {enrollmentID && isOpenForManualTicketEntry && (
          <React.Fragment>
            <button
              className="btn btn-accent flex flex-align-center"
              onClick={() => this.setState({ showBulkOrderUploadModal: true })}
            >
              Bulk Import
            </button>
            <BulkImportModal
              bulkOrderTemplateURL={bulkOrderTemplateURL}
              close={() => this.toggleBulkOrderUploadModal(false)}
              enrollmentID={enrollmentID}
              getRaffleOrders={getRaffleOrders}
              importBulkOrders={this.props.importBulkOrders}
              pageRoute={this.props.pageRoute}
              raffle={this.props.raffle}
              show={showBulkOrderUploadModal}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
