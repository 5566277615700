import React from "react";
import CustomSelectDeprecated from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";
import NewOrderCCInfo from "./NewOrderCCInfo";
import { RafflePaymentTypes } from "../../constants";

export default class NewOrderPaymentInfo extends React.PureComponent {
  render() {
    let {
      allowDonorToPayProcessingFee,
      paymentType,
      cashTypeID,
      cashTypes,
      ccInfoComplete,
      cardHolderFullName,
      cociCcProcessingFeePercentage,
      currencyCode,
      didDonorPayProcessingFee,
      submitAttempted,
      onChange,
      onChangeEvent,
      paymentNotes,
      ticketPriceAmount,
    } = this.props;
    return (
      <div className="mb-24 new-order-payment-method">
        <p className="fw-700 mb-16">Payment Method</p>
        <div className="radio-button-container flex mb-24 mobile-block">
          <div className="flex mobile-mb-8">
            <input
              type="radio"
              name="paymentType"
              id="cash"
              value={RafflePaymentTypes.Cash}
              checked={paymentType === RafflePaymentTypes.Cash}
              onChange={onChangeEvent}
            />
            <label
              htmlFor="cash"
              style={{
                fontSize: "14px",
                paddingLeft: "28px",
                width: "auto",
                minWidth: "auto",
              }}
            >
              Record donation
              <span className="small-text accent-text">
                {" "}
                Monies already collected
              </span>
            </label>
          </div>
          <div className="flex mr-24 mobile-mb-8">
            <input
              type="radio"
              name="paymentType"
              id="cc"
              value={RafflePaymentTypes.CreditCard}
              checked={paymentType === RafflePaymentTypes.CreditCard}
              onChange={onChangeEvent}
            />
            <label
              htmlFor="cc"
              style={{
                fontSize: "14px",
                paddingLeft: "28px",
                width: "auto",
                minWidth: "auto",
              }}
            >
              Save donation
              <span className="small-text accent-text"> Collect funds now</span>
            </label>
          </div>
        </div>
        {paymentType === RafflePaymentTypes.CreditCard && (
          <NewOrderCCInfo
            allowDonorToPayProcessingFee={allowDonorToPayProcessingFee}
            cardHolderFullName={cardHolderFullName}
            ccInfoComplete={ccInfoComplete}
            cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
            currencyCode={currencyCode}
            didDonorPayProcessingFee={didDonorPayProcessingFee}
            onChange={onChange}
            onChangeEvent={onChangeEvent}
            submitAttempted={submitAttempted}
            ticketPriceAmount={ticketPriceAmount}
          />
        )}
        {paymentType === RafflePaymentTypes.Cash && (
          <div className="flex flex-align-center">
            <label
              className="accent-text small-text mr-24"
              style={{ width: 144, minWidth: "auto" }}
            >
              Payment Method
            </label>
            <CustomSelectDeprecated
              menuPlacement="top"
              name="cashTypeID"
              options={cashTypes.map((type) => ({
                key: type.id,
                value: type.id,
                label: type.name,
              }))}
              onChange={onChange}
              value={cashTypeID}
              className={submitAttempted && !cashTypeID ? "error" : ""}
            />
          </div>
        )}
        <div className="flex flex-align-center flex-justify-space mobile-block mt-16">
          <label
            className="accent-text small-text mr-24"
            style={{ width: 144, minWidth: "auto" }}
          >
            Payment Notes (optional)
          </label>
          <input
            type="text"
            className="custom-input full-width"
            name="paymentNotes"
            placeholder="Payment Notes (optional)"
            onChange={onChangeEvent}
            value={paymentNotes}
          />
        </div>
      </div>
    );
  }
}
