import React, { useCallback, useRef } from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";
import { useMobile } from "../../../themes";
import { useDebounce } from "../../../lib";

export const profileMenuItemClassName = "profile-menu-item";

export const getProfileMenuItemId = (tabId: string) =>
  `${tabId}-profile-menu-item`;

export function getProfileSectionId(
  type:
    | "person"
    | "spouse"
    | "family"
    | "chabadHouse"
    | "chabadHouseGeneral"
    | "chabadHousePersonnel"
    | "chabadHouseFinancial"
    | "campus"
    | "campusGeneral"
    | "campusJewishStudentLife",
  chabadHouseOrCampusIndex?: number,
) {
  return chabadHouseOrCampusIndex
    ? `${type}-${chabadHouseOrCampusIndex}`
    : type;
}

const scrollViewThresholds = [0, 0.25, 0.5, 1];

export function useProfileMenu({
  scrollMarginTop = 0,
}: {
  scrollMarginTop?: number;
} = {}) {
  const rootMargin = `-${scrollMarginTop}px 0px 0px 0px`;

  const visibleSectionPercentages = useRef<Record<string, number>>({});

  const scrollActiveMenuItemIntoView = useCallback(() => {
    document
      .getElementsByClassName(`${profileMenuItemClassName} active`)[0]
      ?.scrollIntoView();
  }, []);
  const debounceScrollActiveMenuItemIntoView = useDebounce(
    scrollActiveMenuItemIntoView,
    250,
  );

  const updateActiveMenuItem = useCallback(() => {
    const maxVisibleSectionItemId = Object.keys(
      visibleSectionPercentages.current,
    )
      .reverse()
      .reduce(
        (p1, p2) =>
          visibleSectionPercentages.current[p1] >
          visibleSectionPercentages.current[p2]
            ? p1
            : p2,
        "",
      );

    const menuItems = document.querySelectorAll(`.${profileMenuItemClassName}`);
    menuItems.forEach((i: Element) => {
      if (i.classList.contains("active")) i.classList.remove("active");
    });

    const activeMenuItem = document.getElementById(
      getProfileMenuItemId(maxVisibleSectionItemId),
    );
    if (activeMenuItem) {
      activeMenuItem.classList.add("active");
      debounceScrollActiveMenuItemIntoView();
    }
  }, [debounceScrollActiveMenuItemIntoView, visibleSectionPercentages]);

  const onIntersectionChange = useCallback(
    (_, entry: IntersectionObserverEntry) => {
      const visiblePercentage =
        (entry.intersectionRect.height * 100) / entry.boundingClientRect.height;
      visibleSectionPercentages.current = {
        ...visibleSectionPercentages.current,
        [entry.target.id]: visiblePercentage,
      };

      updateActiveMenuItem();
    },
    [updateActiveMenuItem],
  );

  return {
    intersectionOptions: {
      onChange: onIntersectionChange,
      rootMargin,
      threshold: scrollViewThresholds,
    },
  };
}

interface ProfileSectionWrapperProps {
  children: React.ReactNode;
  id: string;
  intersectionOptions: IntersectionOptions;
  view?: "desktop" | "mobile";
}

export const ProfileSectionWrapper = React.memo(function ProfileSectionWrapper(
  props: ProfileSectionWrapperProps,
) {
  const isMobile = useMobile();

  const { children, id, intersectionOptions, view } = props;

  const scrollMarginTop =
    intersectionOptions.rootMargin?.split("px")[0].replace("-", "") || 0;

  const [ref] = useInView(intersectionOptions);

  const skipWrapper =
    (view === "mobile" && !isMobile) || (view === "desktop" && isMobile);

  return skipWrapper ? (
    <>{children}</>
  ) : (
    <div id={id} ref={ref} style={{ scrollMarginTop }}>
      {children}
    </div>
  );
});
