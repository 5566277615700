import React from "react";
import { Button } from "@material-ui/core";
import Navigation from "../../../../lib/routing/Navigation";
import { MessageScheduleTypes, WizardStepProps } from "../WizardHelpers";
import { PageURL } from "../../../../lib";
import AppPages from "../../..";

export const SentMessageStatus = React.memo(function SentMessageStatus(
  props: WizardStepProps,
) {
  const { messageScheduleType, onClose } = props;
  return (
    <div className="mt-48">
      <div className="message-status-content">
        <img width="400px" src="/images/sms_sent.svg" alt="message sent" />

        <div className="xxl-text mt-24 mb-24 fw-900">
          Your message was successfully{" "}
          {messageScheduleType === MessageScheduleTypes.Scheduled
            ? "scheduled"
            : "sent"}
        </div>

        <div className="mt-24">
          <Button
            className="mr-16"
            size="small"
            onClick={() => {
              onClose();
              Navigation.go(
                PageURL.to(AppPages.engagement.messaging.path, {
                  params: { view: "inbox" } as any,
                }),
              );
            }}
            variant="contained"
          >
            Go to inbox
          </Button>
          <Button
            size="small"
            onClick={() => {
              onClose();
            }}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
});
