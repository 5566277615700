import React, { useCallback, useEffect, useState, useMemo } from "react";

import {
  Box,
  Button,
  Collapse,
  FormGroup,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import MaterialCheckbox from "../../components/form/deprecated-inputs/MaterialCheckboxDeprecated";
import Loader from "../../components/Loader";

import {
  getAllInternClaims,
  getClaimsForIntern,
  updateClaimsForIntern,
} from "../../state/interns/requests";

import _cloneDeep from "lodash.clonedeep";
import { formatFullName } from "../../lib";

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: "AvenirHeavy",
    fontSize: "18px",
    paddingBottom: "20px",
  },

  row: {
    "& > *": {
      fontFamily: "AvenirHeavy",
      fontSize: "14px",
      borderBottom: "unset",
    },
  },

  categoryGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
  },

  formGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  tooltip: {
    [theme.breakpoints.up("md")]: {
      left: ({ tooltipIndex }) =>
        tooltipIndex % 3 === 2 ? "unset !important" : "-24px !important",
      right: ({ tooltipIndex }) =>
        tooltipIndex % 3 !== 2 ? "unset !important" : "-24px !important",
    },
    [theme.breakpoints.down("xs")]: {
      width: "200px",
      left: "-24px !important",
      right: "unset !important",
    },
  },
}));

export default function InternPermissions(props) {
  const { chabadHousePersonnelID, firstName, lastName, onClose } = props;

  const [allInternClaims, setAllInternClaims] = useState([]);
  const [internPermissions, setInternPermissions] = useState([]);
  const [initialInternPermissions, setInitialInternPermissions] = useState([]);

  const [loadingAllInternsClaims, setLoadingAllInternsClaims] = useState(true);
  const [loadingThisInternsClaims, setLoadingThisInternsClaims] =
    useState(true);

  const [loadingAllInternClaimsError, setLoadingAllInternClaimsError] =
    useState(null);
  const [loadingThisInternsClaimsError, setLoadingThisInternsClaimsError] =
    useState(null);
  const [saveError, setSaveError] = useState(null);

  useEffect(() => {
    async function loadAllClaims() {
      const response = await getAllInternClaims();
      if (response.error) {
        setLoadingAllInternClaimsError(response.errorMessage);
      } else {
        setAllInternClaims(response);
      }
    }

    loadAllClaims();
    setLoadingAllInternsClaims(false);
  }, []);

  useEffect(() => {
    async function setInitialValues() {
      const response = await getClaimsForIntern(chabadHousePersonnelID);
      if (response.error) {
        setLoadingThisInternsClaimsError(response.errorMessage);
      } else {
        setInternPermissions(response);
        setInitialInternPermissions(response);
      }
    }

    setInitialValues();
    setLoadingThisInternsClaims(false);
  }, [chabadHousePersonnelID]);

  const loading = useMemo(
    () => loadingAllInternsClaims || loadingThisInternsClaims,
    [loadingAllInternsClaims, loadingThisInternsClaims],
  );

  const onSave = useCallback(() => {
    async function updatePermissions() {
      const response = await updateClaimsForIntern(
        chabadHousePersonnelID,
        internPermissions,
      );
      if (response.error) {
        setSaveError(response.errorMessage);
      } else {
        setSaveError(null);
        onClose();
      }
    }

    updatePermissions();
  }, [chabadHousePersonnelID, internPermissions, onClose]);

  return (
    <Modal
      open={true}
      className="modal-container"
      style={{ overflow: "scroll" }}
    >
      <div className="access-card modal-card card">
        <div className="xxl-text fw-700">
          Manage access - {formatFullName(firstName, lastName)}
        </div>
        <div className="accent-text mt-10 medium-text">
          Select which programs and permissions this person has access to.
        </div>
        <div>
          {loading ? (
            <Loader />
          ) : loadingAllInternClaimsError ? (
            <div className="mt-24 mb-24 medium-text">
              All personnel permissions could not be loaded:{" "}
              {loadingAllInternClaimsError}
            </div>
          ) : loadingThisInternsClaimsError ? (
            <div className="mt-24 mb-24 medium-text">
              Permissions could not be loaded for{" "}
              {formatFullName(firstName, lastName)}:{" "}
              {loadingThisInternsClaimsError}
            </div>
          ) : (
            <Programs
              allInternClaims={allInternClaims || []}
              internPermissions={internPermissions || []}
              initialPermissions={initialInternPermissions}
              onChange={setInternPermissions}
            />
          )}
        </div>
        <div className="text-right">
          <div>
            <button
              className="btn btn-light btn-medium"
              disabled={loading}
              onClick={onClose}
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn btn-accent btn-medium ml-16"
              disabled={
                loading ||
                loadingAllInternClaimsError ||
                loadingThisInternsClaimsError
              }
              type="button"
              onClick={onSave}
            >
              Save
            </button>
          </div>
          {saveError && <div className="mt-4 error-text">{saveError}</div>}
        </div>
      </div>
    </Modal>
  );
}

function Programs(props) {
  const { allInternClaims, internPermissions, initialPermissions, onChange } =
    props;

  const [allExpanded, setAllExpanded] = useState(false);

  const classes = useStyles();

  return (
    <div className="access-programs-wrapper">
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classes.header}>
              Programs
              <Button
                className="access-select-buttons"
                onClick={() => setAllExpanded(!allExpanded)}
              >
                {allExpanded ? "Collapse all" : "Expand all"}
              </Button>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allInternClaims.map((row, i) => (
            <ProgramRow
              key={i}
              row={row}
              internPermissions={internPermissions}
              onChange={onChange}
              allExpanded={allExpanded}
              initialPermissions={initialPermissions}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function ProgramRow(props) {
  const { row, internPermissions, initialPermissions, onChange, allExpanded } =
    props;

  const [expanded, setExpanded] = useState(allExpanded);
  const classes = useStyles();

  useEffect(() => {
    setExpanded(allExpanded);
  }, [allExpanded]);

  useEffect(() => {
    const wasSelectedInitially = row.claims?.some((p) =>
      initialPermissions.some((i) => i.internPage === p.enumValue),
    );
    setExpanded(!!wasSelectedInitially);
  }, [initialPermissions, row.claims]);

  const onChangeProgramPages = (claims, select) => {
    const updatedPermissions = _cloneDeep(internPermissions);
    if (select) {
      claims.forEach((claim) => {
        const permissionAlreadySelected = updatedPermissions.some(
          (p) => p.id === claim.id,
        );
        if (!permissionAlreadySelected) {
          updatedPermissions.push(claim);
        }
      });
    } else {
      claims.forEach((claim) => {
        const originalRecordIndex = updatedPermissions.findIndex(
          (p) => p.id === claim.id,
        );
        if (originalRecordIndex >= 0) {
          updatedPermissions.splice(originalRecordIndex, 1);
        }
      });
    }

    onChange(updatedPermissions);
  };

  const hasSelected = row.claims.some((claim) =>
    internPermissions.some((p) => p.id === claim.id),
  );
  const allSelected = row.claims.every((claim) =>
    internPermissions.some((p) => p.id === claim.id),
  );

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell align="left">
          {row.category}
          {expanded && (
            <span>
              <Button
                className="access-select-buttons"
                onClick={() => {
                  onChangeProgramPages(row.claims, true);
                }}
              >
                Select all
              </Button>
              <Button
                className="access-select-buttons"
                onClick={() => {
                  onChangeProgramPages(row.claims, false);
                }}
              >
                Clear all
              </Button>
            </span>
          )}
        </TableCell>
        <TableCell align="right" style={{ color: "#747579" }}>
          {expanded ? (
            ""
          ) : allSelected ? (
            <div className="access-section-status">All</div>
          ) : hasSelected ? (
            <div
              className="access-section-status"
              style={{ background: "#FFC602", color: "black" }}
            >
              Limited
            </div>
          ) : (
            <div
              className="access-section-status"
              style={{ background: "#F4F4F5", color: "black" }}
            >
              None
            </div>
          )}
        </TableCell>

        <TableCell align="right" style={{ width: 50 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <div className={classes.categoryGrid}>
                {row.claims?.map((claim, i) => {
                  const isChecked = internPermissions.some(
                    (p) => p.id === claim.id,
                  );

                  const onChange = (v) =>
                    onChangeProgramPages([claim], v.target.checked);

                  return (
                    <PermissionClaim
                      claim={claim}
                      index={i}
                      isChecked={isChecked}
                      onChange={onChange}
                    />
                  );
                })}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function PermissionClaim({ claim, index, isChecked, onChange }) {
  const classes = useStyles({ tooltipIndex: index });

  return (
    <div key={index}>
      <FormGroup className={classes.formGroup}>
        <MaterialCheckbox
          color="#47484a"
          checkedColor="#FFC602"
          labelColor="#242426"
          label={claim.display}
          labelStyle={{ marginRight: 4 }}
          checked={isChecked}
          onChange={onChange}
        />
        <span className="tooltip-container flex ml-4">
          <i className="material-icons accent-text no-hover large-text">info</i>
          <span className={`tooltip ${classes.tooltip}`}>
            {claim.description}
          </span>
        </span>
      </FormGroup>
    </div>
  );
}
