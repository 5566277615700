import React, { useState, memo } from "react";
import { Menu, Button, MenuItem } from "@material-ui/core";
import StudentInteractionModal from "../pages/students/activity/StudentInteractionModal.vm";
import StudentProfileModal from "../pages/students/studentProfile/StudentProfileModal";
import { SmsWizard } from "../pages/sms/wizard/Wizard";

interface SystemShortcutsMenuProps {
  buttonText?: string;
  btnSize?: "small" | "medium" | "large" | undefined;
  className?: string;
}
function SystemShortcutsMenu(props: SystemShortcutsMenuProps) {
  const { buttonText = "Add", btnSize = "small", className = "" } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [showAddInteractionModal, setShowAddInteractionModal] = useState(false);
  const [showSendSmsModal, setShowSendSmsModal] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        size={btnSize}
        className={className}
      >
        {buttonText}
      </Button>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowAddStudentModal(true);
          }}
        >
          Add Student
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowAddInteractionModal(true);
          }}
        >
          Add Interaction
        </MenuItem>
        {/* we will need to hide this for those that messaging not configured */}
        <MenuItem
          onClick={() => {
            handleClose();
            setShowSendSmsModal(true);
          }}
        >
          Send Sms Message
        </MenuItem>
      </Menu>
      {showAddStudentModal && (
        <StudentProfileModal
          close={() => setShowAddStudentModal(false)}
          show={showAddStudentModal}
        />
      )}
      {showAddInteractionModal && (
        <StudentInteractionModal
          /* @ts-ignore */
          close={() => setShowAddInteractionModal(false)}
          show={showAddInteractionModal}
        />
      )}
      {showSendSmsModal && (
        <SmsWizard onClose={() => setShowSendSmsModal(false)} />
      )}
    </>
  );
}

export default memo(SystemShortcutsMenu);
