import React, { memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Box, Grid, makeStyles } from "@material-ui/core";
import DashboardCard from "./components/DashboardCard";
import WelcomeUser from "../../components/WelcomeUser";

import {
  ActivityActions,
  AuthSelectors,
  EduActions,
  EduSelectors,
  useAction,
  RaffleSelectors,
  RaffleActions,
} from "../../state";
import Pages from "../../pages";

import { InternPermissions, doArraysIntersect } from "../../lib";

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "72px",
      paddingBottom: "40px",
    },
  },
  grid: {
    justifyContent: "flex-start",
    columnGap: "32px",
    rowGap: "32px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
}));

function InternDashboard() {
  //SELECTORS
  const jewishuProgramInfo = useSelector(EduSelectors.jewishUProgramDetails);
  const yourIsraelProgramInfo = useSelector(
    EduSelectors.yourIsraelProgramDetails,
  );
  const raffle = useSelector(RaffleSelectors.raffle);
  const shliachID = useSelector(AuthSelectors.shliachID);

  //ACTIONS
  const loadActivity = useAction(ActivityActions.getActivity);
  const getEduEnrolledPrograms = useAction(EduActions.getEduEnrolledPrograms);
  const internPermissions = useSelector(AuthSelectors.claims);
  const getHistoricRaffleEnrollments = useAction(
    RaffleActions.getHistoricEnrollments,
  );

  const internActions = useMemo(
    () =>
      [
        {
          title: "Students",
          page: Pages.engagement.students,
          imageSrc: "/images/manageStudents.svg",
          permissions: [InternPermissions.manageStudents],
        },
        {
          title: "Activity",
          page: Pages.engagement.studentsActivity,
          imageSrc: "/images/manageActivity.svg",
          permissions: [InternPermissions.manageActivity],
        },
        {
          title: "Events",
          page: Pages.engagement.rsvp,
          pageParams: { pageView: "events" },
          imageSrc: "/images/manageRsvpEvents.svg",
          permissions: [InternPermissions.manageRsvp],
        },
        {
          title: "Resources",
          page: Pages.engagement.rsvp,
          pageParams: { pageView: "resources" },
          imageSrc: "/images/manageRsvpResources.svg",
          permissions: [InternPermissions.manageRsvp],
        },
        {
          title: "RSVP Settings",
          page: Pages.engagement.rsvp,
          pageParams: { pageView: "settings" },
          imageSrc: "/images/manageRsvpSettings.svg",
          permissions: [InternPermissions.manageRsvp],
        },
        jewishuProgramInfo && {
          title: "JewishU",
          page: Pages.edu.edu,
          pageParams: {
            programScheduleId: jewishuProgramInfo.programScheduleID,
          },
          imageSrc: "/images/manageJewishU.svg",
          permissions: [
            InternPermissions.eduAttendance,
            InternPermissions.eduSchedule,
            InternPermissions.eduStudents,
          ],
        },
        yourIsraelProgramInfo && {
          title: "Your Israel",
          page: Pages.edu.edu,
          pageParams: {
            programScheduleId: yourIsraelProgramInfo.programScheduleID,
          },
          imageSrc: "/images/manageYourIsrael.svg",
          permissions: [
            InternPermissions.eduAttendance,
            InternPermissions.eduSchedule,
            InternPermissions.eduStudents,
          ],
        },
        !!raffle?.historicEnrollments?.length && {
          title: "Grand Draw",
          page: Pages.raffle.raffle,
          pageParams: {
            programScheduleID: raffle.historicEnrollments[0].programScheduleID,
          },
          imageSrc: "/images/manageRaffle.svg",
          permissions: [
            InternPermissions.manageRaffleDonations,
            InternPermissions.manageRaffleSettings,
            InternPermissions.viewRaffleMetrics,
          ],
        },
      ].filter(
        (action) =>
          action && doArraysIntersect(action.permissions, internPermissions),
      ),
    [internPermissions, jewishuProgramInfo, yourIsraelProgramInfo, raffle],
  );

  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  useEffect(() => {
    if (
      doArraysIntersect(internPermissions, [
        InternPermissions.eduAttendance,
        InternPermissions.eduSchedule,
        InternPermissions.eduStudents,
      ])
    ) {
      getEduEnrolledPrograms();
    }
  }, [internPermissions, getEduEnrolledPrograms]);

  useEffect(() => {
    if (
      doArraysIntersect(internPermissions, [
        InternPermissions.manageRaffleDonations,
        InternPermissions.manageRaffleSettings,
        InternPermissions.viewRaffleMetrics,
      ])
    ) {
      getHistoricRaffleEnrollments(shliachID);
    }
  }, [internPermissions, getHistoricRaffleEnrollments, shliachID]);

  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <WelcomeUser />

      <Grid container className={classes.grid}>
        {internActions.map((i, index) => (
          <Grid item md={3} key={index}>
            <DashboardCard {...i!} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(InternDashboard);
