import { styled, Chip, Box } from "@material-ui/core";
export const ChipStyled = styled(Chip)(() => ({
  marginRight: "4px",
}));

export const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
}));
