import React from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { ProgramLevels } from "../../ProfileFormHelpers";
import {
  FormFieldWatch,
  FormNumberField,
} from "../../../../components/form/react-hook-form";
import { CampusInfoProps } from "./CampusInfo";
import {
  ProfileFormGridFullRowItemStyled,
  ProfileFormGridItemFlexStyled,
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
  ProfileFormTypographyFlexStyled,
} from "../../ProfileForm.styles";

export const CampusPopulationInfo = React.memo(
  /**
   *
   */
  function SchoolPopulationInfo(props: CampusInfoProps) {
    const { campusIndex } = props;
    const campusFieldsAccessor = `campuses.${campusIndex}`;
    return (
      <Box>
        <FormFieldWatch name={`campuses.${campusIndex}.programLevels`}>
          {(programLevels) => {
            const hasUndergrad = programLevels.some(
              (p: string) => p === ProgramLevels.Undergrad,
            );
            const hasGrad = programLevels.some(
              (p: string) => p === ProgramLevels.Grad,
            );
            return (
              <>
                <ProfileFormTypographyFlexStyled variant="subtitle1">
                  School Population
                  <Tooltip
                    title={
                      programLevels.length
                        ? "Population details are automatically filled in for you based on public records."
                        : "Population details will be automatically filled in once School Demographics are set."
                    }
                  >
                    <ProfileFormInfoIcon />
                  </Tooltip>
                </ProfileFormTypographyFlexStyled>
                <ProfileFormGridStyled
                  style={{ marginTop: "0px", marginBottom: "50px" }}
                >
                  {hasUndergrad && (
                    <DemographicPopulation
                      demographic={ProgramLevels.Undergrad}
                      campusFieldsAccessor={campusFieldsAccessor}
                    />
                  )}
                  {hasGrad && (
                    <DemographicPopulation
                      demographic={ProgramLevels.Grad}
                      campusFieldsAccessor={campusFieldsAccessor}
                    />
                  )}
                  {hasGrad && hasUndergrad && (
                    <DemographicPopulation
                      isTotal={true}
                      campusFieldsAccessor={campusFieldsAccessor}
                    />
                  )}
                </ProfileFormGridStyled>
              </>
            );
          }}
        </FormFieldWatch>
      </Box>
    );
  },
);

interface DemogrpahicPopulationProps {
  demographic?: string;
  campusFieldsAccessor: string;
  isTotal?: boolean;
}

function DemographicPopulation(props: DemogrpahicPopulationProps) {
  const { demographic, campusFieldsAccessor, isTotal } = props;
  const populationFieldName =
    demographic === ProgramLevels.Undergrad
      ? `${campusFieldsAccessor}.undergradPopulation`
      : `${campusFieldsAccessor}.graduatePopulation`;
  const jewishPopulationFieldName =
    demographic === ProgramLevels.Undergrad
      ? `${campusFieldsAccessor}.jewishUndergradPopulation`
      : `${campusFieldsAccessor}.jewishGraduatePopulation`;

  return (
    <ProfileFormGridItemStyled>
      <ProfileFormGridStyled>
        <ProfileFormGridFullRowItemStyled>
          <Typography variant="subtitle1">
            {isTotal ? "Total" : demographic} students
          </Typography>
        </ProfileFormGridFullRowItemStyled>
        <ProfileFormGridFullRowItemStyled
          style={{
            paddingTop: isTotal ? 16 : 8,
            paddingBottom: isTotal ? 16 : 8,
          }}
        >
          <ProfileFormGridStyled>
            <ProfileFormGridItemFlexStyled sm={6} xs={8}>
              <Typography>Population</Typography>
            </ProfileFormGridItemFlexStyled>
            <ProfileFormGridItemStyled sm={6} xs={4}>
              {isTotal ? (
                <>
                  <FormFieldWatch
                    name={[
                      `${campusFieldsAccessor}.undergradPopulation`,
                      `${campusFieldsAccessor}.graduatePopulation`,
                    ]}
                  >
                    {([undergradPopulation, graduatePopulation]) => {
                      const total =
                        (parseInt(undergradPopulation) || 0) +
                        (parseInt(graduatePopulation) || 0);

                      return <Typography>{total || ""}</Typography>;
                    }}
                  </FormFieldWatch>
                </>
              ) : (
                <FormNumberField name={populationFieldName} />
              )}
            </ProfileFormGridItemStyled>
          </ProfileFormGridStyled>
        </ProfileFormGridFullRowItemStyled>
        <ProfileFormGridFullRowItemStyled
          style={{
            paddingTop: isTotal ? 16 : 8,
            paddingBottom: isTotal ? 16 : 8,
          }}
        >
          <ProfileFormGridStyled>
            <ProfileFormGridItemFlexStyled sm={6} xs={8}>
              <Typography>Jewish Population</Typography>
            </ProfileFormGridItemFlexStyled>
            <ProfileFormGridItemStyled sm={6} xs={4}>
              {isTotal ? (
                <>
                  <FormFieldWatch
                    name={[
                      `${campusFieldsAccessor}.jewishUndergradPopulation`,
                      `${campusFieldsAccessor}.jewishGraduatePopulation`,
                    ]}
                  >
                    {([
                      jewishUndergradPopulation,
                      jewishGraduatePopulation,
                    ]) => {
                      const total =
                        (parseInt(jewishUndergradPopulation) || 0) +
                        (parseInt(jewishGraduatePopulation) || 0);

                      return <Typography>{total || ""}</Typography>;
                    }}
                  </FormFieldWatch>
                </>
              ) : (
                <FormNumberField name={jewishPopulationFieldName} />
              )}
            </ProfileFormGridItemStyled>
          </ProfileFormGridStyled>
        </ProfileFormGridFullRowItemStyled>
        <ProfileFormGridFullRowItemStyled>
          <ProfileFormGridStyled>
            <ProfileFormGridItemFlexStyled sm={6} xs={8}>
              <Typography>Jewish Percentage</Typography>
            </ProfileFormGridItemFlexStyled>
            <ProfileFormGridItemStyled sm={6} xs={4}>
              {isTotal ? (
                <>
                  <FormFieldWatch
                    name={[
                      `${campusFieldsAccessor}.undergradPopulation`,
                      `${campusFieldsAccessor}.graduatePopulation`,
                      `${campusFieldsAccessor}.jewishUndergradPopulation`,
                      `${campusFieldsAccessor}.jewishGraduatePopulation`,
                    ]}
                  >
                    {([
                      undergradPopulation,
                      graduatePopulation,
                      jewishUndergradPopulation,
                      jewishGraduatePopulation,
                    ]) => {
                      const totalPopulation =
                        (parseInt(undergradPopulation) || 0) +
                        (parseInt(graduatePopulation) || 0);
                      const totalJewishPopulation =
                        (parseInt(jewishUndergradPopulation) || 0) +
                        (parseInt(jewishGraduatePopulation) || 0);
                      return (
                        <Typography>
                          {totalPopulation
                            ? `${percentage(
                                totalJewishPopulation,
                                totalPopulation,
                              )}%`
                            : ""}
                        </Typography>
                      );
                    }}
                  </FormFieldWatch>
                </>
              ) : (
                <FormFieldWatch
                  name={[populationFieldName, jewishPopulationFieldName]}
                >
                  {([population, jewishPopulation]) => {
                    const jewishPercentage = percentage(
                      jewishPopulation,
                      population,
                    );
                    return (
                      <Typography>
                        {population && jewishPercentage
                          ? `${jewishPercentage}%`
                          : ""}
                      </Typography>
                    );
                  }}
                </FormFieldWatch>
              )}
            </ProfileFormGridItemStyled>
          </ProfileFormGridStyled>
        </ProfileFormGridFullRowItemStyled>
      </ProfileFormGridStyled>
    </ProfileFormGridItemStyled>
  );
}

function percentage(partialValue: number, totalValue: number) {
  if (!partialValue || !totalValue) {
    return;
  }
  return ((partialValue / totalValue) * 100).toFixed(2);
}
