import React, { useState } from "react";

import _cloneDeep from "lodash.clonedeep";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Delete from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import { formatFullName, partition, dateAddDays } from "../../../lib";
import MultiSelectDeprecated from "../../../components/form/deprecated-inputs/MultiSelectDeprecated";
import ChaperoneModal from "./ChaperoneModal";

function Attendees(props) {
  const {
    staffList,
    attendees,
    termsAndConditions,
    validation,
    onChange,
    studentRegistrationEndDate,
  } = props;

  const disableChaperones =
    new Date() > dateAddDays(new Date(studentRegistrationEndDate), 2);

  const [showNewChaperoneModal, setShowNewChaperoneModal] = useState(false);

  const [shluchim, chabadHousePersonnel] = partition(
    staffList,
    (s) => s.isShliach,
  );
  const [attendingShluchim, chaperones] = partition(
    attendees,
    (a) => !a.isChaperone,
  );

  const getFormattedList = (list) => {
    return list.map((item) => ({
      key: item,
      value: formatFullName(item.firstName, item.lastName),
      label: formatFullName(item.firstName, item.lastName),
    }));
  };

  return (
    <>
      <div className="trip-page-form-section shluchim-section">
        <p
          className={`medium-text fw-700 mb-16 ${
            validation.attendees ? "error-text" : ""
          }`}
        >
          Shluchim
        </p>
        <p className="accent-text-dark mb-8 line-height-double">
          Please select which shluchim will be joining your group. Don’t send
          your students to pegisha, bring your students to pegisha. At least one
          shliach or shlucha should attend. You can add additional chaperones
          below.
        </p>
        <div>
          <MultiSelectDeprecated
            placeholder="Select..."
            name="attendees"
            onChange={(name, value) => {
              onChange(name, [...value.map((a) => a.key), ...chaperones]);
            }}
            options={getFormattedList(shluchim)}
            value={getFormattedList(attendingShluchim)}
          />
        </div>
      </div>
      <div className="trip-page-form-section">
        <div className="flex flex-align-center add-icon">
          <p className="medium-text fw-700">Additional Chaperones</p>
          <IconButton
            onClick={() => setShowNewChaperoneModal(true)}
            disabled={disableChaperones}
          >
            <AddCircleIcon color="primary" />
          </IconButton>
        </div>
        {disableChaperones && (
          <p className="accent-text-dark mb-12 mt-12 line-height-double">
            This section can no longer be edited.
          </p>
        )}
        {!!chaperones.length && (
          <div className="mt-24">
            {chaperones.map((chaperone, i) => (
              <div key={i} className="flex flex-align-center chaperone-wrapper">
                <div className="chaperone three-column-grid">
                  <p className="flex flex-align-center fw-500 ml-16">
                    {formatFullName(chaperone.firstName, chaperone.lastName)}
                  </p>
                  <p className="flex flex-align-center fw-300">
                    {chaperone.relationship}
                  </p>
                  <p className="flex flex-align-center fw-300">
                    {chaperone.phoneNumber}
                  </p>
                </div>
                <IconButton
                  disabled={disableChaperones}
                  onClick={() => {
                    const _attendees = _cloneDeep(attendees);
                    const chaperoneToDelete = attendees.indexOf(chaperone);
                    _attendees.splice(chaperoneToDelete, 1);
                    onChange("attendees", _attendees);
                  }}
                >
                  <Delete />
                </IconButton>
              </div>
            ))}
          </div>
        )}
      </div>
      {showNewChaperoneModal && (
        <ChaperoneModal
          chabadHousePersonnel={chabadHousePersonnel}
          chaperones={chaperones}
          termsAndConditions={termsAndConditions}
          onSubmit={(newChaperone) => {
            attendees.push(newChaperone);
            onChange("attendees", attendees);
          }}
          onClose={() => setShowNewChaperoneModal(false)}
        />
      )}
    </>
  );
}
export default React.memo(Attendees);
