import React from "react";
import CustomCheckboxDeprecated from "../../../../components/form/deprecated-inputs/CustomCheckboxDeprecated";
import moment from "moment";

export default ({
  student: {
    primaryShliach,
    primaryShlucha,
    demographics: {
      childhoodKosherStatusDisplay,
      childhoodShabbosHolidayStatusDisplay,
      childhoodSynagogueAttendanceStatusDisplay,
      childhoodTefillinStatusDisplay,
      childhoodTorahStudyStatusDisplay,
      cityOfOrigin,
      cityOfOriginJewishCommunityTypeDisplay,
      cityOfOriginRegionName,
      currentKosherStatusDisplay,
      currentShabbosHolidayStatusDisplay,
      currentSynagogueAttendanceStatusDisplay,
      currentTefillinStatusDisplay,
      currentTorahStudyStatusDisplay,
      familyJewishAffiliationDisplay,
      firstEncounterDate,
      halachicJewishStatusDisplay,
      interests,
      jewishAffiliationDisplay,
      jewishInterestLevelDisplay,
      jewishInvolvementUpdatedOn,
      jewishRelationshipStatusDisplay,
      jewishObservanceStatusUpdatedOn,
      jewishTrajectoryUpdatedOn,
      learningEventsParticipationDisplay,
      notes,
      orgInvolvementTypes,
      personality,
      personalShliachShluchaTimeParticipationDisplay,
      programParticipationTypes,
      referralSourceDescription,
      shabbatDinnerParticipationDisplay,
      socialEventsParticipationDisplay,
    } = {},
    person: { gender } = {},
  },
  sys: {
    studentOrgInvolvementTypes = [],
    studentProgramParticipationTypes = [],
  },
}) => (
  <div className="student-background">
    <div>
      <p className="fw-900 medium-text mb-16">Overall Demographics</p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">City of Origin</p>
          <p>{cityOfOrigin || "—"}</p>
          <p className="fw-700">City of Origin Jewish Stats</p>
          <p>{cityOfOriginJewishCommunityTypeDisplay || "—"}</p>
          <p className="fw-700">Geography</p>
          <p>{cityOfOriginRegionName || "—"}</p>
        </div>
        <div className="two-column-grid">
          <p className="fw-700">Family Affiliation</p>
          <p>{familyJewishAffiliationDisplay || "—"}</p>
          {/* take out for now add back in later<p className="fw-700">Relationship Status</p>
          <p>{jewishRelationshipStatusDisplay || "—"}</p> */}
          <p className="fw-700">Halachically Jewish Student</p>
          <p>{halachicJewishStatusDisplay || "—"}</p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Childhood Jewish Involvement</p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">Shabbos and Holiday</p>
          <p>{childhoodShabbosHolidayStatusDisplay || "—"}</p>
          <p className="fw-700">Kosher Status</p>
          <p>{childhoodKosherStatusDisplay || "—"}</p>
          {gender !== "Female" && (
            <React.Fragment>
              <p className="fw-700">Tefillin Status</p>
              <p>{childhoodTefillinStatusDisplay || "—"}</p>
            </React.Fragment>
          )}
        </div>
        <div className="two-column-grid" style={{ height: "fit-content" }}>
          <p className="fw-700">Torah Study Status</p>
          <p>{childhoodTorahStudyStatusDisplay || "—"}</p>
          <p className="fw-700">Synagogue Attendance Status</p>
          <p>{childhoodSynagogueAttendanceStatusDisplay || "—"}</p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">
        Current Jewish Involvement
        {!!jewishObservanceStatusUpdatedOn && (
          <span className="small-text accent-text fw-700 ml-8">
            Last updated on{" "}
            {moment(jewishObservanceStatusUpdatedOn).format("MM/D/YYYY")}
          </span>
        )}
      </p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">Shabbos and Holiday</p>
          <p>{currentShabbosHolidayStatusDisplay || "—"}</p>
          <p className="fw-700">Kosher Status</p>
          <p>{currentKosherStatusDisplay || "—"}</p>
          {gender !== "Female" && (
            <React.Fragment>
              <p className="fw-700">Tefillin Status</p>
              <p>{currentTefillinStatusDisplay || "—"}</p>
            </React.Fragment>
          )}
        </div>
        <div className="two-column-grid" style={{ height: "fit-content" }}>
          <p className="fw-700">Torah Study Status</p>
          <p>{currentTorahStudyStatusDisplay || "—"}</p>
          <p className="fw-700">Synagogue Attendance Status</p>
          <p>{currentSynagogueAttendanceStatusDisplay || "—"}</p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">
        Student Trajectory
        {!!jewishTrajectoryUpdatedOn && (
          <span className="small-text accent-text fw-700 ml-8">
            Current Jewish affiliation last updated on{" "}
            {moment(jewishTrajectoryUpdatedOn).format("MM/D/YYYY")}
          </span>
        )}
      </p>
      <div className="two-column-grid">
        <p className="fw-700">Jewish Interest Level</p>
        <p>{jewishInterestLevelDisplay || "—"}</p>
        <p className="fw-700">Current Jewish Affiliation</p>
        <p>{jewishAffiliationDisplay || "—"}</p>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">
        Current Chabad/Community Involvement
        {!!jewishInvolvementUpdatedOn && (
          <span className="small-text accent-text fw-700 ml-8">
            Last updated on{" "}
            {moment(jewishInvolvementUpdatedOn).format("MM/D/YYYY")}
          </span>
        )}
      </p>
      <div className="two-column-grid">
        <div className="two-column-grid">
          <p className="fw-700">Shliach/Shlucha</p>
          <p>
            {[primaryShliach?.fullName, primaryShlucha?.fullName]
              .filter((n) => n)
              .join("/") || "—"}
          </p>
          <p className="fw-700">First COC Encounter</p>
          <p>
            {firstEncounterDate
              ? moment(firstEncounterDate).format("MMMM D, YYYY")
              : "—"}
          </p>
          <p className="fw-700">Referred By</p>
          <p>{referralSourceDescription || "—"}</p>

          <p className="fw-700">Personal Time with Shliach</p>
          <p>{personalShliachShluchaTimeParticipationDisplay || "—"}</p>
        </div>
        <div className="two-column-grid" style={{ height: "fit-content" }}>
          <p className="fw-700">Shabbat Dinner</p>
          <p>{shabbatDinnerParticipationDisplay || "—"}</p>
          <p className="fw-700">Social Events</p>
          <p>{socialEventsParticipationDisplay || "—"}</p>
          <p className="fw-700">Learning Events</p>
          <p>{learningEventsParticipationDisplay || "—"}</p>
        </div>
      </div>
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Other Program Participation</p>
      <CustomCheckboxDeprecated
        name="programParticipationTypes"
        options={
          studentProgramParticipationTypes &&
          studentProgramParticipationTypes.map((t) => ({
            display: t.description,
            value: t.id,
          }))
        }
        readOnly={true}
        values={
          programParticipationTypes
            ? programParticipationTypes.map((t) => t.id)
            : []
        }
      />
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Org Involvement</p>
      <CustomCheckboxDeprecated
        name="orgInvolvementTypes"
        options={
          studentOrgInvolvementTypes &&
          studentOrgInvolvementTypes.map((t) => ({
            display: t.description,
            value: t.id,
          }))
        }
        readOnly={true}
        values={orgInvolvementTypes ? orgInvolvementTypes.map((t) => t.id) : []}
      />
    </div>
    <div className="student-background-divider" />
    <div>
      <p className="fw-900 medium-text mb-16">Interests and Hobbies</p>
      <p>
        {interests && interests.length
          ? interests.map((i, index) => (
              <span className="mr-8 student-profile-tag" key={index}>
                {i.name}
              </span>
            ))
          : "—"}
      </p>
    </div>
    <div className="student-background-divider" />
    {/* take out for now put back later
     <div>
      <p className="fw-900 medium-text mb-16">Personality</p>
      <p>
        {personality && personality.length
          ? personality.map((p, index) => (
              <span className="mr-8 student-profile-tag" key={index}>
                {p.name}
              </span>
            ))
          : "—"}
      </p>
    </div> */}
    {/* <div className="student-background-divider" /> */}
    <div>
      <p className="fw-900 medium-text mb-16">Notes</p>
      <p>{notes || "—"}</p>
    </div>
  </div>
);
