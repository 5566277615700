import React from "react";
import { Button, DialogActions } from "@material-ui/core";
import { useMobile } from "../../../themes";

interface WizardFooterProps {
  back?: () => void;
  buttonsDisabled?: boolean;
  customNextButton?: any;
  next?: () => void;
  nextDisabled?: boolean;
  nextLoading?: boolean;
  onCancel: () => void;
}

export const WizardFooter = React.memo(function WizardFooter(
  props: WizardFooterProps,
) {
  const {
    back,
    customNextButton,
    buttonsDisabled,
    next,
    nextDisabled,
    nextLoading,
    onCancel,
  } = props;
  const isMobile = useMobile();
  return (
    <DialogActions
      className="mt-16 mb-16"
      style={{ justifyContent: "space-between", padding: 0 }}
    >
      <div>
        {!isMobile && (
          <Button
            onClick={onCancel}
            variant="contained"
            disabled={buttonsDisabled}
          >
            Cancel
          </Button>
        )}
      </div>
      <div>
        {back && (
          <Button
            className="mr-16"
            onClick={back}
            disabled={buttonsDisabled}
            variant="contained"
          >
            Back
          </Button>
        )}
        {customNextButton ||
          (next && (
            <Button
              disabled={nextDisabled || buttonsDisabled}
              onClick={next}
              variant="contained"
              color="primary"
            >
              {nextLoading ? "Loading..." : "Next"}
            </Button>
          ))}
      </div>
    </DialogActions>
  );
});
