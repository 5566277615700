import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  InteractionGoalCustomBarLabel,
  getInteractionDataKey,
  GoalCustomTooltip,
  InteractionCustomBarLabel,
  CustomTooltip,
} from "./metricHelpers";
import { InteractionSettingTypes } from "../shared";

function InteractionsBarChart(props) {
  const {
    chartHeight,
    data,
    getColors,
    isGoalData,
    metrics,
    tickYFormatter,
    tickYDataKey,
    tickXFormatter,
    interactionSettingFilter,
    groupByMonth = false,
    xDomain = [0, 100],
  } = props;

  const [bandOffset, setBandOffset] = useState(0);
  const [bandSize, setBandSize] = useState(0);
  const [tooltipId, setTooltipId] = useState("");

  const setChartRef = (node) => {
    const { bandSize = 0, y: bandOffset = 0 } = node?.state.yAxisMap[0] || {};
    setBandOffset(bandOffset);
    setBandSize(bandSize);
  };

  const renderBars = useCallback(
    (id, colors, data, interactionSettingFilter, isAnimationActive) => {
      const barProps = {
        barSize: 12,
        fill: colors.light,
        radius: [0, 10, 10, 0],
        onMouseOver: () => setTooltipId(id),
        onMouseOut: () => setTooltipId(""),
      };
      const renderBar = (dataKey, interactionSetting) => {
        return (
          <Bar
            {...barProps}
            dataKey={dataKey}
            isAnimationActive={isAnimationActive}
            key={dataKey}
            label={
              isGoalData ? (
                <InteractionGoalCustomBarLabel
                  colors={colors}
                  data={data}
                  dataKey={dataKey}
                  id={id}
                />
              ) : (
                <InteractionCustomBarLabel
                  colors={colors}
                  data={data}
                  dataKey={dataKey}
                  interactionSetting={interactionSetting}
                  id={id}
                  showInteractionSetting={!interactionSettingFilter}
                />
              )
            }
          >
            {data.map((_, index) => (
              <Cell key={index} fill={colors.light} />
            ))}
          </Bar>
        );
      };

      if (isGoalData) {
        const dataKey = getInteractionDataKey(id);
        return renderBar(dataKey);
      } else {
        return Object.keys(InteractionSettingTypes).map((interactionType) => {
          if (
            !interactionSettingFilter ||
            interactionSettingFilter === interactionType
          ) {
            const dataKey = getInteractionDataKey(id, interactionType);
            return renderBar(dataKey, interactionType);
          }
          return null;
        });
      }
    },
    [isGoalData],
  );

  // re-rendering during animation causes the labels not to be rendered entirely, we therefore disable animation if dataRenders is more than 1
  // see issue https://github.com/recharts/recharts/issues/829#issuecomment-1710270921
  const [dataRenders, setDataRenders] = useState(0);
  useEffect(() => {
    setDataRenders((t) => t + 1);
  }, [data]);

  return (
    <ResponsiveContainer height={chartHeight} width="94%">
      <BarChart barGap={1} data={data} layout="vertical" ref={setChartRef}>
        {metrics?.map((metric) => {
          const value = groupByMonth ? metric.id : metric;
          const colors = getColors(value);
          const id =
            !isGoalData && !groupByMonth
              ? new Date(value).toISOString()
              : value;
          return renderBars(
            id,
            colors,
            data,
            interactionSettingFilter,
            dataRenders <= 1,
          );
        })}

        <Tooltip
          content={
            isGoalData ? (
              <GoalCustomTooltip
                interactionSettingFilter={interactionSettingFilter}
                tooltipPeriodId={tooltipId}
              />
            ) : (
              <CustomTooltip
                interactionSettingFilter={interactionSettingFilter}
                tooltipId={tooltipId}
              />
            )
          }
          cursor={false}
        />

        <YAxis
          dataKey={tickYDataKey}
          tickFormatter={tickYFormatter}
          tickLine={false}
          tickMargin={10}
          type="category"
        />

        <XAxis
          domain={xDomain}
          tickFormatter={tickXFormatter}
          tickLine={false}
          type="number"
        />

        {bandSize && (
          <CartesianGrid
            vertical={false}
            horizontalPoints={Array(data.length)
              .fill()
              .map((_, i) => i * bandSize + bandOffset)}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default memo(InteractionsBarChart);
