import React, { forwardRef } from "react";
import { InputLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Option } from "../../../lib/types";
import { TextInput, TextInputProps } from "./TextInput";

type AutocompleteInputOption = {
  disabled?: boolean;
} & Option;

export type AutocompleteInputProps = {
  autoFocus?: boolean;
  defaultValue?: any; // default value set when selection is cleared - for non-multiple use
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  dividers?: boolean;
  errorMessage?: string;
  fieldProps?: any;
  filterSelectedOptions?: boolean;
  getSelection?: (options: any, value: any) => AutocompleteInputOption[];
  hidePopupIcon?: boolean;
  name: string;
  multiple?: boolean;
  multipleShortenedDisplay?: boolean;
  noOptionsText?: string | React.ReactNode;
  onChange: (name: string, val: any) => void;
  openOnFocus?: boolean;
  options: AutocompleteInputOption[];
  placeholder?: string;
  showLoader?: boolean;
  tagsMaxWidth?: string;
  truncateSelection?: boolean;
  value: any;
  valueIsObject?: boolean;
} & Omit<TextInputProps, "onChange" | "value">;

export const AutocompleteInput = React.memo(
  forwardRef(
    /**
     *
     */
    function AutocompleteInput(
      {
        autoFocus,
        defaultValue = null,
        disabled,
        disableClearable,
        disableCloseOnSelect,
        dividers,
        error,
        errorMessage,
        fieldProps,
        filterSelectedOptions,
        fullWidth,
        hidePopupIcon,
        getSelection,
        label,
        multiple,
        name,
        noOptionsText,
        onChange,
        options,
        openOnFocus,
        placeholder = "Select",
        value,
        valueIsObject,
        showLoader = false,
        tagsMaxWidth,
        truncateSelection,
        ...passProps
      }: AutocompleteInputProps,
      ref: React.Ref<any> | null,
    ) {
      const handleChange = (val: any) => {
        let changeValue;

        if (multiple) {
          const all = val.find((v: any) => v.id === "all");
          const none = val.find((v: any) => v.id === "none");
          changeValue = all
            ? options.filter((o) => !o.disabled).map((o) => o.id)
            : none
            ? ""
            : val.map((v: any) => (valueIsObject ? v : v.id));
        } else {
          changeValue = (valueIsObject ? val : val?.id) || defaultValue;
        }

        onChange(name, changeValue);
      };

      const selection = getSelection
        ? getSelection(options, value)
        : multiple
        ? options.filter((o) => value?.includes(o.id))
        : options.find((o) => value === o.id) || null;

      return (
        <>
          {label && (
            <InputLabel error={error} htmlFor={name}>
              {label}
            </InputLabel>
          )}
          <Autocomplete
            id={name}
            key={name}
            disabled={disabled}
            disableClearable={disableClearable}
            disableCloseOnSelect={multiple || disableCloseOnSelect}
            filterSelectedOptions={filterSelectedOptions}
            getOptionLabel={(option: AutocompleteInputOption) => option.name}
            getOptionDisabled={(option: AutocompleteInputOption) =>
              !!option.disabled
            }
            multiple={multiple}
            noOptionsText={noOptionsText}
            onChange={(_, val) => handleChange(val)}
            {...(hidePopupIcon && { popupIcon: "" })}
            openOnFocus={openOnFocus}
            options={options}
            renderInput={(params) => (
              <TextInput
                autoFocus={autoFocus}
                {...params}
                {...fieldProps}
                {...passProps}
                {...(error
                  ? {
                      error: true,
                      helperText: errorMessage,
                    }
                  : {})}
                placeholder={placeholder}
              />
            )}
            loading={showLoader}
            value={selection}
          />
        </>
      );
    },
  ),
);
