import React, { Fragment, useState } from "react";
import { useFormState } from "react-hook-form";
import { Button } from "@material-ui/core";
import { FormSubmissionWrapper } from "../../components/form/react-hook-form";
import {
  ErrorMessageStyled,
  ProfileFormSaveStyled,
} from "./ProfileForm.styles";
import { requiredMessage } from "./ProfileFormHelpers";
import { pickValues, pluralizeText } from "../../lib";
import { useMobile } from "../../themes";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  FiberManualRecord,
} from "@material-ui/icons";
export const ProfileFormSave = React.memo(
  /**
   *
   */
  function ProfileFormSave(props: { submitErrorMessage: string }) {
    const { submitErrorMessage } = props;
    //i don't know why but isDirty is not changing unless we get dirty fields here also
    const { errors, isSubmitting, dirtyFields, isDirty } = useFormState();

    const [requiredFieldsListOpen, setRequiredFieldsListOpen] = useState(false);
    const [errorListOpen, setErrorListOpen] = useState(false);

    const errorMessages = pickValues(errors, "message");

    const validationMessages = errorMessages.filter(
      (m) => !m.includes(requiredMessage), // TODO: error mapping to get complete desired name
    );
    const requiredFields = errorMessages
      .filter((m) => m.includes(requiredMessage))
      .map((m) => m.replace(` ${requiredMessage}`, ""));

    const saveButtonEnabled = isDirty && !isSubmitting;

    const isMobile = useMobile();

    return (
      <ProfileFormSaveStyled>
        <FormSubmissionWrapper>
          {(onSubmit) => (
            <Button
              color="primary"
              disabled={!saveButtonEnabled}
              onClick={onSubmit}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          )}
        </FormSubmissionWrapper>
        {submitErrorMessage && (
          <ErrorMessageStyled>{submitErrorMessage} </ErrorMessageStyled>
        )}

        {!!errorMessages.length && (
          <ErrorMessageStyled>
            {!!requiredFields.length && (
              <Fragment>
                {isMobile ? (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() =>
                      setRequiredFieldsListOpen(!requiredFieldsListOpen)
                    }
                  >
                    {requiredFields.length} required{" "}
                    {pluralizeText("field", requiredFields.length)} need to be
                    completed before saving:
                    {requiredFieldsListOpen ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </div>
                ) : (
                  <>
                    The following required fields need to be completed before
                    saving: {"\n"}
                  </>
                )}
                {(!isMobile || requiredFieldsListOpen) && (
                  <>
                    {requiredFields.map((f, i) => (
                      <ErrorListItem item={f} key={i} />
                    ))}
                    {validationMessages.length ? "\n" : ""}
                  </>
                )}
              </Fragment>
            )}
            {!!validationMessages.length && (
              <Fragment>
                {isMobile ? (
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => setErrorListOpen(!errorListOpen)}
                  >
                    Please address {validationMessages.length}{" "}
                    {pluralizeText("error", validationMessages.length)} before
                    saving:
                    {errorListOpen ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </div>
                ) : (
                  <>Please address the following before saving: {"\n"}</>
                )}
                {(!isMobile || errorListOpen) && (
                  <>
                    {validationMessages.map((m, i) => (
                      <ErrorListItem item={m} key={i} />
                    ))}
                  </>
                )}
              </Fragment>
            )}
          </ErrorMessageStyled>
        )}
      </ProfileFormSaveStyled>
    );
  },
);

interface ErrorItemProps {
  item: string;
}

function ErrorListItem({ item }: ErrorItemProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <FiberManualRecord
        style={{
          fontSize: "8px",
          marginRight: "8px",
          marginBottom: "2px",
        }}
      />
      <Fragment>{item}</Fragment>
    </div>
  );
}
