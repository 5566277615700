import React, { memo, useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loader from "../../../../components/Loader";
import Radio from "../../../../components/form/deprecated-inputs/RadioDeprecated";
import moment from "moment";
import JewishInterestLevelChip from "../../JewishInterestLevelChip";
import StudentTrajectoryModal from "./StudentTrajectoryModal";
import {
  getJewishInterestLevelColors,
  InteractionSettingTypes,
} from "../../engagement/shared";
import { getJewishInterestsOverTime } from "../../../../state/students/requests";
import JewishInterestLevelsLegend from "../../JewishInterestLevelsLegend";
import { SystemSelectors } from "../../../../state";

const CategoryRatingTick = ({ payload: { value }, x, y }) => (
  <g transform={`translate(${x - value * 12},${y - 10})`}>
    <image
      xlinkHref={`/images/flames-${value}.svg`}
      x={0}
      y={0}
      height="12px"
      width={value * 12}
      textAnchor="middle"
    />
  </g>
);

const InteractionTooltip = ({ payload }) => {
  if (payload && payload.length) {
    const interaction = payload[0].payload;
    if (interaction) {
      return (
        <div
          style={{
            backgroundColor: "#666666",
            color: "#fff",
            fontSize: "11px",
            borderRadius: "4px",
            boxShadow: "0 0 16px -4px rgba(0,0,0,0.5)",
            padding: "4px 8px",
          }}
        >
          {[interaction.name, interaction.type].filter((v) => v).join(" - ")}{" "}
          {interaction.dateTimeDisplay}
        </div>
      );
    }
  }

  return "";
};

const individualSettingColor = "#EC5150";
const communalSettingColor = "#2774AE";

const settings = [
  {
    value: InteractionSettingTypes.Individual,
    display: InteractionSettingTypes.Individual,
    color: individualSettingColor,
  },
  {
    value: InteractionSettingTypes.Communal,
    display: InteractionSettingTypes.Communal,
    color: communalSettingColor,
  },
  { value: "", display: "Both" },
];

const settingOptions = settings.map((s) => ({
  display: (
    <p style={s.color ? { borderBottom: `2px solid ${s.color}` } : {}}>
      {s.display}
    </p>
  ),
  value: s.value,
}));

const timeFrameValues = {
  last6Months: "last6Months",
  lastYear: "lastYear",
  last2Years: "last2Years",
};

const timeFrames = [
  { value: timeFrameValues.last6Months, display: "Past 6 months" },
  { value: timeFrameValues.lastYear, display: "Past year" },
  { value: timeFrameValues.last2Years, display: "Past 2 years" },
];

const timeFrameOptions = timeFrames.map((tf) => (
  <option key={tf.value} value={tf.value}>
    {tf.display}
  </option>
));

function StudentInteractionsOverTimeChart(props) {
  const {
    data,
    errorMessage,
    getStudentInteractions,
    jewishInterestLevel,
    loading,
    mobileView,
    studentId,
  } = props;

  const [setting, setSetting] = useState("");
  const [timeFrame, setTimeFrame] = useState(timeFrameValues.last6Months);
  const [jewishInterestsOverTime, setJewishInterestsOverTime] = useState([]);
  const [onMonths, setOnMonths] = useState([]);
  const [showTrajectoryModal, setShowTrajectoryModal] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await getJewishInterestsOverTime(studentId);
      setJewishInterestsOverTime(
        response.error ? [] : response.data.jewishInterestsOverTime,
      );
      setOnMonths(response.error ? [] : response.data.campusOnMonths);
    })();
  }, [studentId]);

  const dateIntervals = useMemo(() => {
    const endDate = moment();
    let startDate;

    let dateFormat = "M/YY";
    switch (timeFrame) {
      case timeFrameValues.last6Months:
        startDate = moment().add(-6, "month");
        dateFormat = mobileView ? "M/YY" : "MMM YYYY";
        break;
      case timeFrameValues.lastYear:
        startDate = moment().add(-1, "year");
        break;
      case timeFrameValues.last2Years:
        startDate = moment().add(-2, "year");
        break;
      default:
        return [];
    }

    // intervals have to start on the first of the month
    startDate.date(1);

    const dateValues = [];
    while (
      endDate > startDate ||
      startDate.format("M") === endDate.format("M")
    ) {
      dateValues.push({
        date: startDate.toDate(),
        display: startDate.format(dateFormat),
        value: startDate.toDate().getTime(),
      });
      startDate.add(1, "month");
    }

    return dateValues;
  }, [mobileView, timeFrame]);

  const verticalFills = useMemo(
    () =>
      dateIntervals.map((i) => {
        // we're relying on jewishInterestsOverTime being sorted desc
        const level = jewishInterestsOverTime.find((j) => {
          return new Date(j.valueAsOfDate) <= i.date;
        })?.value;
        // onMonths comes from the BE, where month values are 1-based, while moment.js months are 0-based
        const isOnMonth = onMonths.includes(i.date.getMonth() + 1);
        const color = getJewishInterestLevelColors(level).dark;
        const opacity = isOnMonth
          ? "99" // 50%
          : "33"; // 20%
        return color + opacity;
      }),
    [dateIntervals, jewishInterestsOverTime, onMonths],
  );

  const orderedData = useMemo(
    () =>
      data
        .sort((d1, d2) => new Date(d1.day) - new Date(d2.day))
        .map((d) => ({
          ...d,
          dateTimeKey: new Date(d.dateTime).getTime(),
          dateTimeDisplay: moment(d.dateTime).format("MMM D, YYYY"),
        })),
    [data],
  );

  const individualData = orderedData.filter(
    (d) => d.setting === InteractionSettingTypes.Individual,
  );
  const communalData = orderedData.filter(
    (d) => d.setting === InteractionSettingTypes.Communal,
  );

  return (
    <>
      <div>
        <div className="flex flex-justify-space flex-align-center mb-24">
          <p className="large-text fw-900">Engagement</p>
          <select
            className="no-border-select accent-text-secondary"
            onChange={(e) => {
              const newTimeFrame = e.target.value;
              setTimeFrame(newTimeFrame);
              getStudentInteractions(newTimeFrame, setting);
            }}
            value={timeFrame}
          >
            {timeFrameOptions}
          </select>
        </div>
        <div className="graph-outline">
          <div className="flex flex-justify-space mb-40">
            <div className="flex flex-align-center">
              <p className="archivo-extra-bold large-text mr-16">
                Current Interest Level
              </p>
              <JewishInterestLevelChip
                jewishInterestLevel={jewishInterestLevel}
                narrow
              />
            </div>
            {!!jewishInterestsOverTime.length && (
              <p
                className="uppercase-text medium-text link-text"
                onClick={() => setShowTrajectoryModal(true)}
              >
                View Trajectory
              </p>
            )}
          </div>
          <div className="flex flex-align-center mobile-block mb-16">
            <p className="fw-700 medium-text mr-24">Interactions</p>
            <Radio
              className="engagement-interactions-checkbox-container mobile-mt-16"
              name="setting"
              onChange={(_, newSetting) => {
                setSetting(newSetting);
                getStudentInteractions(timeFrame, newSetting);
              }}
              options={settingOptions}
              value={setting}
            />
          </div>
          <div className="flex flex-align-center flex-justify-center student-interactions-chart">
            {loading ? (
              <Loader />
            ) : errorMessage ? (
              <p className="error-text small-text text-center mb-16 mt-16">
                {errorMessage}
              </p>
            ) : !data.length ? (
              <div className="chart-no-results chart-no-results-small">
                <img
                  src="/images/no_results.svg"
                  alt="no results"
                  height="120"
                />
                <p className="accent-text mt-8 mb-16">
                  No{" "}
                  {setting
                    ? `${settings.find((s) => s.value === setting).display} `
                    : ""}
                  Interactions found for this student over this timeframe
                </p>
              </div>
            ) : (
              <div
                className="full-width"
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  padding: "10px 0 0 42px",
                }}
              >
                <ResponsiveContainer
                  height={200}
                  width={mobileView ? 600 : "100%"}
                >
                  <ScatterChart>
                    <CartesianGrid verticalFill={verticalFills} />
                    <Tooltip content={<InteractionTooltip />} />

                    <Scatter
                      data={individualData}
                      fill={individualSettingColor}
                    />

                    <Scatter data={communalData} fill={communalSettingColor} />

                    <XAxis
                      //removing All timeframe option for now
                      // {...(timeFrame === "all"
                      //   ? {
                      //       domain: ["dataMin", "dataMax"],
                      //       tickFormatter: (key) =>
                      //         orderedData.find((d) => d.dateTimeKey === key)
                      //           .dateTimeDisplay,
                      //     }
                      //   : {
                      //       domain: [
                      //         dateIntervals[0],
                      //         dateIntervals[dateIntervals.length - 1],
                      //       ],
                      //       interval: 0,
                      //       ticks: dateIntervals.map((d) => d.value),
                      //       tickFormatter: (key) =>
                      //         dateIntervals.find((d) => d.value === key).display,
                      //     })}
                      angle={timeFrame === timeFrameValues.last2Years ? -45 : 0}
                      dataKey="dateTimeKey"
                      domain={[
                        dateIntervals[0],
                        dateIntervals[dateIntervals.length - 1],
                      ]}
                      interval={0}
                      padding={{ right: 20 }}
                      tickFormatter={(key) =>
                        dateIntervals.find((d) => d.value === key).display
                      }
                      tickLine={false}
                      ticks={dateIntervals.map((d) => d.value)}
                      type="number"
                      scale="time"
                    />

                    <YAxis
                      dataKey="categoryRating"
                      domain={["dataMin", "dataMax"]}
                      interval={0}
                      tick={<CategoryRatingTick />}
                      tickLine={false}
                      tickMargin={5}
                      ticks={Array(8)
                        .fill()
                        .map((_, i) => i + 1)}
                      type="number"
                    />
                  </ScatterChart>
                </ResponsiveContainer>
                <div className="flex flex-align-center mobile-block">
                  <JewishInterestLevelsLegend
                    levelsSelector={SystemSelectors.jewishInterestLevels}
                    mobileDisplay="mobile-flex"
                  />
                  <p className="accent-text italic-text mb-16">
                    Lighter colors indicate "off" months
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showTrajectoryModal && (
        <StudentTrajectoryModal
          jewishInterestsOverTime={jewishInterestsOverTime}
          onClose={() => setShowTrajectoryModal(false)}
        />
      )}
    </>
  );
}

export default memo(StudentInteractionsOverTimeChart);
