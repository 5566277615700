import React, { useEffect, useState } from "react";
import FilePreviewModal from "../../../components/FilePreviewModal";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import StudentConfirmationModal from "./StudentConfirmationModal";
import { formatCurrency, getCcIcon, pluralizeText } from "../../../lib";

export function getTagColor(status) {
  switch (status) {
    case "Pending":
    case "Waitlisted":
      return "#f3893d";
    case "Rejected":
      return "#ec5150";
    case "Cancelled":
      return "#ec5150";
    case "Accepted":
      return "#63c674";
    case "CheckedIn":
      return "#6369d1";
    default:
      return "#747579";
  }
}

function StudentDetailsModal(props) {
  const {
    adminAcceptancePolicyOption,
    show,
    id,
    isTravelTrip,
    programScheduleName,
    registration: {
      loading,
      credCardInfo,
      dateCheckedIn,
      dateRegistered,
      depositAmount,
      promoCodesApplied,
      recommendation,
      rewardRequest,
      status,
      student: {
        ageAtTrip,
        campus,
        cell,
        email,
        isJewish,
        person: { dob, firstName, lastName } = {},
        vaccinationProofUrls,
      } = {},
      success,
      total,
      tours,
      trackName,
      trackPrice,
      wasEarlyBird,
      wasDepositRefunded,
      wasPaymentProcessed,
      wasTripFeeRefunded,
      workflowStep,
    } = {},
    rewardCreditValue,
    getTripRegistrationDetails,
    toggleStudentDetailsModal,
    toggleStudentRecommendationModal,
    onUpdateRegistrationStatus,
  } = props;

  const [showFilePreviewModal, setShowFilePreviewModal] = useState(false);
  const [selectedVaccinationProof, setSelectedVaccinationProof] = useState("");
  const [selectedStatusUpdate, setSelectedStatusUpdate] = useState("");
  const [showStatusUpdateConfirmation, setShowStatusUpdateConfirmation] =
    useState(false);
  const [detailsLoading, setDetailsLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getTripRegistrationDetails(id);
    }
  }, [id, getTripRegistrationDetails]);

  useEffect(() => {
    if (success !== undefined) setDetailsLoading(false);
  }, [success]);

  return (
    <Modal show={show}>
      <div className="student-details-modal-container modal-container full-page-mobile-modal-container">
        <div className="modal modal-card card student-details-modal relative">
          {loading || detailsLoading ? (
            <div className="flex flex-justify-center full-height">
              <Loader />
            </div>
          ) : !success ? (
            <div className="flex flex-justify-center flex-align-center flex-column full-height">
              <div className="modal-error-icon">
                <i className="material-icons">close</i>
              </div>
              <p className="mt-24 mb-24 fw-500 medium-text">
                There was an error loading the student data.
              </p>
              <button
                className="btn btn-accent"
                style={{ padding: "0 24px" }}
                onClick={() => toggleStudentDetailsModal()}
              >
                View Student List
              </button>
            </div>
          ) : (
            <React.Fragment>
              <div className="desktop-hidden tablet-hidden modal-mobile-header">
                <i
                  className="material-icons pointer link-text-secondary"
                  onClick={() => toggleStudentDetailsModal()}
                >
                  close
                </i>
              </div>
              <div className="flex flex-align-center flex-justify-space mb-24 student-details-header">
                <div className="flex flex-align-center mr-16 mobile-mb-16">
                  <p className="xxl-text fw-700">
                    {firstName} {lastName}
                  </p>
                  <div className="flex flex-align-top">
                    <div
                      className="tag small student-status-tag"
                      style={{ background: getTagColor(status), color: "#fff" }}
                    >
                      {status}
                    </div>
                    {isTravelTrip &&
                      (recommendation ? (
                        <p className="student-recommendation-tag submitted">
                          <i className="material-icons medium-text mr-4">
                            check_circle
                          </i>
                          Recommendation submitted
                        </p>
                      ) : (
                        workflowStep === "Applied" && (
                          <p className="student-recommendation-tag">
                            <i className="material-icons medium-text mr-4">
                              error
                            </i>
                            Recommendation needed
                          </p>
                        )
                      ))}
                  </div>
                </div>
                <div className="flex flex-align-center">
                  {isTravelTrip &&
                    !recommendation &&
                    workflowStep === "Applied" && (
                      <p
                        className="flex flex-align-center link-text uppercase-text mr-24"
                        onClick={() =>
                          toggleStudentRecommendationModal({
                            id,
                            studentFirstName: firstName,
                            studentLastName: lastName,
                          })
                        }
                      >
                        Complete recommendation
                        <i className="material-icons large-text ml-4">
                          open_in_new
                        </i>
                      </p>
                    )}
                  <i
                    className="material-icons link-text-secondary accent-text-dark"
                    onClick={() => toggleStudentDetailsModal()}
                  >
                    close
                  </i>
                </div>
              </div>
              <div className="student-info student-details-modal-section">
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Email</label>
                  <p>
                    <a
                      href={`mailto:${email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-text"
                    >
                      {email}
                    </a>
                  </p>
                </div>
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Phone</label>
                  <p>
                    <a href={`tel:${cell}`} className="link-text">
                      {cell}
                    </a>
                  </p>
                </div>
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Age at Trip / Birthday</label>
                  <p>
                    {ageAtTrip} /{" "}
                    {new Date(dob).toLocaleString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                      timeZone: "UTC",
                    })}
                  </p>
                </div>
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Student Identifies as Jewish</label>
                  <p>{isJewish ? "Yes" : isJewish === false ? "No" : ""}</p>
                </div>
                <div className="flex flex-align-center">
                  <label className="fw-700">Campus</label>
                  <p>{campus && campus.name}</p>
                </div>
              </div>
              <div className="student-info student-details-modal-section">
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Track / Price</label>
                  <p>
                    {trackName} / {wasEarlyBird && "(Early Bird)"}{" "}
                    {rewardCreditValue
                      ? `${trackPrice / rewardCreditValue} ${pluralizeText(
                          "Credit",
                          trackPrice / rewardCreditValue,
                        )} ($${formatCurrency(trackPrice)})`
                      : `$${formatCurrency(trackPrice)}`}
                  </p>
                </div>
                {depositAmount > 0 && (
                  <div className="flex flex-align-center mb-12">
                    <label className="fw-700">Deposit amount</label>
                    <p>${formatCurrency(depositAmount)}</p>
                  </div>
                )}
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Applied Promo Codes</label>
                  <p>
                    {promoCodesApplied?.map(({ code }) => code).join(", ") ||
                      "–"}
                  </p>
                </div>
                {rewardRequest && (
                  <div className="flex flex-align-center mb-12">
                    <label className="fw-700">Applied Credits</label>
                    <p>
                      {rewardRequest.creditsToRedeem}{" "}
                      {pluralizeText("credit", rewardRequest.creditsToRedeem)}{" "}
                      (${formatCurrency(rewardRequest.amount)})
                      <span
                        className="small-text ml-8"
                        style={{
                          color:
                            rewardRequest.status === "Rejected"
                              ? "#DC313F"
                              : rewardRequest.status === "Approved"
                              ? "#76E269"
                              : "#F3893D",
                        }}
                      >
                        {rewardRequest.status}
                      </span>
                    </p>
                  </div>
                )}
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Applied</label>
                  {dateRegistered && (
                    <p>
                      {new Date(dateRegistered).toLocaleString("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "UTC",
                      })}
                    </p>
                  )}
                </div>
                {!isTravelTrip && (
                  <div className="flex flex-align-center mb-12">
                    <label className="fw-700">Checked in</label>
                    {dateCheckedIn && (
                      <p>
                        {new Date(dateCheckedIn).toLocaleString("en-US", {
                          weekday: "short",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </p>
                    )}
                  </div>
                )}
                <div className="flex flex-align-center mb-12">
                  <label className="fw-700">Payment amount</label>
                  <p>
                    ${formatCurrency(total)}{" "}
                    <span
                      className="accent-text-secondary-color small-text ml-8"
                      style={{ color: total > 0 ? "#F3893D" : "#EC5150" }}
                    >
                      {total > 0
                        ? status === "Pending"
                          ? "Payment Pending"
                          : !wasPaymentProcessed
                          ? "Payment Not Processed"
                          : ""
                        : wasTripFeeRefunded || wasDepositRefunded
                        ? "Payment Refunded"
                        : ""}
                    </span>
                  </p>
                </div>
                <div className="flex flex-align-center">
                  <label className="fw-700">Payment method</label>
                  {credCardInfo && (
                    <p className="flex flex-align-center line-height-double">
                      {getCcIcon(credCardInfo.cardBrand)}{" "}
                      {credCardInfo.cardBrand} ending in {credCardInfo.lastFour}
                    </p>
                  )}
                </div>
              </div>
              {tours && tours.length > 0 && (
                <div className="student-details-modal-section">
                  {tours &&
                    tours.map((tour) => {
                      return (
                        <div className="student-tour" key={tour.id}>
                          <p className="large-text fw-700 mb-12">
                            {tour.name} - ${formatCurrency(tour.price)}
                          </p>
                          <p>
                            {tour.schedules[0].name}{" "}
                            {tour.schedules[0].name && "|"}{" "}
                            {tour.schedules[0].dateFormat}{" "}
                            {new Date(tour.schedules[0].time).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              },
                            )}{" "}
                            -{" "}
                            {new Date(
                              tour.schedules[0].calculatedEndTime,
                            ).toLocaleString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}
              {vaccinationProofUrls && vaccinationProofUrls.length > 0 && (
                <div className="student-details-modal-section">
                  {vaccinationProofUrls.map((u, i) => {
                    return (
                      <p
                        className="flex flex-align-center accent-text mt-16 link-text"
                        key={i}
                        onClick={() => {
                          setShowFilePreviewModal(true);
                          setSelectedVaccinationProof(u);
                        }}
                      >
                        <i className="material-icons mr-8">description</i>
                        {`Vaccination proof #${i + 1}`}
                      </p>
                    );
                  })}
                  <FilePreviewModal
                    close={() => setShowFilePreviewModal(false)}
                    isSecure={true}
                    show={showFilePreviewModal}
                    title={"Proof Of Vaccination"}
                    type={"application/pdf"}
                    url={selectedVaccinationProof}
                  />
                </div>
              )}
              {!isTravelTrip &&
                adminAcceptancePolicyOption !== "NoAcceptance" &&
                status === "Pending" && (
                  <div className="student-modal-btns flex flex-justify-end">
                    <button
                      className="btn btn-reject flex flex-align-center"
                      onClick={() => {
                        setSelectedStatusUpdate("Rejected");
                        setShowStatusUpdateConfirmation(true);
                      }}
                    >
                      <i className="material-icons medium-text mr-8">
                        thumb_down
                      </i>{" "}
                      Reject
                    </button>
                    <button
                      className="btn btn-accept flex flex-align-center ml-24"
                      onClick={() => {
                        setSelectedStatusUpdate("Accepted");
                        setShowStatusUpdateConfirmation(true);
                      }}
                    >
                      {" "}
                      <i className="material-icons medium-text mr-8">
                        thumb_up
                      </i>{" "}
                      Accept
                    </button>
                  </div>
                )}
            </React.Fragment>
          )}
        </div>
      </div>
      {showStatusUpdateConfirmation && (
        <StudentConfirmationModal
          show={showStatusUpdateConfirmation}
          toggleModal={() =>
            setShowStatusUpdateConfirmation(!showStatusUpdateConfirmation)
          }
          onUpdate={onUpdateRegistrationStatus}
          programScheduleName={programScheduleName}
          studentData={{
            id,
            status: selectedStatusUpdate,
            studentFirstName: firstName,
            campusName: campus.name,
          }}
        />
      )}
    </Modal>
  );
}
export default React.memo(StudentDetailsModal);
