import React from "react";
import _isEqual from "lodash.isequal";
import _debounce from "lodash.debounce";
import { Typography } from "@material-ui/core";
import { Navigation } from "../../../lib";
import OrdersTable from "./OrdersTable";
import OrderActions from "./OrderActions";
import { composeFilterParams } from "../raffleUtils";

export default class Orders extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentOrder: {},
      filterParams: {
        pageRoute: props.pageRoute,
        raffle: props.raffle,
      },
      searchFocused: false,
      searchInputValue: props.pageRoute.query.q || "",
      showOrderDetailsModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      pageRoute: { query },
    } = this.props;
    if (!_isEqual(prevProps.pageRoute.query, query)) {
      this.getOrders();
    }
  }

  onSearch = (debounce = false) => {
    setTimeout(debounce ? _debounce(this.search, 1000) : this.search, 0);
  };

  search = () => {
    const { tab, p = 1 } = this.props.pageRoute.query;
    Navigation.redirect(
      `${this.props.pageRoute.location.pathname}?tab=${tab}&p=${p}&q=${this.state.searchInputValue}&r=10`,
    );
  };

  getOrders = (page, resultsView, searchTerm, enrollmentID, eventID) => {
    //page & resultsView sent with loadData from paginatedTable must override pageRoute query params, because data is fetched prior to params being refreshed
    const filterParams = {
      enrollmentID,
      eventID,
      page,
      pageRoute: this.props.pageRoute,
      raffle: this.props.raffle,
      resultsView,
      searchTerm,
    };
    this.setState({
      filterParams,
    });
    const filterArray = composeFilterParams(filterParams);
    this.props.getRaffleOrders(...filterArray);
  };

  refreshOrders = () => {
    const { filterParams } = this.state;

    const filterArray = composeFilterParams(filterParams);
    const enrollmentId = filterArray[3];
    
    this.props.getRaffleOrders(...filterArray);
    this.props.refreshRaffleAmounts(enrollmentId);
  };

  render() {
    const {
      enrollmentDetails: {
        allowDonorToPayProcessingFee,
        bulkOrderTemplateURL,
        currencyCode,
        stripePublicKey,
        ticketPrice,
      },
      details: {
        cociCcProcessingFeePercentage,
        isActive,
        isOpenForManualTicketEntry,
      },
    } = this.props.raffle;
    return (
      <>
        <Typography variant="h5" className="mb-24">
          My Grand Draw Orders
        </Typography>

        <div className="flex flex-justify-space flex-align-center mb-16 mobile-block">
          <div
            className={
              this.state.searchFocused
                ? "search-input search-input-focused"
                : "search-input"
            }
          >
            <input
              type="text"
              placeholder="Search by donor name or order # ..."
              onChange={(e) => {
                this.setState({ searchInputValue: e.target.value });
                this.onSearch(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") this.onSearch();
              }}
              value={this.state.searchInputValue}
            />
            <i
              className="material-icons accent-text-secondary pointer"
              onClick={this.onSearch}
            >
              search
            </i>
          </div>
          <OrderActions
            allowDonorToPayProcessingFee={allowDonorToPayProcessingFee}
            bulkOrderTemplateURL={bulkOrderTemplateURL}
            createOrder={this.props.createOrder}
            cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
            currencyCode={currencyCode}
            enrollmentID={this.props.raffle.enrollmentDetails.id}
            getRaffleOrders={this.props.getRaffleOrders}
            getRaffleOrdersForExport={this.props.getRaffleOrdersForExport}
            getRaffleEnrollment={this.props.getRaffleEnrollment}
            importBulkOrders={this.props.importBulkOrders}
            isActive={isActive}
            isOpenForManualTicketEntry={isOpenForManualTicketEntry}
            pageRoute={this.props.pageRoute}
            raffle={this.props.raffle}
            raffleID={this.props.raffle.details.id}
            refreshOrders={this.refreshOrders}
            showNewOrderModal={this.state.showNewOrderModal}
            stripePublicKey={stripePublicKey}
            sys={this.props.sys}
            ticketPrice={ticketPrice}
          />
        </div>
        <OrdersTable
          cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
          deleteOrder={this.props.deleteOrder}
          getOrders={this.getOrders}
          pageRoute={this.props.pageRoute}
          raffle={this.props.raffle}
          refreshOrders={this.refreshOrders}
          resendOrderEmail={this.props.resendOrderEmail}
          sys={this.props.sys}
          ticketPrice={ticketPrice}
        />
      </>
    );
  }
}
