import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

import { SystemSelectors } from "../../../../state";
import {
  getVibrancyMetrics,
  getKpiScoreRanges,
} from "../../../../state/engagement/EngagementApi";

import { getDateForMonthYearSelect, palette } from "../shared";

import CustomSelectDeprecated from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";
import Loader from "../../../../components/Loader";

const now = new Date();
const currentMonth = getDateForMonthYearSelect(
  now.getFullYear(),
  now.getMonth(),
);

export default function MetricsCalculations({ monthYearsArray }) {
  const [selectedDate, setSelectedDate] = useState(currentMonth);

  const [kpiScoreRangesLoading, setKpiScoreRangesLoading] = useState(true);
  const [kpiScoreRanges, setKpiScoreRanges] = useState([]);
  const [kpiScoreRangesErrorMessage, setKpiScoreRangesErrorMessage] =
    useState(null);

  const [vibrancyMetrics, setVibrancyMetrics] = useState(null);
  const [vibrancyMetricsErrorMessage, setVibrancyMetricsErrorMessage] =
    useState("");

  const kpis = useSelector(SystemSelectors.engagementKpis);

  useEffect(function onMount() {
    (async () => {
      const { data, errorMessage } = await getKpiScoreRanges();
      setKpiScoreRanges(data);
      setKpiScoreRangesErrorMessage(errorMessage);
      setKpiScoreRangesLoading(false);
    })();
  }, []);

  useEffect(
    function loadMetricsForSelectedEngagementPeriodId() {
      (async () => {
        if (selectedDate) {
          const { data, errorMessage } = await getVibrancyMetrics([
            selectedDate,
          ]);
          setVibrancyMetrics(!errorMessage ? data[0] : null);
          setVibrancyMetricsErrorMessage(errorMessage);
        } else {
          setVibrancyMetrics(null);
          setVibrancyMetricsErrorMessage("");
        }
      })();
    },
    [selectedDate],
  );

  const getSortedScoreRangesForCategory = useCallback(
    (kpi) => {
      return kpiScoreRanges
        .filter((range) => range.category === kpi.enumValue)
        .sort((a, b) => a.rangeStart - b.rangeStart);
    },
    [kpiScoreRanges],
  );

  const getCategoryScore = useCallback(
    (kpi) => {
      if (!vibrancyMetrics || !selectedDate) {
        return null;
      }
      return vibrancyMetrics.kpis.find((epk) => epk.category === kpi.enumValue)
        .score;
    },
    [vibrancyMetrics, selectedDate],
  );

  // Note that the wording in this section was carefully chosen - confirm with the client before making changes.
  const renderHeaderText = (kpis) => (
    <>
      <div className="flex flex-justify-space mt-24 mb-16">
        <p className="xl-text fw-900 mobile-mt-24">
          How is the vibrancy meter calculated?
        </p>
      </div>

      <div className="accent-text-dark mb-32">
        <p className="mb-4">
          The vibrancy meter is calculated by adding your points from each of
          these categories:
        </p>
        <ul className="kpi-list">
          {kpis?.map(({ displayValue, value }) => (
            <li>
              <strong>{displayValue}</strong>: {value.longName}
            </li>
          ))}
        </ul>

        <p className="mt-4">
          The maximum amount of points for each category is 2, so the highest
          calculation would be 10.
        </p>
      </div>
    </>
  );

  const renderCriteriaAndScores = () => (
    <>
      <p className="medium-text fw-900 mb-16">
        Vibrancy meter criteria and your points
      </p>

      <CustomSelectDeprecated
        className="engagement-period-select"
        defaultValue={
          currentMonth && monthYearsArray.find((p) => p.value === currentMonth)
        }
        isClearable={true}
        placeholder="Select Engagement Period..."
        onChange={(_, value) => setSelectedDate(value)}
        options={monthYearsArray}
        value={
          selectedDate && monthYearsArray.find((p) => p.value === selectedDate)
        }
      />

      {vibrancyMetricsErrorMessage && <div>{vibrancyMetricsErrorMessage}</div>}

      <div className="kpi-scorecard-container">
        {kpis.map((kpi, index) => (
          <KpiScorecard
            key={index}
            index={index}
            kpi={kpi}
            scoreRanges={getSortedScoreRangesForCategory(kpi)}
            categoryScore={getCategoryScore(kpi)}
          />
        ))}
      </div>
    </>
  );

  return (
    <div>
      {renderHeaderText(kpis)}

      {kpiScoreRangesLoading ? (
        <Loader />
      ) : kpiScoreRangesErrorMessage ? (
        <div>
          Vibrancy meter criteria could not be loaded. Please try again later.{" "}
          {kpiScoreRangesErrorMessage}
        </div>
      ) : (
        renderCriteriaAndScores()
      )}
    </div>
  );
}

function KpiScorecard({ index, kpi, scoreRanges, categoryScore }) {
  const color = palette[index];

  const formatBoundary = (boundary) => {
    if (boundary === null) {
      return null;
    }
    return kpi.value === "Percentage" ? boundary + "%" : boundary;
  };

  const formatRange = (range) => {
    const rangeStart = formatBoundary(range.rangeStart);
    const rangeEnd = formatBoundary(range.rangeEnd);

    if (rangeStart === null) {
      return `${rangeEnd} or less`;
    }
    if (rangeEnd === null) {
      return `${rangeStart}+`;
    }
    return `${rangeStart}-${rangeEnd}`;
  };

  return (
    <div className="kpi-scorecard">
      <div
        className="kpi-scorecard-header"
        style={{ backgroundColor: color.dark, color: color.text }}
      >
        <p>{kpi.displayValue}</p>
        <p>Points</p>
      </div>
      {scoreRanges.map((range) => {
        const isActive = range.score === categoryScore;
        return (
          <div className={`kpi-score-range ${isActive ? "active" : ""}`}>
            <p>{formatRange(range)}</p>
            <p>{range.score}</p>
          </div>
        );
      })}
    </div>
  );
}
