import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, ButtonGroup, MenuItem, Typography } from "@material-ui/core";
import { SysActions } from "../../../../state/sys/actions";
import {
  calculateMessagePricing,
  getIsSmsCreditNeeded,
  MessagePricing,
  MessageScheduleTypes,
  scheduleSendOptions,
  WizardStepProps,
  WizardStepError,
} from "../WizardHelpers";
import { WizardFooter } from "../WizardFooter";
import ButtonMenu from "../../../../components/ButtonMenu";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { submitSms } from "../../../../lib/apiRequests/SmsApiRequests";
import { SmsPayload } from "../../../../lib/apiRequests/ApiTypes";
import MomentUtils from "@date-io/moment";
import { SmsCostEstimate } from "../SmsCostEstimate";
import SmsAddCredit from "../../SmsAddCredit.vm";
import StripeProvider from "../../../../components/StripeProvider";
import Loader from "../../../../components/Loader";

const { REACT_APP_STRIPE_API_KEY } = process.env;

export const Confirm = React.memo(function ReviewRecipients(
  props: WizardStepProps,
) {
  const {
    back,
    chabadHouseEnrollmentData: { forChabadHouseID: chabadHouseId, timezoneID },
    messageImage,
    messageText,
    next,
    onCancel,
    recipients,
    threadId,
    setMessageScheduleType,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<
    WizardStepError & { pricingError?: string }
  >({});
  const [showScheduleSendDate, setShowScheduleSendDate] = useState(false);
  const [pricingData, setPricingData] = useState<MessagePricing>({
    balance: null,
    price: null,
    segments: null,
  });
  const [addCreditOpen, setAddCreditOpen] = useState(false);

  const dispatch = useDispatch();

  const getMessagePricing = useCallback(async () => {
    setLoading(true);
    setErrors((errors) => ({ ...errors, pricingError: undefined }));
    if (chabadHouseId) {
      const { pricing, error } = await calculateMessagePricing(
        chabadHouseId,
        recipients,
        !!messageImage,
        messageText,
      );
      if (error) {
        setErrors((errors) => ({ ...errors, pricingError: error }));
      }
      pricing && setPricingData(pricing);
    }
    setLoading(false);
  }, [chabadHouseId, messageImage, messageText, recipients]);

  useEffect(() => {
    getMessagePricing();
    //only call on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onCloseAddCredit() {
    setLoading(true);
    await getMessagePricing();
    setAddCreditOpen(false);
    setLoading(false);
  }

  async function handleSubmit(timeToSend?: string) {
    setLoading(true);
    const { uploadFile } = SysActions;
    const payload: SmsPayload = {
      studentIDs: [
        ...recipients.additionalStudents.map((s) => s.studentID),
        ...recipients.filteredStudents.map((s) => s.studentID),
      ],
      text: messageText,
      chabadHouseID: chabadHouseId,
      timezoneID,
      segments: pricingData?.segments,
      threadId,
      timeToSend,
      media: [],
    };
    if (messageImage?.file) {
      const imageUrl = await dispatch(
        uploadFile(messageImage.file, "sms_img_attachment", false) as any,
      );
      payload.media = [imageUrl];
    }
    const response = await submitSms(payload);
    setLoading(false);
    if (response.error) {
      setErrors((errors) => ({ ...errors, submissionError: response.error }));
    } else {
      setMessageScheduleType(
        timeToSend
          ? MessageScheduleTypes.Scheduled
          : MessageScheduleTypes.Immediate,
      );

      next();
    }
  }

  const isCreditNeeded = getIsSmsCreditNeeded(pricingData);
  const disableButtons = loading || addCreditOpen;
  return (
    <div className="sms-wizard-page">
      <div>
        <div className="mt-24 sms-wizard-container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <SmsCostEstimate
                error={errors.pricingError}
                pricingData={pricingData}
                recipientCount={
                  recipients.additionalStudents.length +
                  recipients.filteredStudents.length
                }
                onAddCredit={() => setAddCreditOpen(true)}
                showAddCreditButon={!addCreditOpen}
              />
              {addCreditOpen && (
                <div className="sms-add-credit-section">
                  <Typography variant="h6">Add Credit</Typography>
                  <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
                    <SmsAddCredit
                      //@ts-ignore
                      buttonsOnTheLeft={true}
                      onClose={onCloseAddCredit}
                      chabadHouseID={chabadHouseId}
                    />
                  </StripeProvider>
                </div>
              )}
            </>
          )}
          <div className="error-text mt-16 flex flex-justify-end">
            {errors.submissionError}
          </div>
        </div>
      </div>

      <WizardFooter
        back={back}
        buttonsDisabled={disableButtons}
        onCancel={onCancel}
        customNextButton={
          <ButtonGroup variant="contained" color="primary">
            <Button
              disabled={disableButtons || isCreditNeeded}
              onClick={() => {
                handleSubmit();
              }}
            >
              Send Message
            </Button>
            <ButtonMenu
              disabled={disableButtons || isCreditNeeded}
              id="send-later-menu"
              buttonProps={{
                style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
                color: "primary",
              }}
              icon="arrow_drop_down"
              title="Send later"
              menuItems={[
                ...scheduleSendOptions.map((option, index) => (
                  <MenuItem
                    key={option.name}
                    onClick={() => {
                      handleSubmit(option.value.format("YYYY-MM-DDTHH:mm:ss"));
                    }}
                  >
                    {option.name}
                  </MenuItem>
                )),
                <MenuItem
                  key={"custom"}
                  style={{ color: "#2774AE" }}
                  onClick={() => setShowScheduleSendDate(true)}
                >
                  <span>Choose custom time</span>
                </MenuItem>,
              ]}
            />
          </ButtonGroup>
        }
      />
      <div style={{ display: "none" }}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DateTimePicker
            open={showScheduleSendDate}
            onChange={(d) => {
              if (d) {
                handleSubmit(d.format("YYYY-MM-DDTHH:mm:ss"));
                setShowScheduleSendDate(false);
              }
            }}
            onClose={() => {
              setShowScheduleSendDate(false);
            }}
            //since this picker is not displayed in the UI once the value is set, we don't need to set the value.
            //However, TS errors when you don't declare the value prop
            value={undefined}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
});
