import React, { useState } from "react";
import {
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  FormTextField,
  FormToggleField,
} from "../../../../components/form/react-hook-form";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
  ProfileFormInfoIcon,
} from "../../ProfileForm.styles";
import { getProfileSectionId, ProfileSectionWrapper } from "../../profileMenu";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const ChabadHouseFinancialInfo = React.memo(
  /**
   *
   */
  function ChabadHouseFinancialInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, intersectionOptions } = props;

    const [showSecretKey, setShowSecretKey] = useState(false);

    const chabadHouseFieldsAccessor = `chabadHouses.${chabadHouseIndex}`;
    const paymentProviderFieldsAccessor = `chabadHouses.${chabadHouseIndex}.paymentProviderAccounts[0]`; // one payment provider is allowed per Chabad House

    return (
      <ProfileSectionWrapper
        id={getProfileSectionId("chabadHouseFinancial", chabadHouseIndex)}
        intersectionOptions={intersectionOptions}
        view="desktop"
      >
        <Typography variant="h6">Financial info</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: "6px" }}
        >
          All financial information will not appear in the public directory.
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "16px", marginBottom: "40px" }}
        >
          <ProfileFormGridItemStyled>
            <FormTextField
              label="Tax ID/Charity number"
              name={`${chabadHouseFieldsAccessor}.taxID`}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormToggleField
              color="secondary"
              style={{ width: "150px" }}
              options={[
                {
                  id: true,
                  name: "YES",
                },
                {
                  id: false,
                  name: "NO",
                },
              ]}
              label="My Chabad House is a registered 501(c)(3) / charity "
              name={`${chabadHouseFieldsAccessor}.isRegistered501`}
            />
          </ProfileFormGridItemStyled>
        </ProfileFormGridStyled>

        {/* TODO: add ACH info to API - Phase 1.5 */}
        {/* <Typography variant="subtitle2">ACH info (optional)</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: "6px" }}
        >
          Please provide your ACH information
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "16px", marginBottom: "40px" }}
        >

        </ProfileFormGridStyled> */}

        <Typography variant="subtitle1">Stripe account (optional)</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: "6px" }}
        >
          A Stripe account is required to set an event registration fee or to
          request an event donation.
        </Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "16px", marginBottom: "42px" }}
        >
          <ProfileFormGridItemStyled>
            <FormTextField
              label={
                <>
                  Account ID{" "}
                  <Tooltip title="Log into Stripe account. Click on the gears icon to view settings (top right). Click account details. Copy your account ID from the far right.">
                    <ProfileFormInfoIcon />
                  </Tooltip>
                </>
              }
              name={`${paymentProviderFieldsAccessor}.paymentProviderAccountID`}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormTextField
              label={
                <>
                  Publishable key{" "}
                  <Tooltip title="Log into Stripe account. Click developers (top right). Click API Keys (left side menu). Find your publishable key.">
                    <ProfileFormInfoIcon />
                  </Tooltip>
                </>
              }
              name={`${paymentProviderFieldsAccessor}.publicKey`}
            />
          </ProfileFormGridItemStyled>
          <ProfileFormGridItemStyled>
            <FormTextField
              type={showSecretKey ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowSecretKey(!showSecretKey)}
                    >
                      {showSecretKey ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={
                <>
                  Secret key{" "}
                  <Tooltip title="Use your full, 24+ character-long Secret Key. If you don't know it, or already generated it and only have the short version (ex sk_live…abc), you'll need to create a new one. Log into Stripe account. Click developers (top right). Click API Keys. Click Create secret key. Copy the value before closing the confirmation modal.">
                    <ProfileFormInfoIcon />
                  </Tooltip>
                </>
              }
              name={`${paymentProviderFieldsAccessor}.secretKey`}
            />
          </ProfileFormGridItemStyled>
        </ProfileFormGridStyled>
      </ProfileSectionWrapper>
    );
  },
);
