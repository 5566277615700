import React, { memo, useEffect } from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import Radio from "../../../../components/form/deprecated-inputs/RadioDeprecated";
import { formatNumber, pluralizeText } from "../../../../lib";
import { InteractionSettingTypes } from "../shared";

const useStyles = makeStyles(() => ({
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  total: { marginBottom: 8, color: "#747579" },
}));

function InteractionMetricChartHeader(props) {
  const {
    loading,
    dataTotal,
    setInteractionSettingFilter,
    interactionSettingFilter,
    isGoalData,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if (isGoalData) {
      setInteractionSettingFilter("");
    }
  }, [isGoalData, setInteractionSettingFilter]);
  return (
    <Box>
      <Box className={classes.topContainer}>
        <Typography variant="subtitle1" className="mr-24">
          Engagements
        </Typography>

        <Typography variant="body2" className={classes.total}>
          Total:{" "}
          {!loading
            ? `${formatNumber(dataTotal)} ${pluralizeText(
                "engagement",
                dataTotal,
              )}`
            : ""}
        </Typography>
      </Box>
      {!isGoalData && (
        <Radio
          className="engagement-interactions-checkbox-container mt-16"
          name="interactionSetting"
          onChange={(_, val) => setInteractionSettingFilter(val)}
          options={[
            ...Object.keys(InteractionSettingTypes).map((s) => ({
              value: s,
              display: s,
            })),
            { value: "", display: "Both" },
          ]}
          value={interactionSettingFilter}
        />
      )}
    </Box>
  );
}

export default memo(InteractionMetricChartHeader);
