import React, { useCallback, useState } from "react";
import {
  Checkbox,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import Toggle from "../../../../../../components/form/deprecated-inputs/ToggleDeprecated";
import {
  REMINDER_MINUTE_VALUE_LIST,
  REMINDER_SETTING_TYPES,
  DEFAULT_REGISTERED_STUDENTS_REMINDER_MINUTES,
  DEFAULT_UNREGISTERED_STUDENTS_REMINDER_MINUTES,
} from "../../../../RsvpHelpers";
import ReminderTimeSelect from "../../../settings/ReminderTimeSelect";

const getMinuteValueLabel = (minutes) =>
  REMINDER_MINUTE_VALUE_LIST.find((m) => m.value === minutes)?.label;

const toggleOptions = [
  {
    value: true,
    display: "Use Default reminder settings",
  },
  {
    value: false,
    display: "Custom",
  },
];

function ReminderSettings({
  registeredStudentsReminderMinutes,
  unregisteredStudentsReminderMinutes,
  reminderSettingType,
  onChange,
  globalDefaultHasReminders,
  globalDefaultRegisteredStudentsReminderMinutes,
  globalDefaultUnregisteredStudentsReminderMinutes,
  error,
  setError,
  setReminderSettingType,
  readOnly,
  wasRegisteredStudentsReminderAlreadyTriggered,
  wasUnregisteredStudentsReminderAlreadyTriggered,
}) {
  const [useGlobalDefaults, setUseGlobalDefaults] = useState(
    reminderSettingType === REMINDER_SETTING_TYPES.global,
  );

  const setReminderMinutes = useCallback(
    async (fieldName, minutes) => {
      await onChange(fieldName, minutes);
      if (minutes && reminderSettingType !== REMINDER_SETTING_TYPES.custom) {
        await setReminderSettingType(REMINDER_SETTING_TYPES.custom);
      }
      await setError(false);
    },
    [reminderSettingType, onChange, setError, setReminderSettingType],
  );

  const onChangeToggle = useCallback(
    async (_, value) => {
      setUseGlobalDefaults(value);
      if (value) {
        await setReminderSettingType(REMINDER_SETTING_TYPES.global);
      } else {
        await setReminderSettingType(REMINDER_SETTING_TYPES.none);
      }
    },
    [setReminderSettingType],
  );

  const onChangeCheckBoxRegistered = useCallback(
    (_, value) => {
      setReminderMinutes(
        "registeredStudentsReminderMinutes",
        value ? DEFAULT_REGISTERED_STUDENTS_REMINDER_MINUTES : "",
      );
    },
    [setReminderMinutes],
  );

  const onChangeCheckBoxUnregistered = useCallback(
    (_, value) => {
      setReminderMinutes(
        "unregisteredStudentsReminderMinutes",
        value ? DEFAULT_UNREGISTERED_STUDENTS_REMINDER_MINUTES : "",
      );
    },
    [setReminderMinutes],
  );

  return (
    <div className="program-form-section mb-16 mt-16 separatorTop">
      <p className="medium-text fw-700 mb-8">Reminders</p>
      <p className="accent-text-dark line-height-double">
        When would you like to send reminders?
      </p>

      <div className="mb-16 mt-24 toggle-container uppercase-text">
        <Toggle
          options={toggleOptions}
          name="useGlobalDefaults"
          onChange={onChangeToggle}
          value={useGlobalDefaults}
          disabled={readOnly}
        />
      </div>
      {reminderSettingType === REMINDER_SETTING_TYPES.global ? (
        globalDefaultHasReminders ? (
          <div className="accent-text">
            {globalDefaultRegisteredStudentsReminderMinutes && (
              <p>
                {`Reminders will be sent to all registered students ${getMinuteValueLabel(
                  globalDefaultRegisteredStudentsReminderMinutes,
                )} before event start time.`}
              </p>
            )}
            {globalDefaultUnregisteredStudentsReminderMinutes && (
              <p className="mt-16">
                {`Reminders will be sent to all unregistered students ${getMinuteValueLabel(
                  globalDefaultUnregisteredStudentsReminderMinutes,
                )} before event start time.`}
              </p>
            )}
          </div>
        ) : (
          <p className="flex flex-align-center mt-8 accent-text medium-text">
            <i className="material-icons ml-32 mr-4">notifications_off</i>
            No Reminders will be sent
          </p>
        )
      ) : (
        <div>
          <RadioGroup
            value={reminderSettingType}
            name="hasReminders"
            onChange={(e) => setReminderSettingType(e.target.value)}
          >
            <FormControlLabel
              value={REMINDER_SETTING_TYPES.custom}
              label="Custom reminder settings"
              control={<Radio disabled={readOnly} />}
            />
            <div className="ml-24">
              {error && (
                <span className="accent-text small-text error-text block mt-4">
                  You must select a reminder time.
                </span>
              )}
              <div
                className={`flex flex-align-center ${
                  readOnly && "accent-text"
                }`}
              >
                <Checkbox
                  checked={!!registeredStudentsReminderMinutes}
                  onChange={onChangeCheckBoxRegistered}
                  disabled={readOnly}
                />
                <ReminderTimeSelect
                  isRegistered={true}
                  setReminderMinutes={setReminderMinutes}
                  fieldName="registeredStudentsReminderMinutes"
                  fieldValue={registeredStudentsReminderMinutes}
                  disabled={readOnly}
                />
                {wasRegisteredStudentsReminderAlreadyTriggered && !readOnly && (
                  <div className="accent-text mt-8 small-text italic-text ml-24">
                    Reminder was already sent. Updating the event time or
                    reminder time will resend the reminder. To prevent a second
                    reminder, clear the reminder time.
                  </div>
                )}
              </div>
              <div
                className={`flex flex-align-center ${
                  readOnly && "accent-text"
                }`}
              >
                <Checkbox
                  checked={!!unregisteredStudentsReminderMinutes}
                  onChange={onChangeCheckBoxUnregistered}
                  disabled={readOnly}
                />
                <ReminderTimeSelect
                  isRegistered={false}
                  setReminderMinutes={setReminderMinutes}
                  fieldName="unregisteredStudentsReminderMinutes"
                  fieldValue={unregisteredStudentsReminderMinutes}
                  disabled={readOnly}
                />
                {wasUnregisteredStudentsReminderAlreadyTriggered &&
                  !readOnly && (
                    <div className="accent-text small-text italic-text ml-24">
                      Reminder was already sent. Updating the event time or
                      reminder time will resend the reminder. To prevent a
                      second reminder, clear the reminder time.
                    </div>
                  )}
              </div>
              <p className="flex flex-align-center small-text accent-text">
                <i className="material-icons ml-32 mr-4 xl-text">info</i>
                This includes all Jewish students and alumni across all grad
                years who are registered with your Chabad House, but are not
                registered for the event.
              </p>
            </div>
            <FormControlLabel
              value={REMINDER_SETTING_TYPES.none}
              label="Don't send reminder"
              control={<Radio disabled={readOnly} />}
            />
          </RadioGroup>
        </div>
      )}
    </div>
  );
}
export default React.memo(ReminderSettings);
