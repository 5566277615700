import React from "react";
import { ProfileFormSectionProps } from "../../ProfileFormHelpers";
import {
  CampusJewishStudentLifeInfo,
  CampusGeneralInfo,
  CampusPopulationInfo,
} from ".";
import { getProfileSectionId, ProfileSectionWrapper } from "../../profileMenu";

export interface CampusInfoProps extends ProfileFormSectionProps {
  campusIndex: number;
}

export const CampusInfo = React.memo(
  /**
   *
   */
  function CampusInfo(props: CampusInfoProps) {
    const { campusIndex, intersectionOptions } = props;

    return (
      <ProfileSectionWrapper
        id={getProfileSectionId("campus", campusIndex)}
        intersectionOptions={intersectionOptions}
        view="mobile"
      >
        <ProfileSectionWrapper
          id={getProfileSectionId("campusGeneral", campusIndex)}
          intersectionOptions={intersectionOptions}
          view="desktop"
        >
          <CampusGeneralInfo {...props} />
          <CampusPopulationInfo {...props} />
        </ProfileSectionWrapper>
        <CampusJewishStudentLifeInfo {...props} />
      </ProfileSectionWrapper>
    );
  },
);
