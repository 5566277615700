import React, { useCallback, useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import _isEqual from "lodash.isequal";
import moment from "moment";
import { Dialog } from "@material-ui/core";
import Select from "react-select";
import { DialogHeader } from "../../components";
import MultiSelectDeprecated from "../../components/form/deprecated-inputs/MultiSelectDeprecated";
import JewishInterestLevelChip from "./JewishInterestLevelChip";
import { formatFullName, pluralizeText } from "../../lib";
import { EngagementActions, StudentsActions } from "../../state";
import Loader from "../../components/Loader";

const getTypeCategoryRating = ({ rating }) => {
  return (
    <span className="interaction-category-rating ml-4">
      {Array(8)
        .fill()
        .map((_, i) => (
          <img
            alt=""
            className="mr-4"
            key={i}
            src={`/images/flame-${rating > i ? "solid" : "fade"}.png`}
          />
        ))}
    </span>
  );
};

function TargetStudentModal(props) {
  const {
    close,
    interactionTypes,
    onSubmit,
    show,
    student: {
      id,
      currentJil,
      demographics,
      person: { firstName, lastName } = {},
      studentID: _studentID,
      studentName,
    } = {},
  } = props;

  const studentID = _studentID || id; //Retrieved from different student prop structures provided based on where the modal is opened from (student list or profile header)
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [engagementPeriodOptions, setEngagementPeriodOptions] = useState([]);
  const [nextAlgorithmRunDate, setNextAlgorithmRunDate] = useState();
  const [isMaxStudentsTargeted, setIsMaxStudentsTargeted] = useState();
  const [targetedStudentsCount, setTargetedStudentsCount] = useState();
  const [selectedEngagementPeriod, setSelectedEngagementPeriod] = useState();
  const [targetStudentFields, setTargetStudentFields] = useState({
    id: null,
    engagementPeriodID: null,
    isCurrent: true,
    isTargeted: true,
    interactionTypeIDs: [],
    targetInterestLevel: currentJil || demographics?.jewishInterestLevel,
  });
  const [initialTargetStudentFields, setInitialTargetStudentFields] =
    useState(targetStudentFields);
  const dirty = !_isEqual(targetStudentFields, initialTargetStudentFields);

  const now = moment();
  const algorithmHasNotRunYet =
    moment(nextAlgorithmRunDate).month() === now.month() &&
    moment(nextAlgorithmRunDate).isAfter(now);
  const isAlreadyTargeted = !!targetStudentFields.id;
  const isDisabled =
    //Previous months' data can't be updated.
    //Cannot target student for current month if:  1) The monthly algorithm hasn't run yet.  2) The max amount of targeted students has already been reached this month (based on engagement goals).
    //If a student is already targeted for this month, you can untag them.
    !targetStudentFields.isCurrent ||
    algorithmHasNotRunYet ||
    (isMaxStudentsTargeted && !isAlreadyTargeted);

  const interactionTypeOptions = useMemo(() => {
    return interactionTypes
      .sort((t1, t2) => (t1.name > t2.name ? 1 : -1))
      .map(({ category, id, name }) => ({
        label: (
          <div className="flex flex-align-center flex-justify-space full-width">
            <div className="flex flex-align-center flex-justify-space full-width mobile-block">
              <p>{name}</p>
              <p className="accent-text mr-12 text-right mobile-text-left interaction-category-name">
                {category.name}
              </p>
            </div>
            <p className="flex flex-align-center flex-justify-space interaction-category-details">
              {getTypeCategoryRating(category)}
            </p>
          </div>
        ),
        searchValue: name,
        value: id,
      }));
  }, [interactionTypes]);

  const onChange = useCallback(
    (name, value) => {
      if (name === "engagementPeriodID") {
        const selectedPeriod = engagementPeriodOptions?.find(
          (p) => p.value === value.value,
        );
        setSelectedEngagementPeriod(selectedPeriod);
        const targetStudentFieldsUpdate = {
          [name]: value.value,
          isCurrent: value.isCurrent,
          interactionTypeIDs: [],
        };
        if (value.isCurrent) {
          setInitialTargetStudentFields({
            ...initialTargetStudentFields,
            ...targetStudentFieldsUpdate,
          });
        }
        return setTargetStudentFields({
          ...targetStudentFields,
          ...targetStudentFieldsUpdate,
        });
      }
      if (name === "isTargeted" && !value) {
        return setTargetStudentFields({
          ...targetStudentFields,
          [name]: value,
          interactionTypeIDs: [],
        });
      }
      setTargetStudentFields({ ...targetStudentFields, [name]: value });
    },
    [targetStudentFields, engagementPeriodOptions, initialTargetStudentFields],
  );

  const onSave = useCallback(async () => {
    const { engagementPeriodID, ...rest } = targetStudentFields;
    setLoading(true);

    let engagementPeriodObject;
    if (engagementPeriodID === "current") {
      engagementPeriodObject = {
        month: selectedEngagementPeriod?.month,
        year: selectedEngagementPeriod?.year,
      };
    } else {
      engagementPeriodObject = { engagementPeriodID };
    }

    const results = await dispatch(
      StudentsActions.targetStudent({
        ...rest,
        studentID,
        ...engagementPeriodObject,
      }),
    );
    if (results.success) {
      close();
      onSubmit();
    } else if (results.errorMessage) {
      setErrorMessage(results.errorMessage);
    }
    setLoading(false);
  }, [
    dispatch,
    targetStudentFields,
    close,
    onSubmit,
    studentID,
    selectedEngagementPeriod,
  ]);

  useEffect(() => {
    if (!engagementPeriodOptions.length) {
      const getEngagementPeriods = async () => {
        setLoading(true);
        const results = await dispatch(
          EngagementActions.getEngagementPeriods(true),
        );
        if (results.success) {
          const epOptions = results.data
            .filter((p) => !p.isUpcoming)
            .map((p) => ({
              isCurrent: p.isCurrent,
              value: p.id || "current",
              label: `${p.monthDisplay} ${p.year}`,
              monthDisplay: p.monthDisplay,
              month: p.month,
              year: p.year,
            }));
          setEngagementPeriodOptions(epOptions);
          const currentPeriod = epOptions?.find((p) => p.isCurrent); //defaulting the selected engagement period to the current month's period
          setSelectedEngagementPeriod(currentPeriod);
          setTargetStudentFields((t) => ({
            ...t,
            engagementPeriodID: currentPeriod?.value,
          }));
        } else if (results.errorMessage) {
          setErrorMessage(results.errorMessage);
        }
        setLoading(false);
      };

      getEngagementPeriods();
    }
  }, [dispatch, engagementPeriodOptions]);

  useEffect(() => {
    if (!nextAlgorithmRunDate) {
      const getNextAlgorithmDate = async () => {
        const results =
          await EngagementActions.getJewishInterestAlgorithmRunDates();
        if (results.success) {
          setNextAlgorithmRunDate(results.data.nextRunDate);
        } else if (results.errorMessage) {
          setErrorMessage(results.errorMessage);
        }
      };
      getNextAlgorithmDate();
    }
  }, [nextAlgorithmRunDate]);

  useEffect(
    () => {
      if (
        targetStudentFields.engagementPeriodID &&
        targetStudentFields.engagementPeriodID !== "current"
      ) {
        const getTargetedStudent = async () => {
          setLoading(true);
          const results = await dispatch(
            StudentsActions.getTargetedStudent(
              studentID,
              targetStudentFields.engagementPeriodID,
              targetStudentFields.isCurrent,
            ),
          );
          if (results.success) {
            const {
              isMaxStudentsTargeted,
              targetedStudentsCount,
              interactionGoals,
              targetInterestLevel,
              ...rest
            } = results.data;
            setIsMaxStudentsTargeted(isMaxStudentsTargeted);
            setTargetedStudentsCount(targetedStudentsCount);

            const targetStudentFieldsUpdate = {
              engagementPeriodID: targetStudentFields.engagementPeriodID,
              isCurrent: targetStudentFields.isCurrent,
              targetInterestLevel:
                targetInterestLevel || targetStudentFields.targetInterestLevel,
              ...rest,
            };
            if (targetStudentFields.isCurrent) {
              setInitialTargetStudentFields({
                ...targetStudentFieldsUpdate,
              });
            }
            setTargetStudentFields({
              ...targetStudentFieldsUpdate,
            });
          } else if (results.errorMessage) {
            setErrorMessage(results.errorMessage);
          }
          setLoading(false);
        };
        getTargetedStudent();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetStudentFields.engagementPeriodID],
  );
  return (
    <Dialog open={show} fullWidth maxWidth="sm">
      {loading ? (
        <Loader />
      ) : (
        <>
          <DialogHeader
            title={
              <div className="flex flex-align-center">
                Tag {studentName || formatFullName(firstName, lastName)}
                <div className="ml-16">
                  <JewishInterestLevelChip
                    jewishInterestLevel={
                      targetStudentFields.targetInterestLevel
                    }
                    narrow
                  />
                </div>
              </div>
            }
            handleClose={close}
          />
          <form className="profile-form">
            <div className="container">
              {algorithmHasNotRunYet && targetStudentFields.isCurrent ? (
                <div className="accent-text">
                  <p>You can only use this feature after the algorithm runs.</p>
                  <p>
                    {`The algorithm for ${now.format(
                      "MMMM",
                    )} will run on ${moment(nextAlgorithmRunDate).format(
                      "MMMM Do",
                    )}.`}
                  </p>
                </div>
              ) : (
                !targetStudentFields.isCurrent && (
                  <div className="accent-text">
                    Student tags for past months cannot be changed.
                  </div>
                )
              )}
              <div className="profile-form-grid parent-contact-info-grid mt-24">
                <div className="relative">
                  <div className="accent-text mb-8">Choose month</div>
                  <Select
                    className="custom-select"
                    classNamePrefix="custom-select"
                    isSearchable={false}
                    onChange={(val) => onChange("engagementPeriodID", val)}
                    options={engagementPeriodOptions}
                    placeholder="Choose month"
                    value={
                      targetStudentFields.engagementPeriodID &&
                      engagementPeriodOptions.find(
                        (p) =>
                          p.value.toString() ===
                          targetStudentFields.engagementPeriodID.toString(),
                      )
                    }
                  />
                </div>
                <div
                  className={`flex flex-align-center ml-10 mt-24 custom-checkbox-container  ${
                    isDisabled ? "disabled" : ""
                  }`}
                >
                  <input
                    id="shliach-override"
                    className="custom-checkbox"
                    checked={!!targetStudentFields.isTargeted}
                    name="isTargeted"
                    onChange={(e) => onChange("isTargeted", e.target.checked)}
                    type="checkbox"
                    disabled={isDisabled}
                  />
                  <label htmlFor="shliach-override">Tag student</label>
                </div>
              </div>
              {isMaxStudentsTargeted && !isAlreadyTargeted && (
                <div className="error-text mt-12" style={{ lineHeight: "1.2" }}>
                  <p>
                    {`You've already tagged ${targetedStudentsCount} ${
                      targetStudentFields.targetInterestLevel
                    } ${pluralizeText(
                      "student",
                      targetedStudentsCount,
                    )}, which is your goal for ${
                      selectedEngagementPeriod?.monthDisplay
                    }.`}
                  </p>
                  <p>
                    To tag this student, increase your goal or un-tag other
                    students.
                  </p>
                </div>
              )}
              <div className="accent-text mt-24 mb-16">
                Add engagements you intend to have with this student
              </div>
              <div className="target-student-interaction-select">
                <MultiSelectDeprecated
                  className="custom-select mb-32"
                  classNamePrefix="custom-select"
                  onChange={(_, vals) => {
                    onChange(
                      "interactionTypeIDs",
                      vals.map((i) => i.value),
                    );
                  }}
                  options={interactionTypeOptions}
                  value={interactionTypeOptions
                    .filter((e) =>
                      targetStudentFields.interactionTypeIDs?.includes(e.value),
                    )
                    .map((e) => {
                      return { label: e.searchValue, value: e.value };
                    })}
                  filterOption={(option, searchText) =>
                    !searchText
                      ? true
                      : option.data.searchValue
                          .toLowerCase()
                          .includes(searchText.toLowerCase())
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="text-right mb-24">
                <div>
                  <button
                    className="btn btn-light btn-medium"
                    disabled={loading}
                    onClick={close}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-accent btn-medium ml-16 mobile-ml-0 mobile-mt-8"
                    disabled={
                      (!dirty && isAlreadyTargeted) ||
                      loading ||
                      isDisabled ||
                      (!isAlreadyTargeted && !targetStudentFields.isTargeted) //trying to untag non-tagged student
                    }
                    type="button"
                    onClick={onSave}
                  >
                    Save
                  </button>
                </div>
              </div>
              {errorMessage && (
                <div className="mb-24 mt-4 error-text">{errorMessage}</div>
              )}
            </div>
          </form>
        </>
      )}
    </Dialog>
  );
}
export default React.memo(TargetStudentModal);
