import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import Toggle from "../../../../components/form/deprecated-inputs/ToggleDeprecated";
import { DialogHeader } from "../../../../components";
import { ApiCallErrorMessageHandler } from "../../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

export default class StudentProfileSettingsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      errorMessage: "",
      loading: false,
      studentProfileSettings: [],
    };

    state.initialState = _cloneDeep(state);
    this.state = state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.onToggleShow();
    }
  }

  onToggleShow = () => {
    const { show, studentProfileSettings } = this.props;

    if (show) {
      this.setState({
        studentProfileSettings: _cloneDeep(studentProfileSettings),
      });
    } else {
      this.setState(this.state.initialState);
    }
  };

  onToggleRequiredField = async (field, isRequired) => {
    if (isRequired && field === "State") {
      await this.setRequiredField("Country", isRequired);
    }
    if (!isRequired && field === "Country") {
      await this.setRequiredField("State", isRequired);
    }

    this.setRequiredField(field, isRequired);
  };
  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  };

  setRequiredField = (field, isRequired) => {
    let studentProfileSettings = _cloneDeep(this.state.studentProfileSettings);
    const profileSettingIndex = studentProfileSettings
      .map((sps) => sps.field)
      .indexOf(field);

    if (profileSettingIndex >= 0) {
      studentProfileSettings[profileSettingIndex].isRequired = isRequired;
    } else {
      studentProfileSettings.push({
        field,
        isRequired,
      });
    }

    return this.setStateAsync({ studentProfileSettings });
  };

  onSubmitStudentProfileSettings = async () => {
    this.setState({
      errorMessage: "",
      loading: true,
    });

    const { chabadHouseId, programId, submitStudentProfileSettings } =
      this.props;
    const { studentProfileSettings } = this.state;

    await submitStudentProfileSettings(
      chabadHouseId,
      programId,
      studentProfileSettings.filter(
        (spf) =>
          !spf.isLockedByAdmin && // exclude 'admin locked' settings
          (!spf.id || spf.programID === programId), // exclude 'chabad house locked/default' settings that do not match the current programId
      ),
    );

    const { close, error } = this.props;
    if (error) {
      this.setState({
        errorMessage: ApiCallErrorMessageHandler(error),
        loading: false,
      });
    } else {
      close();
    }
  };

  categorizedStudentProfileFields = [
    {
      category: "Personal Information",
      fields: [
        { label: "Profile Image", name: "ProfileImageURL" },
        { label: "First Name", name: "FirstName" },
        { label: "Last Name", name: "LastName" },
        { label: "Email", name: "Email" },
        { label: "Cell", name: "Cell" },
        { label: "Hebrew Name", name: "HebrewName" },
        { label: "Birthday", name: "Dob" },
        { label: "Gender", name: "Gender" },
        { label: "Is Jewish", name: "IsJewish" },
      ],
    },
    {
      category: "Home / Permanent Address",
      fields: [
        { label: "Address 1", name: "Address1" },
        { label: "City", name: "City" },
        { label: "State", name: "State" },
        { label: "Zip", name: "Zip" },
        { label: "Country", name: "Country" },
      ],
    },
    {
      category: "Current Education",
      fields: [
        { label: "School", name: "Campus" },
        { label: "Demographic", name: "Class" },
        { label: "Graduation Year", name: "GraduationYear" },
        { label: "School Student ID", name: "StudentIDNumber" },
        { label: "Major", name: "Major" },
        { label: "Double Major", name: "DoubleMajor" },
      ],
    },
    {
      category: "Family Background",
      fields: [
        { label: "Father's Background", name: "FatherBackground" },
        { label: "Mother's Background", name: "MotherBackground" },
        { label: "Father's Name", name: "FatherName" },
        { label: "Mother's Name", name: "MotherName" },
        { label: "Father's Email", name: "FatherEmail" },
        { label: "Mother's Email", name: "MotherEmail" },
        { label: "Father's Phone", name: "FatherPhone" },
        { label: "Mother's Phone", name: "MotherPhone" },
        {
          label: "Parents Reside at Same Address",
          name: "DoParentsShareResidence",
        },
      ],
    },
    {
      category: "Jewish Educational Background",
      fields: [
        { label: "Hebrew Language", name: "HebrewLevel" },
        { label: "Had Bar/Bat Mitzvah", name: "HadBarBatMitzvah" },
      ],
    },
    {
      category: "Israel Educational Background",
      fields: [
        {
          label: "Student has taken courses about Israel",
          name: "HasTakenIsraelCourses",
        },
        {
          label: "Student's knowledge about Jewish connection to Israel",
          name: "JewishIsraelKnowledgeLevel",
        },
      ],
    },
    {
      category: "Other Information",
      fields: [{ label: "Preferred Shirt Size", name: "TShirtSize" }],
    },
  ];

  render() {
    const { errorMessage, loading, studentProfileSettings } = this.state;
    const {
      close,
      disabled,
      studentProfileSettings: initialStudentProfileSettings,
      show,
    } = this.props;

    return (
      <Dialog
        open={show}
        fullWidth
        maxWidth="md"
        className="manage-student-settings-modal"
      >
        <DialogHeader title="Manage Student Settings" handleClose={close} />
        <DialogContent>
          <div>
            <div className="flex flex-justify-space mobile-flex-justify-start mb-24">
              <p className="accent-text small-text mt-8">
                Fields that are marked 'Required' with no optional switch are
                required by the System Administrator and cannot be changed
              </p>
            </div>
            {this.categorizedStudentProfileFields.map((spfGroup, spfgIndex) => (
              <div className="mb-32" key={spfgIndex}>
                <p className="fw-700 large-text mb-16">{spfGroup.category}</p>
                <div className="student-settings-grid">
                  {spfGroup.fields.map((field, fieldIndex) => {
                    const profileSetting =
                      studentProfileSettings.find(
                        (sps) => sps.field === field.name,
                      ) || {};
                    const isLockedByChabadHouseDefault =
                      !profileSetting.isLockedByAdmin &&
                      profileSetting.id != null &&
                      !profileSetting.programID;
                    return (
                      <div key={fieldIndex}>
                        <label
                          className="flex flex-align-center block mb-8"
                          style={{ maxHeight: "10px" }}
                        >
                          <span className="xs-text uppercase-text accent-text">
                            {field.label}
                          </span>
                          {isLockedByChabadHouseDefault && (
                            <span className="tooltip-container ml-8">
                              <i className="material-icons medium-text link-text no-hover">
                                info
                              </i>
                              <span
                                className="tooltip line-height-double pre-wrap"
                                style={{ width: "220px" }}
                              >
                                This setting is locked by the default
                                cross-program settings for this Chabad House
                              </span>
                            </span>
                          )}
                        </label>
                        {profileSetting.isLockedByAdmin ? (
                          <p className="accent-text-secondary">Required</p>
                        ) : (
                          <Toggle
                            disabled={disabled || isLockedByChabadHouseDefault}
                            name={field.name}
                            onChange={() =>
                              this.onToggleRequiredField(
                                field.name,
                                !profileSetting.isRequired,
                              )
                            }
                            options={[
                              {
                                value: true,
                                display: "Required",
                              },
                              {
                                value: false,
                                display: "Optional",
                              },
                            ]}
                            value={!!profileSetting.isRequired}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
            <div className="text-right mt-24">
              <div className="flex flex-align-center flex-justify-end add-student-btns">
                <p
                  className={`link-text uppercase-text${
                    loading ? " disabled" : ""
                  }`}
                  onClick={!loading ? close : null}
                >
                  Cancel
                </p>
                <button
                  className="btn btn-accent ml-24"
                  disabled={
                    _isEqual(
                      studentProfileSettings,
                      initialStudentProfileSettings,
                    ) ||
                    disabled ||
                    loading
                  }
                  onClick={this.onSubmitStudentProfileSettings}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </div>
              {errorMessage && (
                <p className="mt-8 error-text">{errorMessage}</p>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
