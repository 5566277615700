// import decode from 'jwt-decode';
// import { REHYDRATE } from 'redux-persist';
// import { Navigation } from '../../lib';

import { AuthActions } from "./actions";

export const AuthState = {
  name: "auth",
  persist: true,
  defaults: {},
  handlers: {
    // [REHYDRATE]: function reloadWindowIfAuthChanged(state, action) {
    //   const {
    //     payload: {
    //       auth: incoming,
    //     } = {}
    //   } = action;
    //   if (incoming && !incoming.token) {
    //     // TODO: If on a protected page, redirect to login...
    //     // CONSIDER: Not sure if Navigation.query exists during REHYDRATE...
    //   }
    //   return state;
    // },
    [AuthActions.DO_SIGNOUT]: function onLogout(state, action) {
      return AuthState.defaults;
    },
    [AuthActions.SIGNIN_COMPLETED]: function signinCompleted(state, action) {
      const {
        accountID,
        token,
        expiration,
        isImpersonated,
        loggedInAs,
        permissionClaims,
        person,
        userName,
        userEmail,
        userEmailHash,
        data,
      } = action.payload;
      return {
        accountID,
        token,
        expiration,
        isImpersonated,
        loggedInAs,
        started: new Date().toISOString(),
        permissionClaims,
        person,
        userName,
        userEmail,
        userEmailHash,
        ...data,
      };
    },
    [AuthActions.SET_ENGAGEMENT_ACCESS]: (state, data) => {
      return {
        ...state,
        engagementAccess: data,
      };
    },
    [AuthActions.SET_ENGAGEMENT_ACCESS_LOADING]: (state, value) => {
      return {
        ...state,
        engagementAccess: {
          ...state.engagementAccess,
          loading: value,
        },
      };
    },
  },
};
