import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2774ae",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#2774ae",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2774ae",
      },
    },
  },
})(TextField);
export const StyledTextFieldDeprecated = (props) => <CssTextField {...props} />;
export default StyledTextFieldDeprecated;
