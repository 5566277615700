import React from "react";
import { components as SelectComponents } from "react-select";
import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";
import { Dialog, DialogContent } from "@material-ui/core";
import RegistrationPayment from "../../../../shared/manage/students/registerStudent/RegistrationPayment";
import {
  ApiCallErrorMessageHandler,
  AuthRequest,
  formatFullName,
} from "../../../../../../../lib";
import ResourceRegistrationQuestions from "./ResourceRegistrationQuestions";
import {
  CheckboxDeprecated,
  MultiSelectDeprecated,
} from "../../../../../../../components/form/deprecated-inputs";
import StudentProfileModal from "../../../../../../students/studentProfile/StudentProfileModal";
import { DialogHeader } from "../../../../../../../components";

export default class RegisterStudentForResourceModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      availableStudentsList: [],
      availableStudentsErrorMessage: "",
      availableStudentsListLoading: false,

      // remove registrationFeeAmount default when build the functionality to allow shliach to process payment for student
      registration: {
        source: "Portal",
        status: "Registered",
        registrationFeeAmount: 0,
      },
      registrationErrorMessage: "",

      resourceDetails: {},
      resourceDetailsErrorMessage: "",
      resourceDetailsLoading: false,

      submitAttempted: false,
      sendRegistrantEmail: false,
      selectedStudentPersonIds: [],
      showCreateStudentModal: false,
    };

    state.initialState = _cloneDeep(state);
    this.state = state;
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.onToggleShow();
    }
  }

  onToggleShow = () => {
    const { show } = this.props;

    if (show) {
      //get list of available students if not already retrieved
      if (!this.state.availableStudentsList.length) {
        this.getAllStudents();
      }

      //get respirce details if not already retrieved
      if (!Object.keys(this.state.resourceDetails).length) {
        this.getResourceDetails();
      }
    } else {
      this.setState(_cloneDeep(this.state.initialState));
    }
  };

  getAllStudents = async () => {
    const { rsvpEnrollmentChabadHouseID } = this.props;

    this.setState({
      availableStudentsListErrorMessage: "",
      availableStudentsListLoading: true,
    });

    const newState = {
      availableStudentsListLoading: false,
    };

    try {
      const response = await AuthRequest.get(
        `ChabadHouses/${rsvpEnrollmentChabadHouseID}/Students/Basic?isPrimary=false`,
      );
      if (!response.data.payload || !response.data.payload.length) {
        newState.availableStudentsListErrorMessage = "No students found";
      } else {
        newState.availableStudentsList = response.data.payload;
      }
    } catch (err) {
      newState.availableStudentsListErrorMessage = ApiCallErrorMessageHandler(
        err,
        "Something went wrong and available students could not be retrieved. Please try again.",
      );
    }

    this.setState(newState);
  };

  getResourceDetails = async () => {
    const { resourceId, rsvpEnrollmentID } = this.props;

    this.setState({
      resourceDetailsErrorMessage: "",
      resourceDetailsLoading: true,
    });

    const newState = {
      resourceDetailsLoading: false,
    };

    try {
      const response = await AuthRequest.get(
        `RsvpEnrollments/${rsvpEnrollmentID}/resources/${resourceId}`,
      );
      newState.resourceDetails = response.data.payload;

      const { registrationQuestions } = newState.resourceDetails;
      if (registrationQuestions) {
        this.onChangeRegistration(
          "registrationQuestionResponses",
          registrationQuestions.map((rq) => ({
            isMandatory: rq.isMandatory, //isMandatory and questionText fields are not neessary for API and will be ignored, mapped for easy access in FE
            questionText: rq.questionText,
            registrationQuestionId: rq.id,
            responseType: rq.responseType, //responseType field is required by API for response value formatting
            value: "",
          })),
        );
      }
    } catch (err) {
      newState.resourceDetailsErrorMessage = ApiCallErrorMessageHandler(
        err,
        "Something went wrong and event details could not be retrieved. Please try again.",
      );
    }

    this.setState(newState);
  };

  onChange = (name, value) => {
    let state = _cloneDeep(this.state);
    _set(state, name, value);

    if (state.errorMessage) {
      state.errorMessage = "";
    }

    return new Promise((resolve) => {
      this.setState(state, () => resolve());
    });
  };

  onChangeStudents = (selectedStudentPersonIds) => {
    this.onChange("selectedStudentPersonIds", [
      ...selectedStudentPersonIds.map((s) => s.value),
    ]);
  };

  onChangeRegistration = async (name, value) =>
    await this.onChange(`registration.${name}`, value);

  onRegisterStudent = async () => {
    const { doSubmitStudentRegistration, resourceId, reloadStudents } =
      this.props;

    this.setState({ registrationErrorMessage: "", submitAttempted: true });

    if (!this.validateRegistration()) {
      this.setState({
        registrationErrorMessage: "Please completed required fields",
      });
      return;
    }

    const { sendRegistrantEmail, selectedStudentPersonIds } = this.state;
    await doSubmitStudentRegistration({
      ...this.state.registration,
      resourceId,
      sendRegistrantEmail,
      selectedStudentPersonIds,
    });

    const {
      close,
      submitStudentRegistration: { errorMessage, success },
    } = this.props;

    if (success) {
      close();
      this.getResourceDetails(); //reload schedule details
      reloadStudents(); //reload students table
    } else {
      this.setState({
        registrationErrorMessage:
          errorMessage || "Sorry, something went wrong.  Please try again.",
      });
    }
  };

  validateRegistration = () => {
    const {
      registration: { registrationQuestionResponses },
      selectedStudentPersonIds,
    } = this.state;

    if (
      selectedStudentPersonIds?.length < 2 &&
      registrationQuestionResponses?.some(
        (qr) =>
          qr.isMandatory &&
          (qr.responseType === "YesNoToggle"
            ? qr.value !== false && qr.value !== true
            : !qr.value),
      )
    ) {
      return false;
    }

    return true;
  };

  render() {
    const {
      availableStudentsList,
      availableStudentsErrorMessage,
      availableStudentsListLoading,

      registration,
      registrationErrorMessage,

      resourceDetails,
      resourceDetailsErrorMessage,
      resourceDetailsLoading,

      submitAttempted,
      selectedStudentPersonIds,
      showCreateStudentModal,
    } = this.state;

    const {
      allowCreateNewStudent,
      close,
      show,
      submitStudentRegistration: { loading: registrationLoading },
    } = this.props;

    const availableStudentsOptions = availableStudentsList.map((p) => ({
      value: p.personID,
      label: formatFullName(p.firstName, p.lastName),
    }));

    return (
      <Dialog open={show} fullWidth maxWidth="md">
        <DialogHeader
          title={`Add Students to ${resourceDetails.name || "this resource"}`}
          handleClose={close}
        />

        <DialogContent>
          <p className="small-text accent-text mb-24">
            Adding registrations for non-students is not currently available
          </p>
          <p className="fw-700 medium-text mb-8">Select student(s)</p>
          <p className="accent-text small-text mb-16">
            Choose from students with a COCI account associated with your school
          </p>
          <div className="mb-16">
            <div
              className="mb-16 tooltip-container"
              style={{ marginLeft: "1px", overflow: "visible" }}
            >
              <MultiSelectDeprecated
                className={
                  submitAttempted && !selectedStudentPersonIds.length
                    ? "error"
                    : ""
                }
                components={{
                  Menu: (props) => (
                    <SelectComponents.Menu {...props}>
                      {props.children}
                      {allowCreateNewStudent && (
                        <div
                          className="link-text"
                          onClick={() =>
                            this.setState({
                              showCreateStudentModal: true,
                            })
                          }
                          style={{ padding: "8px 12px" }}
                        >
                          Create a new Student
                        </div>
                      )}
                    </SelectComponents.Menu>
                  ),
                }}
                menuPlacement="bottom"
                isFixedOptionsFirst={false}
                onChange={(_, vals) => this.onChangeStudents(vals)}
                options={availableStudentsOptions}
                placeholder={
                  availableStudentsListLoading
                    ? "Loading students..."
                    : "Select students..."
                }
                value={selectedStudentPersonIds.map((personId) =>
                  availableStudentsOptions.find(
                    (s) => s.value.toString() === personId.toString(),
                  ),
                )}
              />
              {submitAttempted && !selectedStudentPersonIds.length && (
                <div className="small-text error-text mt-4">
                  Please add students
                </div>
              )}
              {availableStudentsErrorMessage && (
                <p className="mt-8 error-text">
                  {availableStudentsErrorMessage}
                </p>
              )}
            </div>
          </div>

          {selectedStudentPersonIds.length < 2 ? (
            <React.Fragment>
              <ResourceRegistrationQuestions
                onChangeRegistration={this.onChangeRegistration}
                registration={registration}
                resourceDetailsErrorMessage={resourceDetailsErrorMessage}
                resourceDetailsLoading={resourceDetailsLoading}
                submitAttempted={submitAttempted}
              />
              {!!resourceDetails.registrationFeeAmount && (
                <RegistrationPayment scheduleDetails={resourceDetails} />
              )}
              <CheckboxDeprecated
                checked={this.state.sendRegistrantEmail}
                className="mt-24 accent-text-dark event-settings-checkbox"
                label="Send email to student"
                name="sendRegistrantEmail"
                onChange={this.onChange}
              />
            </React.Fragment>
          ) : (
            <div>
              <p
                className="accent-text small-text mt-32 mb-32"
                style={{ lineHeight: 1.3 }}
              >
                When adding multiple students to a resource, you will not be
                able to make any selections for those registrations and students
                will not be notified that they were added to the resource.
              </p>
            </div>
          )}
          <div
            className="text-right mt-24"
            style={{ position: "absolute", bottom: 32, right: 32 }}
          >
            <div className="flex flex-align-center flex-justify-end add-student-btns">
              <p
                className={`link-text uppercase-text${
                  registrationLoading ? " disabled" : ""
                }`}
                onClick={!registrationLoading ? close : null}
              >
                Cancel
              </p>
              <button
                className="btn btn-accent ml-24"
                disabled={
                  selectedStudentPersonIds.length < 1 ||
                  resourceDetailsLoading ||
                  registrationLoading
                }
                onClick={this.onRegisterStudent}
              >
                {registrationLoading ? "Registering..." : "Register"}
              </button>
            </div>
            {registrationErrorMessage && (
              <p className="mt-8 error-text">{registrationErrorMessage}</p>
            )}
          </div>
        </DialogContent>
        {showCreateStudentModal && (
          <StudentProfileModal
            close={() => this.setState({ showCreateStudentModal: false })}
            onSubmit={async (newStudentProfile) => {
              await this.getAllStudents(); // reload students list with newly added student
              const personId = newStudentProfile.person.id;
              this.onChange("selectedStudentPersonIds", [
                ...this.state.selectedStudentPersonIds,
                personId,
              ]);
            }}
            show={showCreateStudentModal}
          />
        )}
      </Dialog>
    );
  }
}
