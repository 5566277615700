import React, { useState, useEffect } from "react";
import CheckboxDeprecated from "../../../../components/form/deprecated-inputs/CheckboxDeprecated";
//import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Toggle from "../../../../components/form/deprecated-inputs/ToggleDeprecated";
import ValidatedInput from "../../../../components/form/deprecated-inputs/ValidatedInputDeprecated";

function AllergyAndDietaryInfo(props) {
  const {
    allergyTypes,
    onChangeProfile,
    onChangeProfileEvt,
    profile: { hasAllergies, allergies, dietaryNotes } = {},
    showFormValidation,
  } = props;

  const [showSelectionRequiredError, setShowSelectionRequiredError] =
    useState(false);
  const [showAditionalInfoRequiredError, setShowAditionalInfoRequiredError] =
    useState(false);

  useEffect(() => {
    if (showFormValidation) {
      setShowSelectionRequiredError(
        hasAllergies && (!allergies || allergies.length === 0),
      );
      setShowAditionalInfoRequiredError(
        hasAllergies && allergies.includes("Other") && !dietaryNotes,
      );
    }
  }, [showFormValidation, hasAllergies, allergies, dietaryNotes]);

  return (
    <div className="profile-form-section">
      <p className="profile-section-title">Allergy and Dietary Restrictions</p>
      <span className="accent-text line-height-double block mb-8">
        Do you have any allergies or dietary needs?
      </span>

      <ValidatedInput
        className="toggle-container mb-16"
        input={
          <Toggle
            allowNoOption={true}
            noOptionValue={null}
            options={[
              {
                value: true,
                display: "YES",
              },
              {
                value: false,
                display: "NO",
              },
            ]}
          />
        }
        name="hasAllergies"
        onChange={(name, value) => {
          //clear out allergies list
          onChangeProfile("allergies", []);
          onChangeProfile(name, value);
        }}
        value={hasAllergies}
      />
      {/* for when we switch to MUI 
      <div className="mb-16">
        <ToggleButtonGroup
          value={hasAllergies}
          exclusive
          size="small"
          onChange={(e, value) => onChangeProfile("hasAllergies", value)}
        >
          <ToggleButton style={{ width: 60 }} value={true}>
            YES
          </ToggleButton>
          <ToggleButton style={{ width: 60 }} value={false}>
            NO
          </ToggleButton>
        </ToggleButtonGroup>
      </div> */}

      {hasAllergies && (
        <div className="mb-16">
          <span className="accent-text line-height-double block mb-8 uppercase">
            Select all that apply from list below
          </span>
          {showSelectionRequiredError && (
            <div className="required-text mb-16">You must select an option</div>
          )}
          <div className="profile-form-grid allergy-info-grid">
            {allergyTypes &&
              allergyTypes.map((allergyType) => (
                <CheckboxDeprecated
                  checked={
                    allergies && allergies.indexOf(allergyType.enumValue) >= 0
                  }
                  key={allergyType.enumValue}
                  label={allergyType.displayValue}
                  name={allergyType.enumValue}
                  onChange={(type, value) =>
                    onChangeProfile(
                      "allergies",
                      value
                        ? [...allergies, type]
                        : allergies.filter((a) => a !== type),
                    )
                  }
                />
              ))}
          </div>
        </div>
      )}
      {showAditionalInfoRequiredError && (
        <span className="required-text mb-16 mt-8">
          You must complete this field when "Other" is selected
        </span>
      )}
      <div
        className={`input-container mt-8 ${
          showAditionalInfoRequiredError && "error"
        }`}
        style={{ height: "100%" }}
      >
        <label>
          Additional Dietary Information and/or Severity of Allergies
        </label>
        <textarea
          className="custom-input"
          name="dietaryNotes"
          onChange={onChangeProfileEvt}
          style={{ background: "transparent", border: "none" }}
          value={dietaryNotes}
        />
      </div>
    </div>
  );
}
export default React.memo(AllergyAndDietaryInfo);
