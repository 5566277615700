import React from "react";

export class TagInputDeprecated extends React.PureComponent {
  state = {
    errorMessage: "",
    newTag: "",
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  removeTag = (tag) => {
    const { name, onChange, tags } = this.props;

    onChange(
      name,
      tags.filter((t) => t !== tag),
    );
  };

  saveTag = () => {
    const { name, onChange, tags } = this.props;
    const { newTag } = this.state;
    this.setState({ errorMessage: "" });

    if (tags.find((t) => t.toLowerCase() === newTag.toLowerCase())) {
      this.setState({
        errorMessage: `Cannot add duplicate value '${newTag}'`,
      });

      return;
    }

    onChange(name, [...tags, newTag]);

    this.setState({ newTag: "" });
  };

  render() {
    const {
      placeholder,
      sort,
      tags,
      style,
      containerStyle,
      className,
      disabled,
    } = this.props;
    const { errorMessage, newTag } = this.state;

    return (
      <React.Fragment>
        <div
          className={`chip-container flex flex-align-center ${className}`}
          style={containerStyle}
        >
          {tags &&
            tags
              .sort((tagA, tagB) =>
                sort ? tagA.toLowerCase().localeCompare(tagB.toLowerCase()) : 0,
              )
              .map((tag, index) => {
                return (
                  <div className="chip flex flex-align-center" key={index}>
                    <p className="accent-text-dark mb-0">{tag}</p>
                    <p
                      className={`accent-text mb-0 xl-text ml-16 ${
                        !disabled ? "pointer link-text-secondary" : ""
                      }`}
                      onClick={() => !disabled && this.removeTag(tag)}
                    >
                      &times;
                    </p>
                  </div>
                );
              })}
          <input
            type="text"
            placeholder={placeholder || "Add New"}
            value={newTag}
            name="newTag"
            onChange={this.onChange}
            onKeyDown={(e) => {
              if (newTag !== "" && (e.key === "Enter" || e.key === "Tab"))
                this.saveTag();
            }}
            onBlur={() => {
              if (newTag !== "") {
                this.saveTag();
              }
            }}
            style={style}
            className="mt-8"
            disabled={disabled}
          />
          {errorMessage && (
            <div className="error-text" style={{ margin: "8px 0px 0px auto" }}>
              {errorMessage}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default TagInputDeprecated;
