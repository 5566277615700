import React, { Fragment, useRef } from "react";
import { Typography } from "@material-ui/core";
import {
  FormCheckboxField,
  FormTextField,
  FormFieldWatch,
  getSetFieldValueOptions,
} from "../../../../components/form/react-hook-form";
import { ChabadHouseInfoProps } from "./ChabadHouseInfo";
import {
  ProfileFormGridItemFlexStyled,
  ProfileFormGridItemStyled,
  ProfileFormGridStyled,
} from "../../ProfileForm.styles";
import {
  SocialAccountTypes,
  SocialMediaAcount,
} from "../../ProfileFormHelpers";
import { useMobile } from "../../../../themes";

function getSocialUrlPlaceholder(socialAccountType: string | null) {
  switch (socialAccountType) {
    case SocialAccountTypes.Facebook:
      return "facebook.com/";
    case SocialAccountTypes.Instagram:
      return "instagram.com/";
    case SocialAccountTypes.X:
      return "x.com/";
    default:
      return "";
  }
}

export const ChabadHouseSocialInfo = React.memo(
  /**
   *
   */

  function ChabadHouseSocialInfo(props: ChabadHouseInfoProps) {
    const { chabadHouseIndex, form } = props;

    const chabadHouseFieldsAccessor = `chabadHouses.${chabadHouseIndex}`;

    const inputFocusRef = useRef<HTMLInputElement>(); //setting this when the text field is in focus

    const isMobile = useMobile();

    return (
      <>
        <Typography variant="subtitle2">Social media</Typography>
        <ProfileFormGridStyled
          style={{ marginTop: "0px", marginBottom: isMobile ? "20px" : "50px" }}
        >
          <FormFieldWatch
            name={`chabadHouses.${chabadHouseIndex}.socialMediaAccounts`}
          >
            {(socialMediaAccounts) => {
              return socialMediaAccounts?.map(
                (account: SocialMediaAcount, i: number) => {
                  const placeholder = `https://${getSocialUrlPlaceholder(
                    account.type,
                  )}`;
                  const accountTypeDisplayName =
                    account.type === SocialAccountTypes.X
                      ? "X/Twitter"
                      : account.type;
                  return (
                    <Fragment key={i}>
                      <ProfileFormGridItemStyled
                        style={isMobile ? { paddingBottom: 0 } : undefined}
                      >
                        <FormTextField
                          label={accountTypeDisplayName}
                          disabled={!!account.optOut}
                          name={`${chabadHouseFieldsAccessor}.socialMediaAccounts.${i}.url`}
                          placeholder={placeholder}
                          onFocus={(e) => {
                            //on focus, setting the value to the placeholder text (base url) when there is no value
                            if (!e.target.value) {
                              inputFocusRef.current =
                                e.target as HTMLInputElement;
                              form.setValue(
                                `chabadHouses.${chabadHouseIndex}.socialMediaAccounts.${i}.url`,
                                placeholder,
                                getSetFieldValueOptions(form),
                              );
                            }
                          }}
                          onMouseUp={() => {
                            //when the user clicks into the input, place the curser at the end (so they can just type their handle and not have to type the base url)
                            if (inputFocusRef.current) {
                              inputFocusRef.current.setSelectionRange(
                                1000,
                                1000,
                              );
                            }
                            inputFocusRef.current = undefined;
                          }}
                          onBlur={(e) => {
                            //on blur, if there was no handle typed in, remove the preset base url (placeholder) from the form field
                            if (e.target.value === placeholder) {
                              form.setValue(
                                `chabadHouses.${chabadHouseIndex}.socialMediaAccounts.${i}.url`,
                                "",
                                getSetFieldValueOptions(form),
                              );
                            }
                          }}
                        />
                      </ProfileFormGridItemStyled>

                      <ProfileFormGridItemFlexStyled
                        sm={2}
                        style={isMobile ? { paddingTop: 4 } : undefined}
                      >
                        <FormCheckboxField
                          label={
                            isMobile ? `No ${accountTypeDisplayName}` : "N/A"
                          }
                          name={`${chabadHouseFieldsAccessor}.socialMediaAccounts.${i}.optOut`}
                          onValueChange={(value) => {
                            // clear the socialMediaAccount url if opt out is checked
                            if (value) {
                              form.setValue(
                                `chabadHouses.${chabadHouseIndex}.socialMediaAccounts.${i}.url`,
                                "",
                                getSetFieldValueOptions(form),
                              );
                            }
                          }}
                        />
                      </ProfileFormGridItemFlexStyled>
                    </Fragment>
                  );
                },
              );
            }}
          </FormFieldWatch>
        </ProfileFormGridStyled>
      </>
    );
  },
);
