import React, { memo, useMemo } from "react";
import { Typography } from "@material-ui/core";
import MultiSelectDeprecated from "../../../components/form/deprecated-inputs/MultiSelectDeprecated";

function MonthYearsSelect(props) {
  const {
    getColors,
    onChange,
    values,
    monthYearsArray,
    maxSelectedMonthYears = 6,
  } = props;

  const colorStyles = useMemo(
    () => ({
      multiValue: (styles, { data }) => ({
        ...styles,
        backgroundColor: getColors(data.value).light,
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: getColors(data.value).dark,
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: getColors(data.value).dark,
      }),
    }),
    [getColors],
  );

  const selectedOptions = monthYearsArray.filter((m) =>
    values.includes(m.value),
  );

  return (
    <div className="mb-24">
      <Typography
        variant="subtitle2"
        className="mb-8 mt-16 accent-text-medium fw-500"
      >
        Select which months you would like to review. You may choose up to{" "}
        {maxSelectedMonthYears} months at once.
      </Typography>
      <MultiSelectDeprecated
        className="full-width"
        isOptionDisabled={() =>
          values && values.length >= maxSelectedMonthYears
        }
        onChange={(_, vals) => onChange(vals)}
        options={monthYearsArray}
        styles={colorStyles}
        value={selectedOptions}
      />
    </div>
  );
}

export default memo(MonthYearsSelect);
