import { AuthRequest, ApiCallErrorMessageHandler } from "../../lib";

export const ProfileActions = {
  getProfile(shliachId) {
    return async () => {
      let errorMessage = "";
      const response = await AuthRequest.get(
        `ShliachProfile/${shliachId}`,
      ).catch((e) => {
        errorMessage = ApiCallErrorMessageHandler(e);
      });
      if (errorMessage) {
        return { errorMessage };
      } else {
        return { data: response?.data?.payload };
      }
    };
  },
  submitStudentProfile(shliachProfile) {
    return async () => {
      let errorMessage = false;
      const response = await AuthRequest.post(
        `ShliachProfile/${shliachProfile.id}`,
        shliachProfile,
      ).catch((e) => {
        errorMessage = ApiCallErrorMessageHandler(e);
      });
      if (errorMessage) {
        return { errorMessage };
      } else {
        return { data: response?.data?.payload };
      }
    };
  },
};
