import React from "react";
import Loader from "../../../../components/Loader";
import Select from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";
import ValidatedInput from "../../../../components/form/deprecated-inputs/ValidatedInputDeprecated";
import SelectSearch from "react-select";
import { getYearOptions } from "../../../../lib";

export default class CurrentEducationInfo extends React.PureComponent {
  graduationYears = getYearOptions(2005, new Date().getFullYear() + 15);

  render() {
    const {
      campuses: {
        loading: campusesLoading,
        error: campusesError,
        data: campuses,
      },
      getProfileFieldLabel,
      isProfileFieldRequired,
      majors,
      onChangeProfile,
      onChangeProfileEvt,
      profile: {
        campus,
        class: studentClass,
        doubleMajorID,
        graduationYear,
        majorID,
        studentIDNumber,
      } = {},
      showFormValidation,
      studentClasses,
    } = this.props;

    return (
      <div className="profile-form-section">
        <p className="profile-section-title">
          Current education
          <span className="small-text accent-text line-height-double block mt-8">
            Note that changing your School may change profile field
            requirements. Please ensure that all required fields are complete
            prior to saving.
          </span>
        </p>
        <div className="profile-form-grid">
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <SelectSearch
                  classNamePrefix="input-container-select"
                  style={{ width: 100 }}
                  isClearable={true}
                  isSearchable={true}
                  options={campuses?.map((ca) => ({
                    label: ca.name,
                    value: ca.id,
                  }))}
                  placeholder="Select School"
                />
              }
              label={getProfileFieldLabel("School", "campus")}
              onChange={(campus) =>
                onChangeProfile(
                  "campus",
                  campus
                    ? {
                        id: campus.value,
                        name: campus.label,
                      }
                    : null,
                )
              }
              required={isProfileFieldRequired("campus")}
              showRequired={showFormValidation}
              value={
                campus && {
                  label: campus.name,
                  value: campus.id,
                }
              }
            />
            {campusesLoading ? (
              <div className="profile-school-loader">
                <Loader />
              </div>
            ) : (
              !!campusesError && (
                <span className="error-message mt-4 school-error-message">
                  We could not retrieve schools information. Please refresh the
                  page.
                </span>
              )
            )}
          </div>
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  isClearable={true}
                  options={studentClasses.map((cl) => ({
                    label: cl.displayValue,
                    value: cl.enumValue,
                  }))}
                  placeholder="Select Demographic"
                  searchable={true}
                />
              }
              label={getProfileFieldLabel("Demographic", "class")}
              name="class"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("class")}
              showRequired={showFormValidation}
              value={studentClass}
            />
          </div>
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  isClearable={true}
                  options={this.graduationYears.map((year) => ({
                    value: year,
                    label: year,
                  }))}
                  placeholder="Select Year"
                  searchable={true}
                />
              }
              label={getProfileFieldLabel("Graduating Year", "graduationYear")}
              name="graduationYear"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("graduationYear")}
              showRequired={showFormValidation}
              value={graduationYear}
            />
          </div>
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("School Student ID", "studentIDNumber")}
            name="studentIDNumber"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("studentIDNumber")}
            showRequired={showFormValidation}
            value={studentIDNumber}
          />
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  isClearable={true}
                  options={majors.map((m) => ({
                    label: m.name,
                    value: m.id,
                  }))}
                  placeholder="Select Major"
                  searchable={true}
                />
              }
              label={getProfileFieldLabel("Major", "majorID")}
              name="majorID"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("majorID")}
              showRequired={showFormValidation}
              value={majorID}
            />
          </div>
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  isClearable={true}
                  options={majors.map((m) => ({
                    label: m.name,
                    value: m.id,
                  }))}
                  placeholder="Select Double Major"
                  searchable={true}
                />
              }
              label={getProfileFieldLabel("Double Major", "doubleMajorID")}
              name="doubleMajorID"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("doubleMajorID")}
              showRequired={showFormValidation}
              value={doubleMajorID}
            />
          </div>
        </div>
      </div>
    );
  }
}
