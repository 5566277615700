import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { EngagementSelectors, SystemSelectors } from "../../../../state";
import { palette } from "../shared";
import Loader from "../../../../components/Loader";
import { formatNumber, sum } from "../../../../lib";
import {
  getMetricDetailsAccessor,
  getInteractionDataKey,
  getEngagementPeriodLabel,
} from "./metricHelpers";
import InteractionGoalChartHeader from "./InteractionMetricChartHeader";
import InteractionsBarChart from "./InteractionsBarChart";
import NoMetrics from "./NoMetrics";
import ColoredLegendEntry from "../../ColoredLegendEntry";

function formatData(data, engagementPeriodIds) {
  if (!data) return [];

  return engagementPeriodIds.map((engagementPeriodID) => {
    const monthData = {
      engagementPeriodID,
      total: 0,
    };

    data
      .filter(
        (goalMetrics) => goalMetrics.engagementPeriodID === engagementPeriodID,
      )

      .forEach((goalMetrics) => {
        const { goal, goalProgress, goalProgressExtra, interactionCategoryID } =
          goalMetrics;

        const goalProgressPercentage = (goalProgress * 100) / goal;

        monthData[getInteractionDataKey(interactionCategoryID)] =
          goalProgressPercentage > 100
            ? 100 //ensure that progress % does not exceed 100
            : goalProgressPercentage;

        monthData[getMetricDetailsAccessor(interactionCategoryID)] =
          goalMetrics;

        monthData.total += goalProgress + goalProgressExtra;
      });

    return monthData;
  });
}

function InteractionGoalMetricsChartByMonth(props) {
  const { engagementPeriodIds, engagementPeriods } = props;

  const [formattedData, setFormattedData] = useState([]);
  const [interactionSettingFilter, setInteractionSettingFilter] = useState("");

  const { data, loading, errorMessage } = useSelector(
    EngagementSelectors.interactionGoalMetrics,
  );
  const interactionCategories =
    useSelector(SystemSelectors.interactionCategories) || []; // value is undefined on signout

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, engagementPeriodIds));
      }
    },
    [data, engagementPeriodIds],
  );

  const getColors = useCallback(
    (value) => {
      const index = interactionCategories.findIndex(({ id }) => id === value);
      return palette[index] || {};
    },
    [interactionCategories],
  );

  const formatTickY = useCallback(
    (key, data) => {
      const monthYearDisplay = getEngagementPeriodLabel(key, engagementPeriods);
      const total = data.find((d) => d.engagementPeriodID === key)?.total;
      return `${monthYearDisplay}\nTotal: ${formatNumber(total)}`;
    },
    [engagementPeriods],
  );

  const dataTotal = useMemo(
    () => sum(formattedData.map((d) => d.total)),
    [formattedData],
  );

  const rowHeight =
    interactionCategories?.length * (interactionSettingFilter ? 20 : 30);

  const minRowHeight = 80;

  const chartHeight =
    formattedData.length *
    (rowHeight >= minRowHeight ? rowHeight : minRowHeight);

  return (
    <div className="graph-outline">
      <InteractionGoalChartHeader
        dataTotal={dataTotal}
        loading={loading}
        setInteractionSettingFilter={setInteractionSettingFilter}
        interactionSettingFilter={interactionSettingFilter}
        isGoalData
      />
      <div className="mt-16 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No engagement goal metrics found" />
        ) : (
          <>
            <Box className="flex flex-align-center mobile-block">
              {interactionCategories.map(({ id, name }) => (
                <ColoredLegendEntry
                  getColors={getColors}
                  id={id}
                  key={id}
                  name={name}
                />
              ))}
            </Box>

            <InteractionsBarChart
              chartHeight={chartHeight}
              data={formattedData}
              tickYFormatter={(key) => formatTickY(key, formattedData)}
              metrics={interactionCategories}
              interactionSettingFilter={interactionSettingFilter}
              getColors={getColors}
              isGoalData={true}
              groupByMonth={true}
              tickYDataKey={"engagementPeriodID"}
              tickXFormatter={(key) => `${key}%`}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default memo(InteractionGoalMetricsChartByMonth);
