import { AuthRequest } from "../../lib";
import queryString from "query-string";

export const ProgramsActions = {
  SET_PROGRAMS_LOADING: "SET_PROGRAMS_LOADING",
  SET_CHABAD_HOUSE_PROGRAMS_LOADING: "SET_CHABAD_HOUSE_PROGRAMS_LOADING",
  SET_DISPLAYED_PROGRAMS_LOADING: "SET_DISPLAYED_PROGRAMS_LOADING",
  UPDATE_PROGRAMS: "UPDATE_PROGRAMS",
  UPDATE_CHABAD_HOUSE_PROGRAMS: "UPDATE_CHABAD_HOUSE_PROGRAMS",

  getPrograms() {
    return async (dispatch, getState) => {
      dispatch(ProgramsActions.setProgramsLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(`Programs`).catch((err) => {
        success = false;
      });
      const newState = {
        loading: false,
        data: data,
        displayedPrograms: {
          data: data ? data.payload : [],
          loading: false,
        },
        success,
      };
      dispatch(ProgramsActions.updatePrograms(newState));
    };
  },
  getProgramRoutes() {
    return async (dispatch, getState) => {
      let success = true;
      const response = await AuthRequest.get(
        `Programs/routes?routeGroup=Shluchim`,
      ).catch((err) => {
        success = false;
      });
      const newState = {
        routes: response && response.data.payload,
        success,
      };
      dispatch(ProgramsActions.updatePrograms(newState));
    };
  },
  searchPrograms(searchTerm, audiences, categories) {
    return async (dispatch) => {
      dispatch(ProgramsActions.setDisplayedProgramsLoading(true));
      let success = true;
      const params = {
        text: searchTerm,
        audiences,
        categories,
      };
      const response = await AuthRequest.get(
        `Search/programs?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
      });
      const newState = {
        displayedPrograms: {
          data: response?.data?.payload,
          loading: false,
          success,
        },
      };
      dispatch(ProgramsActions.updatePrograms(newState));
    };
  },
  setProgramsLoading(payload) {
    return {
      type: ProgramsActions.SET_PROGRAMS_LOADING,
      payload,
    };
  },
  setChabadHouseProgramsLoading(payload) {
    return {
      type: ProgramsActions.SET_CHABAD_HOUSE_PROGRAMS_LOADING,
      payload,
    };
  },
  setDisplayedProgramsLoading(payload) {
    return {
      type: ProgramsActions.SET_DISPLAYED_PROGRAMS_LOADING,
      payload,
    };
  },
  updatePrograms(payload) {
    return {
      type: ProgramsActions.UPDATE_PROGRAMS,
      payload,
    };
  },
  updateChabadHousePrograms(payload) {
    return {
      type: ProgramsActions.UPDATE_CHABAD_HOUSE_PROGRAMS,
      payload,
    };
  },
};
