import { NamedExoticComponent, Dispatch, SetStateAction } from "react";
import moment from "moment";
import Big from "big.js";
import {
  ComposeMessage,
  ReviewRecipients,
  SelectRecipients,
  Confirm,
} from "./wizardSteps";
import { SentMessageStatus } from "./wizardSteps/SentMessageStatus";
import {
  ChabadHouseSmsEnrollment,
  EventSchedule,
  Resource,
  RsvpRegistrationPerson,
  StudentDetails,
  StudentFilters,
  SmsThreadPerson,
} from "../../../lib/apiRequests/ApiTypes";
import { getSmsStatement } from "../../../lib/apiRequests/SmsApiRequests";
import { SegmentedMessage } from "sms-segments-calculator";
import { formatCurrency } from "../../../lib";
import { FileForUpload } from "../../../lib/types";

//#region types
interface WizardStep {
  name: string;
  component: NamedExoticComponent<WizardStepProps>;
  id: WizardStepIdentifiers;
}

export interface SmsRecipients {
  filteredStudents: StudentDetails[];
  additionalStudents: StudentDetails[];
}

export interface WizardProps {
  eventScheduleId?: number;
  onClose: () => void;
  openToStep?: number;
  resourceId?: number;
  rsvpActionType?: RsvpActionTypes;
  threadId?: number;
}

export interface WizardStepProps extends WizardProps {
  back: () => void;
  chabadHouseEnrollmentData: ChabadHouseSmsEnrollment;
  messageImage: FileForUpload | null;
  messageText: string;
  messageScheduleType: MessageScheduleTypes | null;
  next: () => void;
  onCancel: () => void;
  recipients: SmsRecipients;
  removeRecipients: (students: StudentDetails[]) => void;
  setAdditionalStudents: (students: StudentDetails[]) => void;
  setStudentFilters: Dispatch<SetStateAction<StudentFilters>>;
  setFilteredStudents: (students: StudentDetails[]) => void;
  setMessageImage: Dispatch<SetStateAction<FileForUpload | null>>;
  setMessageText: Dispatch<SetStateAction<string>>;
  setMessageScheduleType: Dispatch<SetStateAction<MessageScheduleTypes | null>>;
  setStudentView: Dispatch<SetStateAction<string>>;
  studentFilters: StudentFilters;
  studentView: string;
}

export interface WizardStepError {
  dataRetrievalError?: string;
  validationError?: string;
  submissionError?: string;
}

export interface MessagePricing {
  price: Big | null;
  segments: number | null;
  balance: Big | null;
}

//#region consts

export enum WizardStepIdentifiers {
  SelectRecipients,
  ReviewRecipients,
  ComposeMessage,
  Confirm,
  SentMessageStatus,
}

export const wizardSteps: WizardStep[] = [
  {
    component: SelectRecipients,
    name: "Choose Recipients",
    id: WizardStepIdentifiers.SelectRecipients,
  },
  {
    component: ReviewRecipients,
    name: "Review Recipients",
    id: WizardStepIdentifiers.ReviewRecipients,
  },
  {
    component: ComposeMessage,
    name: "Compose Message",
    id: WizardStepIdentifiers.ComposeMessage,
  },
  {
    component: Confirm,
    name: "Confirm & Send",
    id: WizardStepIdentifiers.Confirm,
  },
  {
    component: SentMessageStatus,
    name: "Sent",
    id: WizardStepIdentifiers.SentMessageStatus,
  },
];

export enum RsvpActionTypes {
  New = 1,
  Update,
}

export const scheduleSendOptions = [
  {
    name: "Tomorrow at 9:00 AM",
    value: moment()
      .add(1, "day")
      .hours(9)
      .minutes(0)
      .seconds(0)
      .milliseconds(0),
  },
  {
    name: "Monday at 9:00 AM",
    value: moment().day(8).hours(9).minutes(0).seconds(0).milliseconds(0),
  },
];

export enum MessageScheduleTypes {
  Scheduled,
  Immediate,
}
export const StudentViews = {
  Individuals: "Individuals",
  Filters: "Filters",
};

export const emptyFilters: StudentFilters = {
  campusID: [],
  chabadHouseTags: [],
  classes: [],
  excludeParticipatedInPrograms: [],
  isJewish: undefined,
  includeArchived: false,
  graduationYear: [],
  programsParticipated: [],
};

//#endregion

// //#region business logic

export function convertRsvpRegistrationToStudent(
  student: RsvpRegistrationPerson,
) {
  return {
    studentFirstName: student.firstName,
    studentLastName: student.lastName,
    cell: student.cell,
    campusName: student.campusName,
    graduationYear: student.graduationYear,
    isJewish: student.isJewish,
    studentID: student.studentID,
    personID: student.personID,
  };
}

export function convertThreadRecipientToStudent(person: SmsThreadPerson) {
  if (!person.studentId) {
    return null;
  }
  const namesArray = person.name.split(" ");
  const lastName = namesArray.pop();
  const firstName = namesArray.join(" ");
  return {
    studentFirstName: firstName || "",
    studentLastName: lastName || "",
    cell: person.phoneNumber,
    campusName: person.campusName,
    graduationYear: person.graduationYear,
    isJewish: person.isJewish,
    studentID: person.studentId,
    personID: person.personId,
  };
}

export async function calculateMessagePricing(
  chabadHouseId: number,
  recipients: SmsRecipients,
  hasImage: boolean,
  messageText: string,
): Promise<{ pricing?: MessagePricing; error?: string }> {
  const date = new Date();

  const { data, error } = await getSmsStatement(
    chabadHouseId,
    new Date(date.getFullYear(), date.getMonth(), 1),
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );
  if (error) {
    return { error };
  }
  const recipientCount =
    recipients.additionalStudents.length + recipients.filteredStudents.length;
  const segments =
    messageText && !hasImage
      ? new SegmentedMessage(messageText)?.segments?.length
      : 1;
  const pricePerMesssage = Big(
    (hasImage ? data?.pricePerMmsSegment : data?.pricePerSegment) || 0,
  ).times(segments);
  const price = pricePerMesssage.times(recipientCount);
  return {
    pricing: {
      price,
      segments,
      balance: data ? data.balance : null,
    },
  };
}

export function getRsvpStatusText(
  actionType: RsvpActionTypes,
  rsvpItemType: string,
) {
  switch (actionType) {
    case RsvpActionTypes.New:
      return `There's a new exciting ${rsvpItemType} available at Chabad!`;
    case RsvpActionTypes.Update:
      return `The ${rsvpItemType} has been updated:`;
  }
}

export function getEventDetailsText(event: EventSchedule) {
  let eventText = "";
  eventText += `${event.name} (Reply ${event.primarySmsCode} to RSVP)\n`;
  eventText += event.shortDescription + "\n";
  eventText += `Date: ${event.formattedDate}\n`;
  eventText += `Time: ${event.formattedEventScheduleTime}`;
  eventText +=
    event.location === "Other"
      ? `\nWhere: ${event.locationName ? event.locationName : ""}${
          event.address1
            ? event.locationName
              ? " - " + event.address1
              : event.address1
            : ""
        }${event.address2 ? " " + event.address2 : ""}`
      : event.location === "Virtual"
      ? "\nVirtually"
      : "\nWhere: " + event.locationDisplay;
  eventText += event.registrationFeeAmount
    ? `\nFee: ${formatCurrency(event.registrationFeeAmount, "USD")}`
    : "";
  return eventText;
}

export function getResourceDetailsText(resource: Resource) {
  let resourceText = "";
  resourceText += `${resource.name} (Reply ${resource.primarySmsCode} to let us know you are in!)\n`;
  resourceText += resource.shortDescription;
  resourceText += resource.registrationFeeAmount
    ? `\nFee: ${formatCurrency(resource.registrationFeeAmount, "USD")}`
    : "";
  return resourceText;
}

export function getIsSmsCreditNeeded(pricingData: MessagePricing): boolean {
  return pricingData?.price && pricingData.balance !== null
    ? pricingData.price > pricingData.balance
    : false;
}

// //#endregion
