import React, { useCallback, useState } from "react";
import { Dialog } from "@material-ui/core";
import { DialogHeader } from "../../components";
import Select from "../../components/form/deprecated-inputs/CustomSelectDeprecated";
import ValidatedInput from "../../components/form/deprecated-inputs/ValidatedInputDeprecated";

function StudentObservanceModal(props) {
  const {
    show,
    close,
    student,
    student: {
      demographics: {
        currentJewishObservanceStatus,
        childhoodJewishObservanceStatus,
      } = {},
    } = {},
    jewishObservanceStatuses,
    onSubmit,
    submitStudent,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [observanceFields, setObservanceFields] = useState({
    childhoodJewishObservanceStatus: childhoodJewishObservanceStatus,
    currentJewishObservanceStatus: currentJewishObservanceStatus,
  });

  const onChange = useCallback(
    (name, value) => {
      setObservanceFields({ ...observanceFields, [name]: value });
    },
    [observanceFields],
  );
  const onSave = useCallback(async () => {
    setLoading(true);
    const results = await submitStudent({
      ...student,
      demographics: {
        ...student.demographics,
        childhoodJewishObservanceStatus:
          observanceFields.childhoodJewishObservanceStatus,
        currentJewishObservanceStatus:
          observanceFields.currentJewishObservanceStatus,
      },
    });
    if (results.success) {
      close();
      onSubmit();
    } else if (results.errorMessage) {
      setErrorMessage(results.errorMessage);
    }
    setLoading(false);
  }, [student, submitStudent, observanceFields, close, onSubmit]);

  return (
    <Dialog open={show} fullWidth maxWidth="sm">
      <DialogHeader title="Observance statuses" handleClose={close} />

      <form className="profile-form">
        <div className="container">
          <div className="profile-form-section">
            <div className="profile-form-grid parent-contact-info-grid mb-24">
              <div className="relative">
                <ValidatedInput
                  className="input-container"
                  input={
                    <Select
                      classNamePrefix="input-container-select"
                      isClearable={true}
                      options={jewishObservanceStatuses.map((o) => ({
                        label: o.displayValue,
                        value: o.enumValue,
                      }))}
                    />
                  }
                  label="Current observance"
                  name="currentJewishObservanceStatus"
                  onChange={onChange}
                  value={observanceFields.currentJewishObservanceStatus}
                />
              </div>
              <div className="relative">
                <ValidatedInput
                  className="input-container"
                  input={
                    <Select
                      classNamePrefix="input-container-select"
                      isClearable={true}
                      options={jewishObservanceStatuses.map((o) => ({
                        label: o.displayValue,
                        value: o.enumValue,
                      }))}
                    />
                  }
                  label="Childhood observance"
                  name="childhoodJewishObservanceStatus"
                  onChange={onChange}
                  value={observanceFields.childhoodJewishObservanceStatus}
                />
              </div>
            </div>
            <div className="line-height-double observance-type-card">
              <p className="fw-700 mb-8">Observance statuses</p>

              <li className="mb-8">
                <span className="fw-700 mr-4">Absent</span>
                <span className="accent-text">
                  Didn't know, or wasn't, Jewish
                </span>
              </li>
              <li className="mb-8">
                <span className="fw-700 mr-4">Ethnic</span>
                <span className="accent-text">
                  Jewish identity, but no affiliation or observance.
                </span>
              </li>
              <li className="mb-8">
                <span className="fw-700 mr-4">Cultural</span>
                <span className="accent-text">
                  Some affiliation or celebration. Anything from High Holidays
                  to Jewish summer camp or even day school.
                </span>
              </li>
              <li className="mb-8">
                <span className="fw-700 mr-4">Traditional</span>
                <span className="accent-text">
                  Strong Jewish affiliation and many observances, but not Frum.
                  E.g., Persian, Moroccan, or other Sefardi traditional, some
                  South African or British, stronger conservative or
                  conservadox.
                </span>
              </li>
              <li className="mb-8">
                <span className="fw-700 mr-4">Observant</span>
                <span className="accent-text">
                  At least a basic Halachic level of Shomer Shabbos and Kashrus.
                </span>
              </li>
            </div>
          </div>
          <div className="text-right mb-24">
            <div>
              <button
                className="btn btn-light btn-medium"
                disabled={loading}
                onClick={close}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-medium ml-16 mobile-ml-0 mobile-mt-8"
                disabled={loading}
                type="button"
                onClick={onSave}
              >
                Save
              </button>
            </div>
            {errorMessage && (
              <div className="mt-4 error-text">{errorMessage}</div>
            )}
          </div>
        </div>
      </form>
    </Dialog>
  );
}
export default React.memo(StudentObservanceModal);
