import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "@material-ui/core";
import moment from "moment";
import Pages from "../../../../..";
import {
  formatCurrency,
  formatFullName,
  PageLink,
  currencyCodes,
} from "../../../../../../lib";
import { RsvpRegistrationStatuses } from "../../../../RsvpHelpers";
import { AuthSelectors } from "../../../../../../state";

function ResourcesStudentsListRow(props) {
  const {
    onRegisterStudent,
    onRemoveStudent,
    showPaidDonated,
    person: {
      cell,
      donationAmount,
      email,
      firstName,
      lastName,
      notes,
      personClassification,
      personClassificationDisplay,
      registrationDate,
      registrationFeeAmount,
      registrationQuestionResponses,
      statusDisplay,
      studentID,
    },
  } = props;

  var [showNotesModal, setShowNotesModal] = useState(false);

  const allowManageStudents = useSelector(AuthSelectors.canManageStudents);

  const isStudent = personClassification === "Student";

  return (
    <div
      className={`paginated-table-row ${
        showPaidDonated
          ? "rsvp-students-table-row-extended"
          : "rsvp-students-table-row"
      }`}
    >
      <div className="desktop-display-contents">
        <div>
          {!isStudent && (
            <div className="tooltip-container flex">
              <i className="material-icons link-text-secondary accent-text large-text">
                person
              </i>
              <span className="tooltip">{personClassificationDisplay}</span>
            </div>
          )}
        </div>

        {!!studentID && allowManageStudents ? (
          <PageLink
            className="link-text-secondary"
            params={{ studentId: studentID }}
            to={Pages.engagement.studentDetails}
          >
            <div>{formatFullName(firstName, lastName)}</div>
          </PageLink>
        ) : (
          <div>{formatFullName(firstName, lastName)}</div>
        )}

        <div className="student-table-row-mobile-small-text">{email}</div>
        <div className="student-table-row-mobile-small-text">{cell}</div>
        <div className="student-table-row-mobile-small-text flex">
          {/* TODO: we deprecated the notes field on 2/13/25 (COC-3219) - the client wanted to keep displaying the field for now but we will remove it at a later time*/}
          {(notes ||
            registrationQuestionResponses?.filter((qr) => qr.value).length >
              0) && (
            <React.Fragment>
              <i
                className="material-icons link-text-secondary large-text mobile-hidden"
                onClick={() => setShowNotesModal(!showNotesModal)}
              >
                info_outlined
              </i>
              <p
                className="accent-text tablet-hidden desktop-hidden"
                onClick={() => setShowNotesModal(!showNotesModal)}
              >
                View details
              </p>
            </React.Fragment>
          )}
          {showNotesModal && (
            <Modal
              open={showNotesModal}
              className="modal-container"
              size="medium"
            >
              <div
                className="modal-card card large-modal"
                style={{ padding: 24 }}
              >
                <p className="xl-text fw-900 mb-24">
                  Additional Details - {formatFullName(firstName, lastName)}
                </p>

                {registrationQuestionResponses &&
                  registrationQuestionResponses
                    .filter((qr) => qr.value)
                    .map((qr, i) => (
                      <div key={i}>
                        <div className="mb-8">
                          <span className="fw-700">Q: </span>
                          <span>{qr.questionText}</span>
                        </div>
                        <div className="mb-16">
                          <span className="fw-700">A: </span>
                          <span className="accent-text">
                            {qr.responseType === "YesNoToggle"
                              ? qr.value === "True"
                                ? "Yes"
                                : "No"
                              : qr.value}
                          </span>
                        </div>
                      </div>
                    ))}
                {notes && (
                  <>
                    <div className="fw-700 mt-32 mb-8">Notes </div>
                    <p className="line-height-double pre-wrap accent-text">
                      {notes}
                    </p>
                  </>
                )}
                <hr className="modal-separator" />
                <div className="text-right">
                  <div className="modal-btns-wrapper mt-8">
                    <Button
                      className="btn-accent"
                      onClick={() => setShowNotesModal(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
        <div className="student-table-row-mobile-small-text">
          {moment(registrationDate).format("MM/DD/YYYY")}
        </div>
        <div>
          {statusDisplay === RsvpRegistrationStatuses.Waitlisted ? (
            <p className="link-text" onClick={onRegisterStudent}>
              {statusDisplay}
            </p>
          ) : (
            <p className="student-table-row-mobile-small-text">
              {statusDisplay}
            </p>
          )}
        </div>
        {showPaidDonated && (
          <div className="mobile-hidden">
            {formatCurrency(registrationFeeAmount, currencyCodes.USD)} /{" "}
            {formatCurrency(donationAmount, currencyCodes.USD)}
          </div>
        )}
      </div>
      <i
        className="material-icons link-text-secondary accent-text large-text text-right"
        onClick={(e) => {
          e.preventDefault();
          onRemoveStudent();
        }}
      >
        delete
      </i>
    </div>
  );
}

export default memo(ResourcesStudentsListRow);
