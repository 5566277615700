import React from "react";
import { Prompt } from "react-router-dom";
import { subWeeks } from "date-fns";
import CancelScheduleModal from "./CancelScheduleModal";
import CopyToClipboardTextbox from "../../../../../../components/CopyToClipboardTextbox";
import CustomSelectDeprecated from "../../../../../../components/form/deprecated-inputs/CustomSelectDeprecated";
import DateTimePicker from "../../../../../../components/DateTimePicker";
import Toggle from "../../../../../../components/form/deprecated-inputs/ToggleDeprecated";
import {
  formatDateTimeForInput,
  getAddressDisplay,
  getFormattedValuesForForm,
  removeEmptyFromObj,
  removeTimezoneFormatFromDate,
  Navigation,
  PageURL,
  PageNavLink,
} from "../../../../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import moment from "moment";
import { EduProgramTypes } from "../../../../eduUtils";

export default class ScheduleSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      ...this.getInitializedCourseSchedule(),
      errorMessage: "",
      requestedNavigation: false,
      showCancelScheduleConfirmationModal: false,
      showSetWeeklyClassSchedulesPrompt: false,
      submitAction: null,
      submitAttempted: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  incompleteErrorMessage = "Please complete required fields";

  actionTypes = {
    cancel: "cancel",
    submit: "submit",
  };

  getInitializedCourseSchedule = () => {
    const {
      course: { data: course = {} },
      courseSchedule: { data: courseSchedule = {} },
      enrollment: { data: { timezoneID } = {} },
      newSchedule,
    } = this.props;

    let initializedSchedule;
    if (newSchedule) {
      initializedSchedule = {
        classSchedules: course.classes
          .sort((c1, c2) => c1.sortOrder - c2.sortOrder)
          .map((cls) => ({
            dateTime: "",
            description: cls.description,
            eduClassID: cls.id,
            sortOrder: cls.sortOrder,
            timezoneID, //must set timezoneID for class dateTime conversion
            title: cls.title,
          })),
        description: "",
        eduCourseID: course.id,
        isActive: true,
        //always initialize schedule location fields for binding purposes, removing before submission if Chabad house location is selected
        locationAddress: {
          address1: "",
          address2: "",
          city: "",
          country: "",
          name: "",
          state: "",
          zip: "",
        },
        locationName: "",
        location: "ChabadHouse",
        registrationStartDate: removeTimezoneFormatFromDate(moment()), //default registration start date to today
        registrationEndDate: "",
        semester: "",
        timezoneID, //must set timezoneID for registration start/end dateTime conversion
        year: new Date().getFullYear(),
      };
    } else {
      initializedSchedule = getFormattedValuesForForm(courseSchedule);
    }

    return {
      schedule: initializedSchedule,
      setScheduleDates: newSchedule
        ? true
        : !!(
            courseSchedule.classSchedules &&
            courseSchedule.classSchedules.some((cs) => !!cs.dateTime)
          ),
    };
  };

  onChangeSchedule = (name, value, otherChanges) => {
    if (this.props.submitCourseSchedule.loading) {
      return;
    }

    let schedule = _cloneDeep(this.state.schedule);
    _set(schedule, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(schedule, change, otherChanges[change]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ schedule }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateSchedule();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeScheduleEvt = ({ target: { name, value } }) => {
    return this.onChangeSchedule(name, value);
  };

  onCancelSchedule = async (courseCancellationReasonId) => {
    this.setState({
      showCancelScheduleConfirmationModal: false,
      submitActionType: this.actionTypes.cancel,
    });

    const courseScheduleForCancellation = {
      ...this.props.courseSchedule.data,
      isActive: false,
      eduCourseCancellationReasonID: courseCancellationReasonId,
    };
    await this.props.actions.submitEduCourseSchedule(
      courseScheduleForCancellation,
    );

    const {
      pageRoute: { page, params },
      submitCourseSchedule: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      this.setState({ requestedNavigation: true }, () => {
        Navigation.go(
          PageURL.to(
            page,
            {
              pageView: "courses",
              programScheduleId: params.programScheduleId,
            },
            null,
          ),
        );
      });
    }
  };

  onCancelScheduleChanges = () => {
    this.setState(this.state.initialState, this.props.pageRoute.history.goBack);
  };

  onSubmitSchedule = async () => {
    this.setState({
      errorMessage: "",
      submitActionType: this.actionTypes.submit,
      submitAttempted: true,
    });

    const isValid = this.validateSchedule();
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const {
      initialState: { schedule: initialSchedule },
      schedule,
      schedule: { location },
    } = this.state;
    const courseScheduleForSubmission = _cloneDeep(schedule);
    if (location !== "Other") {
      courseScheduleForSubmission.locationName = "";
      courseScheduleForSubmission.locationAddress = "";
    }
    removeEmptyFromObj(courseScheduleForSubmission);
    await this.props.actions.submitEduCourseSchedule(
      courseScheduleForSubmission,
    );

    const {
      newSchedule,
      pageRoute: { page, params },
      submitCourseSchedule: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      if (newSchedule) {
        //navigate to my courses page
        this.setState({ requestedNavigation: true }, () => {
          Navigation.go(
            PageURL.to(
              page,
              {
                ...params,
                courseId: undefined,
                courseView: undefined,
              },
              null,
            ),
          );
        });
      } else {
        //refresh course schedules in dropdown if updated schedule name
        const didUpdateCourseScheduleName =
          courseScheduleForSubmission.description !==
            initialSchedule.description ||
          courseScheduleForSubmission.semester !== initialSchedule.semester ||
          courseScheduleForSubmission.year !== initialSchedule.year;
        if (didUpdateCourseScheduleName) {
          this.props.actions.getEduCourseSchedules();
        }

        const updatedState = {
          ...this.state.initialState,
          ...this.getInitializedCourseSchedule(),
        };
        updatedState.initialState = _cloneDeep(updatedState);
        this.setState(updatedState);
      }
    }
  };

  onChangeClassScheduleDate = (name, value, index, prevValue) => {
    const classDate = value && removeTimezoneFormatFromDate(value);
    const isSettingFirstClassDate =
      index === 0 && value && classDate !== prevValue;

    this.onChangeSchedule(
      name,
      classDate,
      //default registration end date to first class datetime if not already set
      isSettingFirstClassDate && !this.state.schedule.registrationEndDate
        ? { registrationEndDate: classDate }
        : null,
    );

    //show set weekly classes prompt when changing first class date
    if (isSettingFirstClassDate) {
      this.setState({
        showSetWeeklyClassSchedulesPrompt: true,
      });
    }
  };

  setWeeklyClassDates = () => {
    const classSchedules = _cloneDeep(this.state.schedule.classSchedules);

    if (!classSchedules[0] || !classSchedules[0].dateTime) {
      return;
    }

    const firstClassDateTime = classSchedules[0].dateTime;
    classSchedules.forEach((clsSched, index) => {
      if (index) {
        clsSched.dateTime = removeTimezoneFormatFromDate(
          moment(firstClassDateTime).add(index * 7, "days"),
        );
      }
    });
    this.onChangeSchedule("classSchedules", classSchedules);

    this.setState({ showSetWeeklyClassSchedulesPrompt: false });
  };

  toggleSetDates = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (!value) {
          //clear out class schedule dates
          const classSchedules = _cloneDeep(this.state.schedule.classSchedules);
          classSchedules.forEach((clsSched) => (clsSched.dateTime = ""));
          this.onChangeSchedule("classSchedules", classSchedules);

          //hide set weekly classes prompt if visible
          if (this.state.showSetWeeklyClassSchedulesPrompt) {
            this.setState({ showSetWeeklyClassSchedulesPrompt: false });
          }
        }
      },
    );
  };

  validateSchedule = () => {
    const {
      schedule: {
        classSchedules,
        location,
        locationAddress,
        registrationEndDate,
        registrationStartDate,
        semester,
        year,
      },
      setScheduleDates,
    } = this.state;

    const {
      enrollment: {
        data: { eduProgramType },
      },
    } = this.props;
    const isYourIsraelCourse = eduProgramType === EduProgramTypes.YourIsrael;

    if (
      !year ||
      (!isYourIsraelCourse && !semester) ||
      (setScheduleDates &&
        (classSchedules.some((clsSched) => !clsSched.dateTime) ||
          !registrationEndDate ||
          !registrationStartDate)) ||
      (location === "Other" &&
        (!locationAddress ||
          !locationAddress.address1 ||
          !locationAddress.city ||
          !locationAddress.country)) ||
      //validate registration dates: registration end cannot be after second class date, registration start cannot be after first class date
      (registrationEndDate &&
        classSchedules[1] &&
        moment(registrationEndDate).isAfter(
          moment(classSchedules[1].dateTime),
        )) ||
      (registrationStartDate &&
        classSchedules[0] &&
        moment(registrationStartDate).isAfter(
          moment(classSchedules[0].dateTime),
        ))
    ) {
      return false;
    }

    return true;
  };

  getScheduleYearOptions = () => {
    const startYear = new Date().getFullYear();
    return Array(20)
      .fill()
      .map((y, i) => startYear + i);
  };

  render() {
    const {
      enrollment: {
        data: {
          chabadHouseAddress,
          chabadHouseName,
          exclusiveEduCourseID,
          eduProgramOrderBooksURL,
          eduProgramType,
          websiteURL,
        },
      },
      course: {
        data: { name: courseName },
      },
      newSchedule,
      pageRoute: { page, params },
      submitCourseSchedule: { loading },
      sys: { countries = [], eduCourseSemesters = [], eduEventLocations = [] },
    } = this.props;

    const {
      schedule,
      errorMessage,
      initialState,
      requestedNavigation,
      setScheduleDates,
      showCancelScheduleConfirmationModal,
      showSetWeeklyClassSchedulesPrompt,
      submitActionType,
      submitAttempted,
    } = this.state;

    const {
      classSchedules,
      description,
      hasApprovedOrPendingEnrollments,
      location,
      locationAddress,
      locationName,
      name: scheduleName,
      notes,
      registrationEndDate,
      registrationStartDate,
      semester,
      year,
    } = schedule;

    const isYourIsraelCourse = eduProgramType === EduProgramTypes.YourIsrael;

    const readOnly = eduProgramType === EduProgramTypes.SinaiScholars;

    const hasChanges =
      !_isEqual(schedule, initialState.schedule) ||
      setScheduleDates !== initialState.setScheduleDates;

    return (
      <React.Fragment>
        <Prompt
          when={!requestedNavigation && hasChanges}
          message={`Your ${
            newSchedule
              ? "schedule has not been submitted"
              : "changes have not been saved"
          }. Are you sure you want to leave this page?`}
        />
        {newSchedule && (
          <i
            className={`material-icons xxl-text mt-24 ml-16 mb-24 desktop-hidden tablet-hidden${
              loading ? " accent-text" : ""
            }`}
            onClick={() => {
              if (!loading) {
                this.onCancelScheduleChanges();
              }
            }}
          >
            close
          </i>
        )}
        <div className="card mobile-flat-card course-schedule-settings-form">
          <div className="flex flex-justify-space mobile-flex-align-center">
            <p className="page-title line-height-double mb-0">
              {newSchedule ? (
                isYourIsraelCourse ? (
                  "Add New Course"
                ) : (
                  <>
                    Offer <span dir="auto">{courseName}</span>
                  </>
                )
              ) : readOnly ? (
                "Course Details"
              ) : (
                "Edit Course"
              )}
            </p>
            {!newSchedule && eduProgramOrderBooksURL && (
              <div>
                <a
                  className="btn btn-accent btn-medium"
                  href={eduProgramOrderBooksURL}
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ padding: "0 16px" }}
                >
                  Order Books
                  <i className="material-icons ml-8 large-text">open_in_new</i>
                </a>
              </div>
            )}
          </div>
          <div className="mt-16 mb-16">
            {!newSchedule && !readOnly && (
              <>
                {moment(new Date()).isAfter(classSchedules[0].dateTime) && (
                  <p className="error-text mb-8 ml-16">
                    **This Course has already begun: Please use extreme caution
                    when editing**
                  </p>
                )}
                {isYourIsraelCourse && (
                  <p className="accent-text">
                    If you are trying to create a new course, click{" "}
                    <PageNavLink
                      params={{
                        courseId: exclusiveEduCourseID,
                        courseView: "offer",
                        pageView: "courses",
                        programScheduleId: params.programScheduleId,
                      }}
                      to={page}
                      style={{
                        textDecoration: "underline",
                        textTransform: "uppercase",
                        color: "initial",
                      }}
                    >
                      Add New Course
                    </PageNavLink>
                  </p>
                )}
              </>
            )}
          </div>
          <div className="edu-form-section course-schedule-semester-settings">
            <p className="medium-text fw-700 mb-16">
              Course Name: {courseName}
              {semester ? ` - ${semester}` : ""} {year} {description}
            </p>
            <p className="line-height-double accent-text-dark mb-16">
              Your course schedule name {newSchedule ? "will be" : "is"}{" "}
              generated based on the course name, and the year
              {!isYourIsraelCourse && ", semester,"} and description that you
              enter below. Use fields below to edit the schedule name - be sure
              to enter a unique description if you are offering this course more
              than once in the same {isYourIsraelCourse ? "year" : "semester"}{" "}
              to differentiate between schedules.
            </p>
            <div className="flex flex-align-center mb-8 mobile-block">
              <label className="mr-24 accent-text-dark">Year</label>
              <CustomSelectDeprecated
                className={submitAttempted && !year ? " error" : ""}
                disabled={readOnly}
                name="year"
                onChange={this.onChangeSchedule}
                options={this.getScheduleYearOptions().map((yr) => ({
                  value: yr,
                  label: yr,
                }))}
                value={year}
              />
            </div>
            {!isYourIsraelCourse && (
              <div className="flex flex-align-center mb-8 mobile-block">
                <label className="mr-24 accent-text-dark">Semester</label>
                <CustomSelectDeprecated
                  className={submitAttempted && !semester ? " error" : ""}
                  disabled={readOnly}
                  name="semester"
                  onChange={this.onChangeSchedule}
                  options={
                    eduCourseSemesters &&
                    eduCourseSemesters.map((sem) => ({
                      value: sem.enumValue,
                      label: sem.displayValue,
                    }))
                  }
                  value={semester}
                />
              </div>
            )}
            <div className="flex flex-align-center mobile-block">
              <label className="mr-24 accent-text-dark">
                Description (optional)
              </label>
              <input
                className="custom-input"
                disabled={readOnly}
                name="description"
                onChange={this.onChangeScheduleEvt}
                placeholder="i.e. Tuesdays"
                type="text"
                value={description}
                dir="auto"
              />
            </div>
          </div>
          <div className="edu-form-section">
            <p className="medium-text fw-700">Course Notes</p>
            <textarea
              className="custom-input custom-message full-width mt-16"
              disabled={readOnly}
              value={notes}
              name="notes"
              onChange={this.onChangeScheduleEvt}
            />
          </div>
          <div className="edu-form-section">
            <p className="medium-text fw-700 mb-16">Course Location</p>
            <div style={{ maxWidth: "768px" }}>
              <div className="flex flex-align-center mobile-block mb-8">
                <label className="accent-text-dark line-height-double">
                  Where will this course be taking place?
                </label>
                <Toggle
                  disabled={readOnly}
                  name="location"
                  onChange={(name, value) =>
                    this.onChangeSchedule(
                      name,
                      value,
                      location === "Other"
                        ? {
                            locationAddress: {
                              address1: "",
                              address2: "",
                              city: "",
                              state: "",
                              zip: "",
                              country: "",
                            },
                            locationName: "",
                          }
                        : null,
                    )
                  }
                  options={
                    eduEventLocations &&
                    eduEventLocations
                      .sort((el1, el2) =>
                        el1.displayValue.localeCompare(el2.displayValue),
                      )
                      .map((el) => ({
                        value: el.enumValue,
                        display: el.displayValue,
                      }))
                  }
                  value={location}
                />
              </div>
              {location === "ChabadHouse" ? (
                chabadHouseAddress && (
                  <p className="accent-text small-text line-height-double">
                    Chabad House address is{" "}
                    {getAddressDisplay(chabadHouseAddress)}
                  </p>
                )
              ) : location === "Other" ? (
                <React.Fragment>
                  <div className="flex flex-align-center mobile-block mb-8">
                    <label className="accent-text-dark">Name</label>
                    <input
                      className="custom-input full-width"
                      disabled={readOnly || location !== "Other"}
                      name="locationName"
                      onChange={this.onChangeScheduleEvt}
                      type="text"
                      value={locationName}
                    />
                  </div>
                  <div className="flex mobile-block">
                    <label className="accent-text-dark address-label">
                      Address
                    </label>
                    <div className="full-width">
                      <input
                        className={`custom-input full-width mb-8 ${
                          location === "Other" &&
                          submitAttempted &&
                          !locationAddress.address1
                            ? "error"
                            : ""
                        }`}
                        disabled={readOnly || location !== "Other"}
                        name="locationAddress.address1"
                        onChange={this.onChangeScheduleEvt}
                        placeholder="Street Address"
                        type="text"
                        value={locationAddress.address1}
                      />

                      <input
                        className="custom-input full-width mb-8"
                        disabled={readOnly || location !== "Other"}
                        name="locationAddress.address2"
                        onChange={this.onChangeScheduleEvt}
                        placeholder="Apt #, Suite, Floor"
                        type="text"
                        value={locationAddress.address2}
                      />
                      <div className="flex mobile-block">
                        <CustomSelectDeprecated
                          className={`mobile-mb-8 ${
                            location === "Other" &&
                            submitAttempted &&
                            !locationAddress.country
                              ? "error"
                              : ""
                          }`}
                          disabled={readOnly || location !== "Other"}
                          isClearable={true}
                          name="locationAddress.country"
                          onChange={(name, val) =>
                            this.onChangeSchedule(
                              name,
                              val,
                              val !== locationAddress.country
                                ? { "locationAddress.state": "" }
                                : null,
                            )
                          }
                          options={
                            countries &&
                            countries.map((c) => ({
                              value: c.name,
                              label: c.name,
                            }))
                          }
                          placeholder="Country"
                          value={locationAddress.country}
                        />
                        <input
                          className={`custom-input full-width mr-8 ml-8 mobile-ml-0 mobile-mb-8 ${
                            location === "Other" &&
                            submitAttempted &&
                            !locationAddress.city
                              ? "error"
                              : ""
                          }`}
                          disabled={readOnly || location !== "Other"}
                          name="locationAddress.city"
                          onChange={this.onChangeScheduleEvt}
                          placeholder="City"
                          type="text"
                          value={locationAddress.city}
                        />
                        {locationAddress.country === "USA" ||
                        locationAddress.country === "Canada" ||
                        locationAddress.country === "Mexico" ? (
                          <CustomSelectDeprecated
                            className="mobile-mb-8"
                            disabled={readOnly || location !== "Other"}
                            isClearable={true}
                            name="locationAddress.state"
                            onChange={this.onChangeSchedule}
                            options={(
                              (countries &&
                                countries
                                  .filter(
                                    (c) => c.name === locationAddress.country,
                                  )
                                  .map((c) => c.states)[0]) ||
                              []
                            ).map((s) => ({
                              value: s.name,
                              label: s.name,
                            }))}
                            placeholder="State"
                            value={locationAddress.state}
                          />
                        ) : (
                          <input
                            className="custom-input full-width ml-8 mobile-ml-0 mobile-mb-8"
                            disabled={
                              readOnly ||
                              location !== "Other" ||
                              !locationAddress.country
                            }
                            name="locationAddress.state"
                            onChange={this.onChangeScheduleEvt}
                            placeholder="State"
                            type="text"
                            value={locationAddress.state}
                          />
                        )}
                        <input
                          className="custom-input full-width ml-8 mobile-ml-0"
                          disabled={readOnly || location !== "Other"}
                          name="locationAddress.zip"
                          onChange={this.onChangeScheduleEvt}
                          placeholder="Zip"
                          type="text"
                          value={locationAddress.zip}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
          {classSchedules && (
            <div className="edu-form-section">
              <div className="mb-24">
                <p className="medium-text fw-700 mb-16">
                  Course Dates
                  <span className="small-text accent-text mt-8 block">
                    *Dates are in{" "}
                    {chabadHouseName || "your enrolled Chabad House"} local
                    timezone
                  </span>
                </p>
                <p className="small-text accent-text line-height-double">
                  Your course will be listed as{" "}
                  <span className="fw-700">Upcoming</span> until Registration
                  Opening date. It will be listed as{" "}
                  <span className="fw-700">Current</span> from Registration
                  Opening date until the date of the last class. Registration
                  will close on the Registration Closing date, which must be
                  before the second class.
                </p>
              </div>
              <p className="fw-700 mb-16 course-dates-label">Class Dates</p>
              {!isYourIsraelCourse && (
                <div className="flex flex-align-center mobile-block mb-24">
                  <label className="accent-text-dark">
                    Set as 'To Be Determined'
                  </label>
                  <Toggle
                    disabled={readOnly}
                    name="setScheduleDates"
                    onChange={this.toggleSetDates}
                    options={[
                      {
                        value: false,
                        display: "Yes",
                      },
                      {
                        value: true,
                        display: "No",
                      },
                    ]}
                    value={setScheduleDates}
                  />
                </div>
              )}
              {!setScheduleDates && (
                <p className="error-text class-schedules-error">
                  Be sure to come back and set dates{" "}
                  <span className="fw-700">before</span> the course starts.
                  Without dates, students will not receive important emails, you
                  will not be able to mark attendance, and credit funding could
                  be delayed
                </p>
              )}
              {classSchedules
                .sort((s1, s2) => s1.sortOrder - s2.sortOrder)
                .map((sched, index) => (
                  <div
                    className={
                      !index
                        ? `flex class-schedules-grid ${
                            showSetWeeklyClassSchedulesPrompt
                              ? " weekly-schedule-box-container"
                              : ""
                          }`
                        : ""
                    }
                    key={index}
                  >
                    <div className="mb-16 mobile-full-width">
                      <p className="mb-16 course-dates-label">
                        Class {sched.sortOrder}
                        {sched.title ? (
                          <>
                            {" "}
                            - <span dir="auto">{sched.title}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                      <div className="flex flex-align-center mobile-block">
                        <label className="accent-text small-text ml-16 mr-40 mobile-ml-0 mobile-mb-8 block">
                          Schedule for
                        </label>
                        <DateTimePicker
                          className={`custom-input ${
                            setScheduleDates &&
                            submitAttempted &&
                            !sched.dateTime
                              ? "error"
                              : ""
                          }`}
                          minDate={
                            sched.sortOrder === 1 ||
                            !classSchedules[index - 1].dateTime
                              ? subWeeks(new Date(), 1)
                              : classSchedules[index - 1].dateTime
                          }
                          disabled={readOnly || !setScheduleDates}
                          name={`classSchedules[${index}].dateTime`}
                          onChange={(nm, val) =>
                            this.onChangeClassScheduleDate(
                              nm,
                              val,
                              index,
                              sched.dateTime,
                            )
                          }
                          showTime={true}
                          value={formatDateTimeForInput(sched.dateTime)}
                        />
                      </div>
                    </div>

                    {!index &&
                      classSchedules &&
                      classSchedules.length > 1 &&
                      showSetWeeklyClassSchedulesPrompt && (
                        <div className="weekly-schedule-box">
                          <div>
                            <p className="medium-text mb-8 line-height-double">
                              Set all classes to once a week on the same day?
                            </p>
                            <div>
                              <button
                                onClick={this.setWeeklyClassDates}
                                className="btn btn-accent btn-small"
                              >
                                Yes
                              </button>
                              <button
                                onClick={() =>
                                  this.setState({
                                    showSetWeeklyClassSchedulesPrompt: false,
                                  })
                                }
                                className="btn btn-light btn-small ml-8"
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
              <p className="fw-700 mb-16 course-dates-label">
                Registration Dates
              </p>
              <div className="mb-16">
                <div className="flex flex-align-center mobile-block">
                  <label className="mr-40 mobile-ml-0 mobile-mb-8 block">
                    Registration Opens
                  </label>
                  <DateTimePicker
                    className={`custom-input ${
                      setScheduleDates &&
                      submitAttempted &&
                      !registrationStartDate
                        ? "error"
                        : ""
                    }`}
                    disabled={readOnly}
                    maxDate={
                      classSchedules[0] ? classSchedules[0].dateTime : null
                    } //first class date
                    name="registrationStartDate"
                    onChange={(nm, val) =>
                      this.onChangeSchedule(
                        nm,
                        val && removeTimezoneFormatFromDate(val),
                      )
                    }
                    showTime={true}
                    value={formatDateTimeForInput(registrationStartDate)}
                  />
                </div>
                {setScheduleDates &&
                  registrationStartDate &&
                  classSchedules[0] &&
                  moment(registrationStartDate).isAfter(
                    classSchedules[0].dateTime,
                  ) && (
                    <p className="error-text small-text mt-4">
                      Registration must open prior to the date of the first
                      class
                    </p>
                  )}
              </div>
              <div className="mb-8">
                <div className="flex flex-align-center mobile-block">
                  <label className="mr-40 mobile-ml-0 mobile-mb-8 block">
                    Registration Closes
                  </label>
                  <DateTimePicker
                    className={`custom-input ${
                      setScheduleDates &&
                      submitAttempted &&
                      !registrationEndDate
                        ? "error"
                        : ""
                    }`}
                    disabled={readOnly}
                    minDate={registrationStartDate || moment()} //registration start or today
                    maxDate={
                      classSchedules[1] ? classSchedules[1].dateTime : null
                    } //second class date
                    name="registrationEndDate"
                    onChange={(nm, val) =>
                      this.onChangeSchedule(
                        nm,
                        val && removeTimezoneFormatFromDate(val),
                      )
                    }
                    showTime={true}
                    value={formatDateTimeForInput(registrationEndDate)}
                  />
                  {!readOnly &&
                    registrationEndDate &&
                    classSchedules[0] &&
                    classSchedules[0].dateTime &&
                    registrationEndDate !== classSchedules[0].dateTime && (
                      <button
                        className="btn btn-medium btn-accent ml-24 mobile-ml-0 mobile-mt-8"
                        onClick={() =>
                          this.onChangeSchedule(
                            "registrationEndDate",
                            classSchedules[0].dateTime,
                          )
                        }
                      >
                        Use time of first class
                      </button>
                    )}
                </div>
                {setScheduleDates &&
                  registrationEndDate &&
                  classSchedules[1] &&
                  moment(registrationEndDate).isAfter(
                    classSchedules[1].dateTime,
                  ) && (
                    <p className="error-text small-text mt-4">
                      Registration must close prior to the date of the second
                      class
                    </p>
                  )}
              </div>
              <p className="accent-text small-text line-height-double mb-16">
                Students can register up until Registration Closes. By default
                registration is set to close at start time of first class; it
                can be set to any time up until start time of second class.
              </p>
            </div>
          )}
          {!newSchedule && isYourIsraelCourse && (
            <div className="program-form-section mobile-mb-16">
              <div className="mb-16">
                <p className="medium-text fw-700 mb-8">
                  {courseName} Shareable URL
                </p>
                <p className="small-text accent-text line-height-double mb-8">
                  Use this link for students to enroll in {courseName}. This
                  link is also where the student can complete the review (once
                  his attendance is complete)
                </p>
                <div className="full-width">
                  <CopyToClipboardTextbox
                    className="copy-to-clipboard-textbox flex flex-justify-space"
                    goToLabel="View Link"
                    id="edu-course-url"
                    label="Copy Link"
                    showGoTo={true}
                    text={websiteURL}
                  />
                </div>
              </div>
              <div>
                <p className="medium-text fw-700 mb-8">Course Direct Link</p>
                <p className="small-text accent-text line-height-double mb-8">
                  Use this link to share with students who already took a Your
                  Israel course in the past, and want to enroll again in this
                  course
                </p>
                <div className="full-width">
                  <CopyToClipboardTextbox
                    className="copy-to-clipboard-textbox flex flex-justify-space"
                    goToLabel="View Link"
                    id="edu-course-direct-url"
                    label="Copy Link"
                    showGoTo={true}
                    text={`${websiteURL}${schedule.id}`}
                  />
                </div>
              </div>
            </div>
          )}
          {!newSchedule && !readOnly && (
            <div className="edu-form-section cancel-schedule-section">
              <button
                className="btn btn-light"
                disabled={loading}
                onClick={() =>
                  this.setState({ showCancelScheduleConfirmationModal: true })
                }
              >
                {submitActionType === this.actionTypes.cancel && loading
                  ? "Canceling..."
                  : "Cancel Course"}
              </button>
              {hasApprovedOrPendingEnrollments && (
                <p
                  className="accent-text small-text line-height-double mt-8 flex"
                  style={{ maxWidth: 680 }}
                >
                  <i
                    className="material-icons mr-8 medium-text"
                    style={{ paddingTop: 2 }}
                  >
                    warning
                  </i>
                  This course has student enrollments. If you cancel this course
                  the enrollments will be cancelled as well. This action cannot
                  be undone.
                </p>
              )}
              {submitActionType === this.actionTypes.cancel &&
                !loading &&
                errorMessage && (
                  <p className="error-text small-text mt-8">{errorMessage}</p>
                )}
            </div>
          )}
        </div>

        {!readOnly && (
          <div className="flex flex-justify-end relative edu-form-btns">
            <button
              className="btn btn-light mt-40"
              disabled={loading || !hasChanges}
              style={{ padding: "0 32px" }}
              onClick={this.onCancelScheduleChanges}
            >
              Cancel
            </button>
            <button
              className="btn btn-accent ml-32 mt-40"
              disabled={loading || !hasChanges}
              style={{ padding: "0 32px" }}
              onClick={this.onSubmitSchedule}
            >
              {submitActionType === this.actionTypes.submit && loading
                ? "Saving..."
                : newSchedule
                ? "Schedule"
                : "Save"}
            </button>
            {submitAttempted &&
              submitActionType === this.actionTypes.submit &&
              !loading &&
              errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        )}
        {showCancelScheduleConfirmationModal && (
          <CancelScheduleModal
            hasApprovedOrPendingEnrollments={hasApprovedOrPendingEnrollments}
            scheduleName={scheduleName}
            onKeepSchedule={() =>
              this.setState({ showCancelScheduleConfirmationModal: false })
            }
            onCancelSchedule={this.onCancelSchedule}
            show={showCancelScheduleConfirmationModal}
            sys={this.props.sys}
          />
        )}
      </React.Fragment>
    );
  }
}
