import React from "react";
import { Typography } from "@material-ui/core";
import MultiSelectDeprecated from "../../../components/form/deprecated-inputs/MultiSelectDeprecated";

export default function EngagementPeriodsSelect(props) {
  const {
    engagementPeriods,
    getColors,
    onChange,
    value,
    maxSelectedEngagementPeriods = 6,
  } = props;

  const colorStyles = {
    multiValue: (styles, { data }) => ({
      ...styles,
      backgroundColor: getColors(data.value).light,
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: getColors(data.value).dark,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: getColors(data.value).dark,
    }),
  };

  const options = engagementPeriods
    ? engagementPeriods.map((p) => ({
        value: p.id,
        label: `${p.monthDisplay} ${p.year}`,
      }))
    : [];

  const selectedOptions = value.map((val) =>
    options.find((o) => o.value.toString() === val),
  );

  return (
    <div className="mb-24">
      <Typography
        variant="subtitle2"
        className="mb-8 mt-16 accent-text-medium fw-500"
      >
        Select which months you would like to review. You may choose up to{" "}
        {maxSelectedEngagementPeriods} months at once.
      </Typography>
      <MultiSelectDeprecated
        className="full-width"
        isOptionDisabled={() =>
          value && value.length >= maxSelectedEngagementPeriods
        }
        onChange={(_, vals) => onChange(vals)}
        options={options}
        styles={colorStyles}
        value={selectedOptions}
      />
    </div>
  );
}
