import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../";
import { TextInput, TextInputProps } from "../../inputs/TextInput";

export type FormTextFieldProps = {
  inputRef?: React.MutableRefObject<any>;
} & FormFieldProps &
  TextInputProps;

export const FormTextField = React.memo(
  /**
   *
   */
  function FormTextField({ name, inputRef, ...passProps }: FormTextFieldProps) {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <TextInput
              {...field}
              id={name}
              {...passProps}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
              autoComplete="off"
              ref={inputRef}
            />
          );
        }}
      />
    );
  },
);
