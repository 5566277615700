import React from "react";
import Select from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";
import moment from "moment";

export default class ChabadCommunityInvolvementDemographics extends React.PureComponent {
  render() {
    const {
      demographics: {
        jewishInvolvementUpdatedOn = "",
        learningEventsParticipation = "",
        personalShliachShluchaTimeParticipation = "",
        referralSourceID = "",
        shabbatDinnerParticipation = "",
        socialEventsParticipation = "",
      },
      onChange,
      participationStatuses,
      studentReferralSources,
    } = this.props;

    return (
      <div className="profile-form-section">
        <div className="profile-section-title">
          Current Chabad/Community Involvement
          {!!jewishInvolvementUpdatedOn && (
            <p className="small-text accent-text mt-8">
              Last updated on{" "}
              {moment(jewishInvolvementUpdatedOn).format("MM/D/YYYY")}
            </p>
          )}
        </div>
        <div className="profile-form-grid">
          <div className="input-container">
            <label>Referred By</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="referralSourceID"
              onChange={onChange}
              options={
                studentReferralSources &&
                studentReferralSources.map((s) => ({
                  label: s.description,
                  value: s.id,
                }))
              }
              placeholder="Select Referrer"
              searchable={true}
              value={referralSourceID}
            />
          </div>
          <div className="input-container">
            <label>Shabbat Dinner</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="shabbatDinnerParticipation"
              onChange={onChange}
              options={
                participationStatuses &&
                participationStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={shabbatDinnerParticipation}
            />
          </div>
          <div className="input-container">
            <label>Personal Time with Shliach or Shlucha</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="personalShliachShluchaTimeParticipation"
              onChange={onChange}
              options={
                participationStatuses &&
                participationStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={personalShliachShluchaTimeParticipation}
            />
          </div>
          <div className="input-container">
            <label>Social Events</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="socialEventsParticipation"
              onChange={onChange}
              options={
                participationStatuses &&
                participationStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={socialEventsParticipation}
            />
          </div>
          <div className="input-container">
            <label>Learning Events</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="learningEventsParticipation"
              onChange={onChange}
              options={
                participationStatuses &&
                participationStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={learningEventsParticipation}
            />
          </div>
        </div>
      </div>
    );
  }
}
