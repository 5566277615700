import React from "react";
import {
  ResponsiveContainer,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatCurrency } from "../../../lib/utils";
import Loader from "../../../components/Loader";

export default class EnrollmentSalesOverTimeChart extends React.Component {
  state = {
    timeFrame: this.props.isOpenForManualTicketEntry ? "last7Days" : "all",
  };

  formatSales = (amount) => formatCurrency(amount, this.props.currencyCode);

  onChangeTimeFrame = (timeFrame) => {
    this.setState({ timeFrame }, () => {
      this.props.getRaffleSales(this.props.id, timeFrame, this.props.raffleId);
    });
  };

  timeFrames = [
    { value: "all", display: "All time" },
    { value: "last7Days", display: "7 days" },
    { value: "last4Weeks", display: "4 weeks" },
    { value: "last2Months", display: "2 months" },
  ];

  render() {
    const { timeFrame } = this.state;
    const { data, errorMessage, loading } = this.props;

    const orderedData = data
      .sort((d1, d2) => new Date(d1.day) - new Date(d2.day))
      .map((item) => ({
        ...item,
        Sales: item.amount, //for display in tooltip
      }));

    return (
      <div className="mt-40 graph-outline" style={{ height: "388px" }}>
        <div className="flex flex-justify-space flex-align-center mb-24">
          <p className="large-text fw-700">Sales Over Time</p>

          {this.props.isOpenForManualTicketEntry && (
            <select
              className="no-border-select accent-text-secondary"
              onChange={(event) => this.onChangeTimeFrame(event.target.value)}
              value={timeFrame}
            >
              {this.timeFrames.map((time) => (
                <option key={time.value} value={time.value}>
                  {time.display}
                </option>
              ))}
            </select>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text">{errorMessage}</p>
        ) : !data.length ? (
          <div className="chart-no-results chart-no-results-small">
            <img src="/images/no_results.svg" alt="no results" height="120" />
            <p className="accent-text mt-8">
              No sales found for this time frame
            </p>
          </div>
        ) : (
          <ResponsiveContainer height={300} width="94%">
            <LineChart data={orderedData}>
              <Line dataKey="Sales" type="monotone" stroke="#ff7300" />

              <Tooltip
                formatter={this.formatSales}
                labelFormatter={(day) => `As of ${day}`}
              />

              <XAxis dataKey="formattedDay" />

              <YAxis
                dataKey="Sales"
                tickFormatter={this.formatSales}
                type="number"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  }
}
