import React from "react";
import Select from "../../../../components/form/deprecated-inputs/CustomSelectDeprecated";

export default class ChildhoodJewishInvolvementDemographics extends React.PureComponent {
  render() {
    const {
      demographics: {
        childhoodKosherStatus = "",
        childhoodJewishObservanceStatus = "",
        childhoodShabbosHolidayStatus = "",
        childhoodSynagogueAttendanceStatus = "",
        childhoodTefillinStatus = "",
        childhoodTorahStudyStatus = "",
      },
      jewishObservanceStatuses,
      observanceStatuses,
      onChange,
      participationStatuses,
      pastShabbosHolidayObservanceStatuses,
      studentGender,
    } = this.props;

    return (
      <div className="profile-form-section">
        <p className="profile-section-title">Childhood Jewish Involvement</p>
        <div className="profile-form-grid">
          <div className="input-container">
            <label>Childhood Observance</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="childhoodJewishObservanceStatus"
              onChange={onChange}
              options={jewishObservanceStatuses?.map((o) => ({
                label: o.displayValue,
                value: o.enumValue,
              }))}
              placeholder="Select Type"
              searchable={true}
              value={childhoodJewishObservanceStatus}
            />
          </div>
          <div className="input-container">
            <label>Shabbos and Holiday Status</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="childhoodShabbosHolidayStatus"
              onChange={onChange}
              options={
                pastShabbosHolidayObservanceStatuses &&
                pastShabbosHolidayObservanceStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={childhoodShabbosHolidayStatus}
            />
          </div>
          <div className="input-container">
            <label>Kosher Status</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="childhoodKosherStatus"
              onChange={onChange}
              options={
                observanceStatuses &&
                observanceStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={childhoodKosherStatus}
            />
          </div>
          <div className="input-container">
            <label>Torah Study Status</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="childhoodTorahStudyStatus"
              onChange={onChange}
              options={
                participationStatuses &&
                participationStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={childhoodTorahStudyStatus}
            />
          </div>
          <div className="input-container">
            <label>Synagogue Attendance Status</label>
            <Select
              classNamePrefix="input-container-select"
              isClearable={true}
              name="childhoodSynagogueAttendanceStatus"
              onChange={onChange}
              options={
                participationStatuses &&
                participationStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={childhoodSynagogueAttendanceStatus}
            />
          </div>
          {studentGender !== "Female" && (
            <div className="input-container">
              <label>Tefillin Status</label>
              <Select
                classNamePrefix="input-container-select"
                isClearable={true}
                name="childhoodTefillinStatus"
                onChange={onChange}
                options={
                  participationStatuses &&
                  participationStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={childhoodTefillinStatus}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
