import React from "react";
import Toggle from "../../../components/form/deprecated-inputs/ToggleDeprecated";
import { getNameDisplay } from "../../../lib";

export default class SeatingPreferences extends React.PureComponent {
  toggleSeatingPreference = async (name, value) => {
    const {
      formProps: {
        formFunctions: { childIsAttending },
        onChange,
        values: {
          seating: { separateSeatingChildPreferences },
        },
      },
      profile: { children },
    } = this.props;

    if (value === "Separate") {
      await onChange(
        "seating.separateSeatingChildPreferences",
        children
          .filter(
            (child) => childIsAttending(child) && child.isEligibleForSeating,
          )
          .map((child) => ({
            childID: child.id,
            parent: "", //cannot toggle if one parent so assuming parent will be set-able
          })),
      );
    } else if (separateSeatingChildPreferences.length) {
      await onChange("seating.separateSeatingChildPreferences", []);
    }

    onChange(name, value);
  };

  render() {
    const {
      attendance: { childrenAttending, parentsAttending },
      formProps: {
        formFunctions: { childIsAttending },
        onChangeEvent,
        values,
        validation,
      },
      profile: { children },
      settings: { seatingPreferences },
      validationHeader,
    } = this.props;

    return (
      <React.Fragment>
        <div className="kinus-page-form">
          <p className="xxl-text fw-500">
            Seating Preferences
            <span
              className="block accent-text-dark mt-8"
              style={{ fontSize: "14px" }}
            >
              Select your preferences for meals with assigned seating.
            </span>
            {validationHeader && validationHeader}
          </p>
          <div className="kinus-form-section seating-preferences">
            <p className={`medium-text fw-700 mb-16 relative`}>
              Would you like separate seating or family seating?{" "}
              <span
                className={
                  validation.seatingSeatingPreference ? "error-message" : ""
                }
                style={{
                  top: "18px",
                  fontFamily: "AvenirBook",
                  fontSize: "12px",
                  left: "0",
                }}
              >
                {!!validation.seatingSeatingPreference &&
                  `*${validation.seatingSeatingPreference}`}
              </span>
            </p>
            <Toggle
              disabled={!childrenAttending || parentsAttending < 2}
              error={validation.seatingSeatingPreference}
              name="seating.seatingPreference"
              onChange={this.toggleSeatingPreference}
              options={seatingPreferences.map((preference) => ({
                value: preference.enumValue,
                display: preference.displayValue,
              }))}
              value={values.seating.seatingPreference}
            />
            {(!childrenAttending || parentsAttending < 2) && (
              <p className="small-text accent-text-dark mt-16 mb-8">
                Family seating option is only available for couples with
                children
              </p>
            )}
          </div>
          {values.seating.seatingPreference === "Separate" &&
            childrenAttending > 0 && (
              <div className="kinus-form-section seating-preferences">
                <p className="medium-text fw-700 mb-16 relative">
                  Which parent will each child be sitting with?{" "}
                  <span
                    className={
                      validation.seatingSeparateSeatingChildPreferences
                        ? "error-message"
                        : ""
                    }
                    style={{
                      top: "18px",
                      fontFamily: "AvenirBook",
                      fontSize: "12px",
                      left: "0",
                    }}
                  >
                    {!!validation.seatingSeparateSeatingChildPreferences &&
                      `*${validation.seatingSeparateSeatingChildPreferences}`}
                  </span>
                </p>
                {children
                  .filter(
                    (child) =>
                      values.attendees.childrenPersonIDs.indexOf(
                        child.person.id,
                      ) >= 0,
                  )
                  .sort(
                    (childA, childB) =>
                      childB.ageInMonthsAtKinus - childA.ageInMonthsAtKinus,
                  )
                  .map((child) => {
                    let separateSeatingPreferenceIndex = null,
                      separateSeatingPreferenceRecord = {};
                    if (child.isEligibleForSeating) {
                      separateSeatingPreferenceIndex =
                        values.seating.separateSeatingChildPreferences.findIndex(
                          (pref) => pref.childID === child.id,
                        );
                      separateSeatingPreferenceRecord =
                        separateSeatingPreferenceIndex >= 0
                          ? values.seating.separateSeatingChildPreferences[
                              separateSeatingPreferenceIndex
                            ]
                          : {};
                    }

                    const disabled =
                      !child.isEligibleForSeating || parentsAttending < 2;

                    return (
                      <div
                        key={child.id}
                        className="child-seat flex flex-align-center"
                      >
                        <p className="small-text accent-text">
                          {!child.isEligibleForSeating && "*"}
                          {getNameDisplay(
                            child.person.firstName,
                            child.person.lastName,
                          )}
                        </p>
                        <div
                          className={`radio-container ${
                            disabled
                              ? "disabled"
                              : validation.seatingSeparateSeatingChildPreferences &&
                                !separateSeatingPreferenceRecord.parent
                              ? "error"
                              : ""
                          }`}
                        >
                          {["Father", "Mother"].map((parent) => (
                            <React.Fragment key={parent}>
                              <input
                                type="radio"
                                id={`separate-seating-${parent}-${child.id}`}
                                disabled={disabled}
                                onChange={onChangeEvent}
                                checked={
                                  separateSeatingPreferenceRecord.parent ===
                                  parent
                                }
                                name={`seating.separateSeatingChildPreferences[${separateSeatingPreferenceIndex}].parent`}
                                value={parent}
                              />
                              <label
                                htmlFor={`separate-seating-${parent}-${child.id}`}
                                style={{ width: "96px" }}
                              >
                                {parent}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                {children.filter(
                  (child) =>
                    childIsAttending(child) && !child.isEligibleForSeating,
                ).length > 0 && (
                  <p className="accent-text-dark mt-24">
                    *Due to space constraints we do not offer seating options
                    for children under 14 months of age.
                  </p>
                )}
              </div>
            )}
        </div>
      </React.Fragment>
    );
  }
}
