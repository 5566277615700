import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "../";
import { NumberInput, NumberInputProps } from "../../inputs/NumberInput";

type FormNumberFieldProps = {
  currencyCode?: string;
  decimals?: number;
  isCurrency?: boolean;
  isNumericString?: boolean;
  isAllowed?: (values: any) => boolean;
  placeholder?: string;
  thousandSeparator?: boolean;
} & FormFieldProps &
  Omit<NumberInputProps, "onChange">;

export const FormNumberField = React.memo(
  /**
   *
   */
  function FormNumberField({ name, ...passProps }: FormNumberFieldProps) {
    const { control } = useFormContext();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => {
          const { onChange, ref, ...fieldProps } = field;
          const { error } = fieldState;
          return (
            <NumberInput
              {...fieldProps}
              id={name}
              {...(passProps as any)}
              getInputRef={ref}
              onChange={(name, value) => {
                onChange({ target: { name, value } });
              }}
              {...(error
                ? {
                    error: true,
                    helperText: error.message,
                  }
                : {})}
            />
          );
        }}
      />
    );
  },
);
