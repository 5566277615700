import React from "react";
import Toggle from "../../../../components/form/deprecated-inputs/ToggleDeprecated";
import { getNameDisplay } from "../../../../lib/utils";

export default ({
  child,
  editProfile,
  formProps: { onChange, onChangeEvent, validation },
  settings,
}) => (
  <div className="kinus-form-section child">
    <p className="medium-text fw-700 mb-24">
      {getNameDisplay(child.person.firstName, child.person.lastName)}
    </p>
    <div className="flex flex-align-center mb-12 mobile-block">
      <p className="kinus-form-label">School Type</p>
      <div className="flex flex-align-center">
        <p>{child.schoolType}</p>
        <p
          className="link-text"
          style={{ fontSize: "12px", padding: "0 8px" }}
          onClick={editProfile}
        >
          <i className="material-icons" style={{ fontSize: "16px" }}>
            edit
          </i>
        </p>
      </div>
    </div>
    {child.childCare.campName && (
      <React.Fragment>
        <div className="flex flex-align-center mb-12 mobile-block">
          <p className="kinus-form-label">Camp Attending</p>
          <p>{child.childCare.campName}</p>
        </div>
        <div className="flex flex-align-center mb-12 mobile-block">
          <p className="kinus-form-label">Transportation to camp</p>
          <Toggle
            error={validation[`child.${child.id}.transportationToCamp`]}
            name={`childCare.children[${child.childCareIndex}].transportationToCamp`}
            onChange={onChange}
            options={[
              { value: true, display: "Yes" },
              { value: false, display: "No" },
            ]}
            value={child.childCare.transportationToCamp}
          />
        </div>
      </React.Fragment>
    )}
    <div className="flex flex-align-center mb-16 mobile-block">
      <p className="kinus-form-label">T-Shirt Size</p>
      <div className="flex flex-align-center mb-16">
        <Toggle
          error={validation[`child.${child.id}.tShirtSize`]}
          name={`childCare.children[${child.childCareIndex}].tShirtSize`}
          onChange={onChange}
          options={settings.tShirtSizes.map((size) => ({
            value: size.enumValue,
            display: size.displayValue,
          }))}
          value={child.childCare.tShirtSize}
        />
      </div>
    </div>

    <div
      className={`flex custom-checkbox-container mb-24
            ${validation[`child.${child.id}.canJoinOffsite`] ? "error" : ""}`}
    >
      <input
        type="checkbox"
        className="custom-checkbox"
        id={`offsite-${child.id}`}
        name={`childCare.children[${child.childCareIndex}].canJoinOffsite`}
        onChange={onChangeEvent}
        checked={child.childCare.canJoinOffsite}
      />
      <label htmlFor={`offsite-${child.id}`}>
        Tzeirei Hashluchim Senior division will be offsite for most of the day.
        I give my child permission to join offsite programs and trips.
      </label>
    </div>
    <div className="mb-16">
      <p className="fw-700 mb-8">
        Please detail any medical conditions, medications and/or allergies
      </p>
      <textarea
        name={`childCare.children[${child.childCareIndex}].medicalNotes`}
        onChange={onChangeEvent}
        placeholder="Type notes here..."
        value={child.childCare.medicalNotes}
      />
    </div>
    <div>
      <p className="fw-700 mb-8">General Comments</p>
      <textarea
        name={`childCare.children[${child.childCareIndex}].generalComments`}
        onChange={onChangeEvent}
        placeholder="Type notes here..."
        value={child.childCare.generalComments}
      />
    </div>
  </div>
);
