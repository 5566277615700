import React from "react";
import CustomSelectDeprecated from "../form/deprecated-inputs/CustomSelectDeprecated";

export class BillingAddress extends React.PureComponent {
  toggleUseAddress = (event, address) => {
    const { onChange } = this.props;

    const billingAddress = event.target.checked
      ? {
          id: address.id,
          address1: address.address1 || "",
          address2: address.address2 || "",
          city: address.city || "",
          state: address.state || "",
          zip: address.zip || "",
          country: address.country || "",
          discriminator: address.discriminator,
        }
      : {
          id: 0,
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          discriminator: "None",
        };

    onChange("billing.address", billingAddress);
  };

  render() {
    const {
      chabadHouseAddress,
      countries,
      billing = { address: {} },
      familyAddress,
      onChange,
      onChangeEvt,
      submitAttempted,
    } = this.props;

    return (
      <div className="payment-info-section billing-address-section">
        <p className="medium-text fw-700 mb-16">Billing Address</p>
        {[
          {
            address: chabadHouseAddress,
            label: "Billing address same as Chabad house address",
          },
          {
            address: familyAddress,
            label: "Billing address same as personal address",
          },
        ].map((add, index) => (
          <div
            key={index}
            className={`flex custom-checkbox-container mb-16 ${
              !add.address ? "disabled" : ""
            }`}
          >
            <input
              checked={!!(add.address && billing.address.id === add.address.id)}
              className="custom-checkbox"
              disabled={!add.address}
              id={`use-address-${index}`}
              onChange={(event) => this.toggleUseAddress(event, add.address)}
              type="checkbox"
            />
            <label htmlFor={`use-address-${index}`}>{add.label}</label>
          </div>
        ))}

        <form className="billing-address-form mt-24">
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="small-text accent-text">Street Address</label>
            <input
              className={`custom-input ${
                submitAttempted && !billing.address.address1 ? "error" : ""
              }`}
              disabled={billing.address.id}
              name="billing.address.address1"
              onChange={onChangeEvt}
              placeholder="Street Address"
              type="text"
              value={billing.address.address1}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="small-text accent-text">Street Address 2</label>
            <input
              className="custom-input"
              disabled={billing.address.id}
              name="billing.address.address2"
              onChange={onChangeEvt}
              placeholder="Street Address 2 (optional)"
              type="text"
              value={billing.address.address2}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="small-text accent-text">Country</label>
            <CustomSelectDeprecated
              className={`custom-input ${
                submitAttempted && !billing.address.country ? "error" : ""
              }`}
              disabled={billing.address.id}
              isClearable={true}
              name="billing.address.country"
              placeholder="Country"
              onChange={onChange}
              options={
                countries &&
                countries.map((c) => ({
                  key: c.name,
                  value: c.name,
                  label: c.name,
                }))
              }
              value={billing.address.country}
            />
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="small-text accent-text">City</label>
            <div className="flex">
              <input
                className={`custom-input ${
                  submitAttempted && !billing.address.city ? "error" : ""
                }`}
                disabled={billing.address.id}
                name="billing.address.city"
                onChange={onChangeEvt}
                placeholder="City"
                type="text"
                value={billing.address.city}
              />
            </div>
          </div>
          <div className="flex flex-align-center mb-12 mobile-block">
            <label className="small-text accent-text">State</label>
            <CustomSelectDeprecated
              disabled={billing.address.id}
              isClearable={true}
              name="billing.address.state"
              onChange={onChange}
              options={(
                (countries &&
                  countries
                    .filter((c) => c.name === billing.address.country)
                    .map((c) => c.states)[0]) ||
                []
              ).map((s) => ({
                key: s.code,
                value: s.name,
                label: s.name,
              }))}
              placeholder="State"
              value={billing.address.state}
            />
          </div>
          <div className="flex flex-align-center mobile-block">
            <label className="small-text accent-text">Zip</label>
            <input
              className="custom-input"
              disabled={billing.address.id}
              name="billing.address.zip"
              onChange={onChangeEvt}
              placeholder="Zip"
              type="text"
              value={billing.address.zip}
            />
          </div>
        </form>
      </div>
    );
  }
}
